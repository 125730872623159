import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import { DebugDelay } from 'src/app/shared/debug/debug.delay';
import { TypedForm } from 'src/app/shared/forms/typed.form';
import { SortOrder } from 'src/app/shared/types/sort.order';
import { GetFundRangeSurveysCommand } from 'src/app/surveys/fundrange-survey/commands/get.fund.range.surveys.command';
import { FundRangeSurveysService } from 'src/app/surveys/fundrange-survey/fund.range.surveys.service';
import { FundRangeSurveysViewModel } from 'src/app/surveys/fundrange-survey/viewmodels/fund.range.surveys.viewmodel';

@Component({
  selector: 'fit-fund-range-surveys',
  templateUrl: './fund.range.surveys.component.html'
})
export class FundRangeSurveysComponent {

  busyLoading = false;

  filterForm = new FilterForm();
  sortOrders = new Map<string, SortOrder>();
  page = 1;
  pageSize = 25;
  sortBy = 'CreatedDate';
  sortOrder = SortOrder.descending;

  viewModel: FundRangeSurveysViewModel;
  
  constructor(private dashboardService: FundRangeSurveysService,
    private router: Router,
    private title: Title,
    @Inject(DOCUMENT) private document: Document) {
    this.sortOrders.set('CreatedDate', SortOrder.none);
    this.sortOrders.set('FundRange', SortOrder.none);
    this.sortOrders.set('Status', SortOrder.none);
    this.sortOrders.set('Age', SortOrder.none);
    this.sortOrders.set('Comment', SortOrder.none);
    this.sortOrders.set('Service', SortOrder.none);
    this.sortOrders.set('ValueAssessment', SortOrder.none);
    this.sortOrders.set('ClassifiedCount', SortOrder.none);
  }

  get canRefresh() {
    return !this.busyLoading;
  }

  get pageSizeButtonText(): string {
    return `Page size: ${this.pageSize}`;
  }

  ngOnInit() {
    this.title.setTitle("Fund Range Surveys");
    this.load();
  }

  load() {
    this.busyLoading = true;

    const command = new GetFundRangeSurveysCommand(
      this.page,
      this.pageSize,
      this.filterForm.fundRange.typedValue,
      this.sortBy,
      this.sortOrder);

    this.dashboardService.getFundRangeSurveysForUser(command)
      .pipe(delay(DebugDelay.value),
        finalize(() => this.busyLoading = false))
      .subscribe((data) => {
        this.viewModel = new FundRangeSurveysViewModel(data);
      });
  }

  runSearch() {
    this.page = 1;
    this.load();
  }

  submitSearch() {
    this.runSearch();
  }

  clearFundRange() {
    this.filterForm.fundRange.reset();
  }

  clearSearch() {
    this.filterForm.reset({ isPassive: false });
    this.runSearch();
  }

  pageChange(pageNumber: number) {
    this.page = pageNumber;
    this.document.body.scrollIntoView(true);
    this.load();
  }

  setPageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.runSearch();
  }

  setSortOrder(args: [string, SortOrder]) {

    this.sortOrders.forEach((v, k, m) => {
      if (k === args[0]) {
        m.set(k, args[1]);
      }
      else {
        m.set(k, SortOrder.none);
      }
    });

    this.sortBy = args[0];
    this.sortOrder = args[1];
    this.page = 1;

    this.load();
  } 

  viewFunds(surveyId: string) {
    this.router.navigate(["surveys/fundrange/funds", surveyId]);
  }
}

class FilterForm extends TypedForm {
  fundRange = this.addTypedControl<string>("fundRange");
}
