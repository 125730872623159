import { Injectable } from '@angular/core';
import { NavigationItem } from 'src/app/navigation/types/navigation.item';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';


@Injectable()
export class NavigationService {

  constructor(private authService: AuthenticationService) {
  }

  getNavigationItems(): NavigationItem[] {

    const portals = new NavigationItem('Portals');
    portals.icon = 'clipboard';

    const lenderPortalNavigationItem = new NavigationItem(
      'Lender',
      'portals/lender',
      'view-columns',
      true);

    const borrowerPortalNavigationItem = new NavigationItem(
      'Borrower',
      'portals/borrower',
      'view-columns',
      true);

    portals.childItems.push(lenderPortalNavigationItem);
    portals.childItems.push(borrowerPortalNavigationItem);
    
    const dashboards = new NavigationItem('Dashboards');
    dashboards.icon = 'dashboard';

    const opportunitiesDashboardNavigationItem = new NavigationItem(
      'Opportunities',
      'dashboards/properties',
      'pie-chart',
      true);

    const dealsDashboardNavigationItem = new NavigationItem(
      'Deals',
      'dashboards/deals',
      'bar-chart',
      true);
       
    dashboards.childItems.push(opportunitiesDashboardNavigationItem);
    dashboards.childItems.push(dealsDashboardNavigationItem);
    
    const properties = new NavigationItem('Opportunities');

    const propertyFindNavigationItem = new NavigationItem(
      'Find',
      'properties/find',
      'property-search',
      true);

    const propertyManageNavigationItem = new NavigationItem(
      'Manage',
      'properties/manage',
      'cog',
      true);

    properties.childItems.push(propertyFindNavigationItem);
    properties.childItems.push(propertyManageNavigationItem);

    const dealFlow = new NavigationItem('Deal Flow')

    const flowsNavigationItem = new NavigationItem(
      'Flows',
      'deal-flows',
      'organization',
      true);

    dealFlow.childItems.push(flowsNavigationItem);

    const team = new NavigationItem('Teams');

    const usersNavigationItem = new NavigationItem(
      'Users',
      'teams/users',
      'users',
      true);

    team.childItems.push(usersNavigationItem);

    return [portals, dashboards, properties, dealFlow, team];
  }
}
