import { DateTime } from "src/app/shared/types/date.time";
import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { ViewModel } from "src/app/shared/viewmodels/viewmodel";

export class FundRangeSurveyViewModel extends ViewModel {
  status: NameValueViewModel<string>;
  fundRange: NameValueViewModel<number>;
  createdDate: DateTime;
  hasBeenMailed: boolean;
  age: number;
  valueAssessment: string;
  classifiedCount: number;
  totalCount: number;

  constructor(obj?: FundRangeSurveyViewModel) {
    super(obj);
    this.status = new NameValueViewModel(obj.status);
    this.fundRange = new NameValueViewModel(obj.fundRange);
    this.createdDate = obj?.createdDate;
    this.hasBeenMailed = obj.hasBeenMailed;
    this.age = obj?.age;
    this.valueAssessment = obj.valueAssessment;
    this.classifiedCount = obj.classifiedCount;
    this.totalCount = obj.totalCount;
  }

  get haveAllFundsBeenClassified() {
    return this.classifiedCount == this.totalCount;
  }
}
