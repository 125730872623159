
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Design-/-Patch" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M11.2928932,5.63603897 L18.363961,12.7071068 L12,19.0710678 L4.92893219,12 L11.2928932,5.63603897 Z M8.46446609,11.2928932 C8.0739418,11.6834175 8.0739418,12.3165825 8.46446609,12.7071068 C8.85499039,13.0976311 9.48815536,13.0976311 9.87867966,12.7071068 C10.2692039,12.3165825 10.2692039,11.6834175 9.87867966,11.2928932 C9.48815536,10.9023689 8.85499039,10.9023689 8.46446609,11.2928932 Z M11.2928932,8.46446609 C10.9023689,8.85499039 10.9023689,9.48815536 11.2928932,9.87867966 C11.6834175,10.2692039 12.3165825,10.2692039 12.7071068,9.87867966 C13.0976311,9.48815536 13.0976311,8.85499039 12.7071068,8.46446609 C12.3165825,8.0739418 11.6834175,8.0739418 11.2928932,8.46446609 Z M11.2928932,14.1213203 C10.9023689,14.5118446 10.9023689,15.1450096 11.2928932,15.5355339 C11.6834175,15.9260582 12.3165825,15.9260582 12.7071068,15.5355339 C13.0976311,15.1450096 13.0976311,14.5118446 12.7071068,14.1213203 C12.3165825,13.7307961 11.6834175,13.7307961 11.2928932,14.1213203 Z M14.1213203,11.2928932 C13.7307961,11.6834175 13.7307961,12.3165825 14.1213203,12.7071068 C14.5118446,13.0976311 15.1450096,13.0976311 15.5355339,12.7071068 C15.9260582,12.3165825 15.9260582,11.6834175 15.5355339,11.2928932 C15.1450096,10.9023689 14.5118446,10.9023689 14.1213203,11.2928932 Z" id="Combined-Shape" fill="#000000"></path>
        <path d="M10.5150629,20.4145579 C8.57369375,21.7007639 5.93228695,21.4886361 4.22182541,19.7781746 C2.51136387,18.0677131 2.2992361,15.4263063 3.58544211,13.4849371 L10.5150629,20.4145579 Z" id="Path" fill="#000000" opacity="0.3"></path>
        <path d="M12.7778303,4.29254889 C14.7191995,3.00634288 17.3606063,3.21847065 19.0710678,4.92893219 C20.7815294,6.63939373 20.9936571,9.28080053 19.7074511,11.2221697 L12.7778303,4.29254889 Z" id="Path" fill="#000000" opacity="0.3"></path>
    </g>
</svg>
