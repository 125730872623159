import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { PagerViewModel } from "src/app/shared/viewmodels/pager.viewmodel";
import { QualitySurveyViewModel } from "src/app/surveys/quality-survey/viewmodels/quality.survey.viewmodel";

export class QualitySurveysViewModel{

  surveys = new Array<QualitySurveyViewModel>();
  subServices = new Array<NameValueViewModel<string>>();
  pager: PagerViewModel;

  constructor(obj?: any) {
    if (obj) {
      this.surveys = obj.surveys.map(a => new QualitySurveyViewModel(a));
      this.subServices = obj.subServices.map(a => new NameValueViewModel<string>(a));
      this.pager = new PagerViewModel(obj.pager);
    }
  }
}
