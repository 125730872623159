import { Validators } from "@angular/forms";
import { merge, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TypedForm } from "src/app/shared/forms/typed.form";
import { Rating } from "src/app/shared/ratings/rating";
import { EditState } from "src/app/shared/types/edit.state";
import { DocumentViewModel } from "src/app/shared/viewmodels/document.viewmodel";
import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { QualitySurveyEditorViewModel } from "src/app/surveys/quality-survey/viewmodels/quality.survey.editor.viewmodel";

export class QualitySurveyForm extends TypedForm {
  provisionalRating: Rating;

  kpiOrSla = this.addTypedControl<boolean>('kpiOrSla');
  kpiOrSlaTerms = this.addTypedControl<string>('kpiOrSlaTerms');
  kpiOrSlaMet = this.addTypedControl<NameValueViewModel<number>>('kpiOrSlaMet');
  kpiOrSlaEvidence = this.addTypedControl<boolean>('kpiOrSlaEvidence');
  outsideKpiOrSla = this.addTypedControl<boolean>('outsideKpiOrSla');
  additionalValues  = this.addTypedControl<string>('additionalValues');
  fundIssues = this.addTypedControl<string>('fundIssues');
  improvements = this.addTypedControl<string>('improvements');
  comment = this.addTypedControl<string>('comment');
  subServiceDefinition = this.addTypedControl<string>('subServiceDefinition');
  files: DocumentViewModel[] = new Array<DocumentViewModel>();
  evidenceFiles: DocumentViewModel[] = new Array<DocumentViewModel>();
  viewModel: QualitySurveyEditorViewModel;

  destroyed = new Subject();

  constructor(viewModel: QualitySurveyEditorViewModel) {
    super();
    this.viewModel = viewModel;
    this.populate(viewModel);
    this.updateValidators();

    this.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.updateValidators())

    merge(
      this.kpiOrSla.valueChanges,
      this.kpiOrSlaMet.valueChanges,
      this.kpiOrSlaEvidence.valueChanges,
      this.outsideKpiOrSla.valueChanges)
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.calculateProvisionalRating());

    this.kpiOrSlaMet.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((change) => {
        if (change) {
          this.kpiOrSlaEvidence.setTypedValue(false);
          this.outsideKpiOrSla.setTypedValue(false);
        }
      });

    this.outsideKpiOrSla.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((change) => {
        if (!change) {
          this.additionalValues.reset();
        }
      });

    this.kpiOrSlaEvidence.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((change) => {
        if (!change) {
          viewModel.evidenceAttachments.filter(e => !!e.externalStoreId).forEach(e => e.editState = EditState.deleted);
          this.evidenceFiles = [];
        }
      });

    this.kpiOrSla.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((change) => {
        if (!change) {
          this.kpiOrSlaMet.setTypedValue(null);
          this.kpiOrSlaTerms.setTypedValue(null);
          this.kpiOrSlaEvidence.setTypedValue(false);
          this.outsideKpiOrSla.setTypedValue(false);
        }
      })
  };

  populate(viewmodel: QualitySurveyEditorViewModel) {
    this.kpiOrSla.setTypedValue(viewmodel.kpiOrSLA);
    this.kpiOrSlaTerms.setTypedValue(viewmodel.kpiOrSLATerms);
    this.additionalValues.setTypedValue(viewmodel.additionalValues);
    this.kpiOrSlaMet.setTypedValue(this.viewModel.kpiOrSLAMetOptions.find(f => f.value == viewmodel.kpiOrSLAMet.value));
    this.kpiOrSlaEvidence.setTypedValue(viewmodel.kpiOrSLAEvidence);
    this.outsideKpiOrSla.setTypedValue(viewmodel.outsideKpiOrSla);
    this.fundIssues.setTypedValue(viewmodel.fundIssues);
    this.improvements.setTypedValue(viewmodel.improvements);
    this.comment.setTypedValue(viewmodel.comment);
    this.subServiceDefinition.setTypedValue(viewmodel.subServiceDefinition);
    this.files = viewmodel.attachments;
    this.evidenceFiles = viewmodel.evidenceAttachments;
    this.markAsPristine();
    this.calculateProvisionalRating();
  }

  getViewModel(): QualitySurveyEditorViewModel {
    const result = new QualitySurveyEditorViewModel();
    result.kpiOrSLAMet = this.kpiOrSlaMet.typedValue;
    result.kpiOrSLA = this.kpiOrSla.typedValue;
    result.kpiOrSLATerms = this.kpiOrSlaTerms.typedValue;
    result.kpiOrSLAEvidence = this.kpiOrSlaEvidence.typedValue;
    result.outsideKpiOrSla = this.outsideKpiOrSla.typedValue;
    result.additionalValues = this.additionalValues.typedValue;
    result.fundIssues = this.fundIssues.typedValue;
    result.improvements = this.improvements.typedValue;
    result.comment = this.comment.typedValue;
    result.subServiceDefinition = this.subServiceDefinition.typedValue;
    result.attachments = this.files;
    result.evidenceAttachments = this.evidenceFiles;
    result.instanceId = this.viewModel.instanceId;
    result.rowVersion = this.viewModel.rowVersion;

    return result;
  }

  updateValidators() {
    if (this.kpiOrSla.typedValue) {
      this.kpiOrSlaTerms.setValidators(Validators.required);
      this.kpiOrSlaMet.setValidators(Validators.required);

      if (this.outsideKpiOrSla.typedValue) {
        this.additionalValues.setValidators(Validators.required);
      } else {
        this.additionalValues.clearValidators();
      }
    } else {
      this.kpiOrSlaTerms.clearValidators();
      this.kpiOrSlaMet.clearValidators();
      this.additionalValues.clearValidators();
    }

    this.kpiOrSlaTerms.updateValueAndValidity({ emitEvent: false });
    this.kpiOrSlaMet.updateValueAndValidity({ emitEvent: false });
    this.additionalValues.updateValueAndValidity({ emitEvent: false });
  }

  calculateProvisionalRating() {
    var rating = Rating.mustImprove;
    if (this.kpiOrSla.typedValue) {
      rating = Rating.satisfactory;

      if (this.kpiOrSlaMet.typedValue?.value == 1) {
        rating = Rating.satisfactory

        if (this.outsideKpiOrSla.typedValue) {
          rating = Rating.good
        }
      } else if (this.kpiOrSlaMet.typedValue?.value == 3) {
        rating = Rating.mustImprove
      } else if (this.kpiOrSlaMet.typedValue?.value == 2) {
        rating = Rating.good

        if (this.kpiOrSlaEvidence.typedValue) {
          rating = Rating.outstanding
        }
      }
    }

    this.provisionalRating = rating;
  }

  destroy() {
    this.destroyed.next();
  }
}
