import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { PagerViewModel } from "src/app/shared/viewmodels/pager.viewmodel";
import { FundRangeSurveyViewModel } from "src/app/surveys/fundrange-survey/viewmodels/fund.range.survey.viewmodel";

export class FundRangeSurveysViewModel{

  pager: PagerViewModel;
  surveys = new Array<FundRangeSurveyViewModel>();
  fundRanges = new Array<NameValueViewModel<string>>();

  constructor(obj?: FundRangeSurveysViewModel) {
    if (obj) {
      this.pager = new PagerViewModel(obj.pager);
      this.surveys = obj.surveys.map(a => new FundRangeSurveyViewModel(a));
      this.fundRanges = obj.fundRanges.map(a => new NameValueViewModel<string>(a));
    }
  }
}
