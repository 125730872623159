import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostBinding, Inject, OnInit } from '@angular/core';
import { merge } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'fit-mobile-header',
  templateUrl: './mobile.header.component.html',
  styleUrls: ['./mobile.header.component.scss']
})
export class MobileHeaderComponent implements OnInit {

  onAsideToggleClick: EventEmitter<void> = new EventEmitter();

  @HostBinding('class.hidden')
  hidden = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.toggleHidden();

    merge(this.authService.notifyLogin, this.authService.notifyLogout)
      .subscribe(_ => {
        this.document.body.classList.remove('topbar-mobile-on');
        this.toggleHidden();
      });
  }

  toggleTopBar(_: MouseEvent): void {
    if (this.document.body.classList.contains('topbar-mobile-on')) {
      this.document.body.classList.remove('topbar-mobile-on');
    } else {
      this.document.body.classList.add('topbar-mobile-on');
    }
  }

  toggleAsideOn(event: MouseEvent): void {
    event.stopPropagation();
    this.onAsideToggleClick.next();
  }

  toggleHidden() {
    if (this.authService.isLoggedIn) {
      this.hidden = false;
    } else {
      this.hidden = true;
    }
  }

}
