import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'surveyStatusColourClass'
})
export class SurveyStatusColourClassPipe implements PipeTransform {

  transform(value: number): any {
    
    switch(value) {
      case 3: 
        return 'label-success';
      case 4:
        return 'label-primary';
      case 5:
        return 'label-danger';
      case 6:
        return 'label-info';
      default:
        return 'label-default';
    }
  }
}
