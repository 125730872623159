
<ng-container *ngIf="survey">
  <div class="card card-custom border mb-2">
    <div class="card-header min-h-50px">
      <div class="card-title m-0">
        Justify Pricing Difference
      </div>
    </div>
    <div class="card-body py-3">
      <form [formGroup]="survey">
        <fit-select-editor [control]="survey?.conclusion"
                           [options]="survey?.viewModel.conclusionOptions"
                           [isReadOnly]="isReadOnly"
                           width="350px"
                           label="Conclusion"
                           helperText=" "
                           tooltip="">
        </fit-select-editor>
        <fit-textarea-editor [control]="survey?.comment"
                             [isReadOnly]="isReadOnly"
                             width="75%"
                             label="Reason"
                             helperText="Please elaborate on why the rating was chosen">
        </fit-textarea-editor>
      </form>
    </div>
  </div>
</ng-container>


