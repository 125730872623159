
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Text-/-H2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <polygon id="H2" fill="#000000" points="1.634 19 1.634 5.51 3.363 5.51 3.363 11.267 10.811 11.267 10.811 5.51 12.54 5.51 12.54 19 10.811 19 10.811 12.939 3.363 12.939 3.363 19"></polygon>
        <path d="M14.649,19 L19.874,13.319 C19.646,13.357 19.475,13.376 19.247,13.376 C16.948,13.319 15.333,11.552 15.333,9.405 C15.333,7.125 17.119,5.358 19.418,5.358 C21.717,5.358 23.522,7.125 23.522,9.405 C23.522,11.001 22.819,12.255 21.185,14.022 L18.069,17.48 L23.522,17.48 L23.522,19 L14.649,19 Z M19.418,11.951 C20.862,11.951 21.907,10.868 21.907,9.405 C21.907,7.961 20.862,6.859 19.418,6.859 C17.955,6.859 16.948,7.961 16.948,9.405 C16.948,10.868 17.955,11.951 19.418,11.951 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
    </g>
</svg>
