import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { PermissionItem } from 'src/app/shared/types/permission.item';
import { LoginViewModel } from 'src/app/shared/viewmodels/login.viewmodel';
import { UserViewModel } from 'src/app/shared/viewmodels/user.viewmodel';

@Injectable()
export class AuthenticationService {

  userSubject = new BehaviorSubject<UserViewModel>(this.user);
  user$ = this.userSubject.asObservable();

  constructor(private router: Router) {
    if (localStorage.getItem('jwtToken') == null) {
      this.authToken = null;
    }

    if (localStorage.getItem('user') == null) {
      this.user = null;
    }

    if (localStorage.getItem('isLoggedIn') == null) {
      this.isLoggedIn = false;
    }
  }

  set authToken(token: string) {
    localStorage.setItem('jwtToken', token);
  }

  get authToken(): string {
    return localStorage.getItem('jwtToken');
  }

  set user(user: UserViewModel) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  get user(): UserViewModel {
    return JSON.parse(localStorage.getItem('user'));
  }

  set isLoggedIn(value: boolean) {
    localStorage.setItem('isLoggedIn', value ? 'true' : 'false');
  }

  get isLoggedIn(): boolean {
    return localStorage.getItem('isLoggedIn') === 'true';
  }

  isAuthorized(requiredPermissions: number): boolean {
    if (!requiredPermissions) {
      return true;
    }

    return this.user.permissions.map(p => p.value).includes(requiredPermissions);
  }

  getAuthorizedPath(): string {
    var path = '/landing';
    const permissions = this.user.permissions.map(p => p.value);
    if (permissions.includes(PermissionItem.viewValueAssessments)) {
      path = '/assessments';
    }
    else if (permissions.includes(PermissionItem.viewQualitySurveyDashboard)) {
      path = '/surveys/dashboard';
    }

    return path;
  }

  @Output() notifyLogin = new EventEmitter();
  @Output() notifyLogout = new EventEmitter();

  setUser(userDetails: UserViewModel) {
    const user = Object.assign(new UserViewModel(), this.user);

    user.firstName = userDetails.firstName;
    user.lastName = userDetails.lastName;
    user.email = userDetails.email;
    user.mobileNumberCode = userDetails.mobileNumberCode;
    user.mobileNumber = userDetails.mobileNumber;
    user.landlineNumberCode = userDetails.landlineNumberCode;
    user.landlineNumber = userDetails.landlineNumber;
    this.user = user;
    this.userSubject.next(this.user);
  }

  login(login: LoginViewModel) {
    this.user = login.user;
    this.authToken = login.authToken;
    this.isLoggedIn = true;
    this.notifyLogin.emit();
    this.userSubject.next(this.user);
  }

  logout() {
    localStorage.clear();
    this.user = null;
    this.authToken = null;
    this.isLoggedIn = false;
    this.notifyLogout.emit();
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }
}
