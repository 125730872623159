import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { ViewModel } from "src/app/shared/viewmodels/viewmodel";

export class FundRangeFundSurveyEditorViewModel extends ViewModel {
  isLocked: boolean;
  fund: NameValueViewModel<string>;
  surveyId: string;

  assetClass: NameValueViewModel<string>
  passiveType: NameValueViewModel<string>
  investmentTechnique: NameValueViewModel<string>
  investmentGeography: NameValueViewModel<string>
  investmentUniverse: NameValueViewModel<string>
  publicPrivate: NameValueViewModel<string>
  scenario: NameValueViewModel<number>
  fundOrMandate: NameValueViewModel<number>

  chargeDetail: string;
  investmentObjective: string;
  status: NameValueViewModel<number>
  fundStatus: string;
  isOIKFund: boolean;
  comment: string;
  aum: number;
  currency: NameValueViewModel<number>

  isTotalReturn: boolean;
  isCapitalGrowth: boolean;
  isYieldIncome: boolean
  isYieldIncomeGrowingHigh: boolean;
  isYieldIncomeRelative: boolean;
  isTarget: boolean;
  isTargetReturn: boolean;
  isInflationProtection: boolean;
  isVolatilityOutcome: boolean;
  isTracker: boolean;
  isLiquidityMatching: boolean;
  isPositiveImpact: boolean;
  isLeverage: boolean;
  isESG: boolean;
  isCharities: boolean;

  assetClasses = new Array<NameValueViewModel<string>>();
  passiveTypes = new Array<NameValueViewModel<string>>();
  currencyList = new Array<NameValueViewModel<number>>();
  investmentTechniques = new Array<NameValueViewModel<string>>();
  investmentGeographies = new Array<NameValueViewModel<string>>();
  investmentUniverses = new Array<NameValueViewModel<string>>();
  publicPrivates = new Array<NameValueViewModel<string>>();

  scenarioTypeList = new Array<NameValueViewModel<number>>();
  fundOrMandateTypeList = new Array<NameValueViewModel<number>>();
  statusTypeList = new Array<NameValueViewModel<number>>();
  wholesaleOrInstitutionalTypeList = new Array<NameValueViewModel<number>>();

  constructor(obj?: FundRangeFundSurveyEditorViewModel) {
    super(obj);
    if (obj) {
      this.isLocked = obj?.isLocked;
      this.fund = new NameValueViewModel(obj.fund);
      this.surveyId = obj?.surveyId;

      this.isTotalReturn = obj?.isTotalReturn;
      this.isCapitalGrowth = obj?.isCapitalGrowth;
      this.isYieldIncome = obj?.isYieldIncome;
      this.isYieldIncomeGrowingHigh = obj?.isYieldIncomeGrowingHigh;
      this.isYieldIncomeRelative = obj?.isYieldIncomeRelative;
      this.isTarget = obj?.isTarget;
      this.isTargetReturn = obj?.isTargetReturn;
      this.isInflationProtection = obj?.isInflationProtection;
      this.isVolatilityOutcome = obj?.isVolatilityOutcome;
      this.isTracker = obj?.isTracker;
      this.isLiquidityMatching = obj?.isLiquidityMatching;
      this.isPositiveImpact = obj?.isPositiveImpact;
      this.isLeverage = obj?.isLeverage;
      this.isESG = obj?.isESG;
      this.isCharities = obj?.isCharities;

      this.chargeDetail = obj?.chargeDetail;
      this.investmentObjective = obj?.investmentObjective;
      this.status = new NameValueViewModel(obj.status);
      this.fundStatus = obj?.fundStatus;
      this.isOIKFund = obj?.isOIKFund;
      this.comment = obj?.comment;
      this.aum = obj?.aum;
      this.currency = obj?.currency;

      this.assetClass = new NameValueViewModel(obj.assetClass);
      this.passiveType = new NameValueViewModel(obj.passiveType);
      this.investmentTechnique = new NameValueViewModel(obj.investmentTechnique);
      this.investmentGeography = new NameValueViewModel(obj.investmentGeography);
      this.investmentUniverse = new NameValueViewModel(obj.investmentUniverse);
      this.publicPrivate = new NameValueViewModel(obj.publicPrivate);
      this.scenario = new NameValueViewModel(obj.scenario);
      this.fundOrMandate = new NameValueViewModel(obj.fundOrMandate);

      this.assetClasses = obj.assetClasses.map(a => new NameValueViewModel(a));
      this.passiveTypes = obj.passiveTypes.map(a => new NameValueViewModel(a));
      this.currencyList = obj.currencyList.map(a => new NameValueViewModel(a));
      this.investmentTechniques = obj.investmentTechniques.map(a => new NameValueViewModel(a));
      this.investmentGeographies = obj.investmentGeographies.map(a => new NameValueViewModel(a));
      this.investmentUniverses = obj.investmentUniverses.map(a => new NameValueViewModel(a));
      this.publicPrivates = obj.publicPrivates.map(a => new NameValueViewModel(a));

      this.scenarioTypeList = obj.scenarioTypeList.map(a => new NameValueViewModel(a));
      this.fundOrMandateTypeList = obj.fundOrMandateTypeList.map(a => new NameValueViewModel(a));
      this.statusTypeList = obj.statusTypeList.map(a => new NameValueViewModel(a));
    }
    
  }
}
