import { Validators } from "@angular/forms";
import { TypedForm } from "src/app/shared/forms/typed.form";
import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { FundRangeFundSurveyEditorViewModel } from "src/app/surveys/fundrange-survey/fundrange-fund-survey/viewmodels/fund.range.fund.survey.editor.viewmodel";

export class StrategyForm extends TypedForm {

  fundOrMandate = this.addTypedControl<NameValueViewModel<number>>('fundOrMandate', [Validators.required]);
  investmentObjective = this.addTypedControl<string>('investmentObjective', [Validators.required]);
  chargeDetail = this.addTypedControl<string>('chargeDetail', [Validators.required]);
  currency = this.addTypedControl<NameValueViewModel<number>>('currency');
  aum = this.addTypedControl<number>('aum');
  statusLock = this.addTypedControl<NameValueViewModel<number>>('statusLock');
  comments = this.addTypedControl<string>('comments', [Validators.maxLength(2000)]);

  viewModel: FundRangeFundSurveyEditorViewModel;

  constructor(isVATeam: boolean = false) {
    super();

    if (isVATeam) {
      let requiredExceptions = ['statusLock', 'aum', 'comments', 'currency'];

      Object.keys(this.controls)
        .filter(key => requiredExceptions.indexOf(key) === -1)
        .forEach(key => {
          this.controls[key].setValidators([Validators.required]);
          this.controls[key].updateValueAndValidity();
        });
    }
  }

  populate(viewmodel: FundRangeFundSurveyEditorViewModel) {
    this.viewModel = viewmodel;
    //Form Control values
    this.chargeDetail.setTypedValue(viewmodel.chargeDetail);
    this.investmentObjective.setTypedValue(viewmodel.investmentObjective);
    this.aum.setTypedValue(viewmodel.aum);
    if (viewmodel.currency != null) {
      this.currency.setTypedValue(this.viewModel.currencyList.find(c => c.value == viewmodel.currency.value));
    }
    else {
      this.currency.setTypedValue(null);
    }
    this.fundOrMandate.setTypedValue(this.viewModel.fundOrMandateTypeList.find(c => c.value == viewmodel.fundOrMandate.value));
    this.statusLock.setTypedValue(this.viewModel.statusTypeList.find(c => c.value == viewmodel.status.value));
    this.comments.setTypedValue(viewmodel.comment);
  }

  getViewModel(viewModel: FundRangeFundSurveyEditorViewModel): FundRangeFundSurveyEditorViewModel {
    viewModel.chargeDetail = this.chargeDetail.typedValue;
    viewModel.investmentObjective = this.investmentObjective.typedValue;
    viewModel.aum = this.aum.typedValue;
    viewModel.currency = this.currency.typedValue;
    viewModel.fundOrMandate = this.fundOrMandate.typedValue;
    viewModel.status = this.statusLock.typedValue;
    viewModel.comment = this.comments.typedValue;

    return viewModel;
  }
}
