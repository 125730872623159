import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, finalize } from 'rxjs/operators';
import { DebugDelay } from 'src/app/shared/debug/debug.delay';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { FundRangeFundSurveyForm } from 'src/app/surveys/fundrange-survey/forms/fund.range.fund.survey.form';
import { GetFundRangeFundSurveyCommand } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/commands/get.fund.range.fund.survey.command';
import { SaveFundRangeFundSurveyCommand } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/commands/save.fund.range.fund.survey.command';
import { FundRangeFundSurveyService } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/fund.range.fund.survey.service';
import { FundRangeFundSurveyEditorViewModel } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/viewmodels/fund.range.fund.survey.editor.viewmodel';

@Component({
  selector: 'fit-fund-range-fund-survey',
  templateUrl: './fund.range.fund.survey.component.html',
  styleUrls: ['./fund.range.fund.survey.component.scss']
})
export class FundRangeFundSurveyComponent {

  busyLoading = false;
  busySaving = false;
  busySubmitting = false;
  busyUploading = false;

  viewmodel: FundRangeFundSurveyEditorViewModel;

  form: FundRangeFundSurveyForm;
  fundId: string;

  navigation = 1;

  isNavigationActive = (result: number) => this.navigation == result;

  constructor(private surveyService: FundRangeFundSurveyService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private title: Title,
    private location: Location,
    @Inject(DOCUMENT) private document: Document) {
  }

  get canRefresh() {
    return !this.busyLoading;
  }

  get canSave() {
    return !this.busyLoading && !this.busySaving && !this.busySubmitting && this.form.dirty && this.form.valid
  }

  get canReset() {
    return !this.busyLoading && !this.busySaving && !this.busySubmitting && this.form.dirty
  }

  ngOnInit() {
    this.title.setTitle("Fund Survey");
    this.fundId = this.route.snapshot.params.fundId;
    this.load();
  }

  load() {
    this.busyLoading = true;

    const command = new GetFundRangeFundSurveyCommand(this.fundId, null);

    this.surveyService.getFundRangeFundSurvey(command)
      .pipe(delay(DebugDelay.value), finalize(() => this.busyLoading = false))
      .subscribe((data) => {
        this.viewmodel = new FundRangeFundSurveyEditorViewModel(data);
        this.form = new FundRangeFundSurveyForm();
        this.form.populate(this.viewmodel);
      });
  }

  saveSurvey() {
    this.busySaving = true;

    const command = new SaveFundRangeFundSurveyCommand(this.form.getViewModel());

    this.surveyService.saveSurvey(command)
      .pipe(delay(DebugDelay.value), finalize(() => this.busySaving = false))
      .subscribe(() => {
        this.navigation = 1;
        this.load();
      });
  }

  resetSurvey() {
    this.form.populate(this.viewmodel);
    this.form.markAsPristine();
  }

  navigate(item: number) {
    this.navigation = item;
  }

  goBack(): void {
    this.location.back();
  }
}
