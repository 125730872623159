import { Component, Input } from '@angular/core';

@Component({
  selector: 'fit-clean-slate',
  templateUrl: './clean.slate.component.html'
})
export class CleanSlate {
  @Input() 
  message = '';

  @Input()
  hasBorder: boolean = true;
}
