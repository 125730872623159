
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Design-/-Crop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M10,6 L14.5,6 C16.709139,6 18,7.290861 18,9.5 L18,14 L16,14 L16,9.5 C16,8.3954305 15.6045695,8 14.5,8 L10,8 L10,6 Z M8,6 L8,8 L4,8 C3.44771525,8 3,7.55228475 3,7 C3,6.44771525 3.44771525,6 4,6 L8,6 Z M18,16 L18,20 C18,20.5522847 17.5522847,21 17,21 C16.4477153,21 16,20.5522847 16,20 L16,16 L18,16 Z" id="Combined-Shape" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
        <path d="M7,5 C6.44771525,5 6,4.55228475 6,4 C6,3.44771525 6.44771525,3 7,3 L17.5,3 C19.709139,3 21,4.290861 21,6.5 L21,17 C21,17.5522847 20.5522847,18 20,18 C19.4477153,18 19,17.5522847 19,17 L19,6.5 C19,5.3954305 18.6045695,5 17.5,5 L7,5 Z" id="Path-12-Copy" fill="#000000" fill-rule="nonzero" transform="translate(13.500000, 10.500000) rotate(-180.000000) translate(-13.500000, -10.500000) "></path>
    </g>
</svg>
