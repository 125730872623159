import { Component, Input } from '@angular/core';

@Component({
  selector: 'fit-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent {

  @Input()
  icon: string;

  @Input()
  title: string;

  @Input()
  isFullWidth: boolean = true;

  @Input() 
  busyLoading = false;

}
