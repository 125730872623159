<div class="py-2 my-n2" [style.width]="width ? width : null">
  <button type="button"
          [class]="isDisabled ? 'btn-default': class"
          [ngClass]="{'btn btn-sm font-weight-bold mr-1 mt-1': true}"
          [disabled]="isDisabled || busyLoading"
          [title]="title"
          (click)="onClick.next($event)">
    <div class="d-flex flex-row">
      <span *ngIf="!busyLoading && icon" class="svg-icon svg-icon-md ">
        <fit-icon [icon]="icon"></fit-icon>
      </span>
      <span *ngIf="busyLoading" class="spinner-border" style="width: 1.5rem; height: 1.5rem;" ></span>
      <div *ngIf="busyLoading" style="height: 20px;"></div>
      <span *ngIf="caption" class="ml-2 mr-1 align-self-center"
            [ngStyle]="{'margin-top.px': icon ? '0' : '1.5', 'margin-bottom.px': icon ? '0' : '1.5'}">
        {{ busyLoading ? busyCaption : caption }}
      </span>
    </div>
  </button>
</div>



