import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Editor } from 'src/app/shared/editors/editor';
import { ToggleTooltipsService } from 'src/app/shared/services/toggle.tooltips.service';
import { Palette } from 'src/app/shared/theme/colour.palette';

@Component({
  selector: 'fit-input-editor',
  templateUrl: './input.editor.html',
  styleUrls: ['./editor.scss']
})

export class InputEditor extends Editor {

  @Output()
  keyEnter = new EventEmitter<KeyboardEvent>();

  @Input()
  width = '100%';

  @Input()
  height = '';

  @Input()
  type: string;

  @Input()
  autofocus = false;

  @Input()
  pattern: string;

  @Input()
  helperText: string;

  colorMap = Palette.getColourNameMap();

  constructor(@Inject(String) type: string = 'text',
  toggleTooltipsService: ToggleTooltipsService) {
    super(toggleTooltipsService);
    this.type = type;
  }

  onKeyEnter(event: KeyboardEvent) {
    this.keyEnter.emit(event);
    event.preventDefault();
  }

}
