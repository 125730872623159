
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Files-/-Locked-folder" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
        <path d="M14.5,13 C15.0522847,13 15.5,13.4477153 15.5,14 L15.5,17 C15.5,17.5522847 15.0522847,18 14.5,18 L9.5,18 C8.94771525,18 8.5,17.5522847 8.5,17 L8.5,14 C8.5,13.4477153 8.94771525,13 9.5,13 L9.5,12.5 C9.5,11.1192881 10.6192881,10 12,10 C13.3807119,10 14.5,11.1192881 14.5,12.5 L14.5,13 Z M12,11 C11.1715729,11 10.5,11.6715729 10.5,12.5 L10.5,13 L13.5,13 L13.5,12.5 C13.5,11.6715729 12.8284271,11 12,11 Z" id="Combined-Shape" fill="#000000"></path>
    </g>
</svg>
