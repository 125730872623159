import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { delay, exhaustMap, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { DebugDelay } from 'src/app/shared/debug/debug.delay';
import { SlideOutDirective } from 'src/app/shared/directives/slide-out/slide-out.directive';
import { TypedForm } from 'src/app/shared/forms/typed.form';
import { ConfirmModalComponent, ConfirmModalConfig } from 'src/app/shared/modals/confirm-modal/confirm.modal.component';
import { ModalButton } from 'src/app/shared/modals/modal.button';
import { ModalResult } from 'src/app/shared/modals/modal.result';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { SortOrder } from 'src/app/shared/types/sort.order';
import { AddNewFundToRangeCommand } from 'src/app/surveys/fundrange-survey/fundrange-funds/commands/add.new.fund.to.range.command';
import { DeleteFundFromRangeCommand } from 'src/app/surveys/fundrange-survey/fundrange-funds/commands/delete.fund.from.range.command';
import { GetFundRangeFundsCommand } from 'src/app/surveys/fundrange-survey/fundrange-funds/commands/get.fund.range.funds.command';
import { SubmitFundRangeSurveyCommand } from 'src/app/surveys/fundrange-survey/fundrange-funds/commands/submit.fund.range.survey.command';
import { FundRangeFundsService } from 'src/app/surveys/fundrange-survey/fundrange-funds/fund.range.funds.service';
import { FundRangeFundViewModel } from 'src/app/surveys/fundrange-survey/fundrange-funds/viewmodels/fund.range.fund.viewmodel';
import { FundRangeFundsViewModel } from 'src/app/surveys/fundrange-survey/fundrange-funds/viewmodels/fund.range.funds.viewmodel';

@Component({
  selector: 'fit-fund-range-funds',
  templateUrl: './fund.range.funds.component.html',
  styleUrls: ['./fund.range.funds.component.scss']
})
export class FundRangeFundsComponent implements OnDestroy {

  @ViewChild('addManualSlideout')
  addManualSlideOutInstance: SlideOutDirective;

  busyLoading = false;
  busySaving = false;
  busyUploading = false;
  busySubmitting = false;
  busyAdding = false;

  surveyId: string;
  filterForm = new FilterForm();
  fundForm = new NewFundForm();
  sortOrders = new Map<string, SortOrder>();
  page = 1;
  pageSize = 25;
  sortBy = 'Code';
  sortOrder = SortOrder.ascending;

  viewmodel: FundRangeFundsViewModel;

  destroyed = new Subject();

  constructor(private fundRangeFundsService: FundRangeFundsService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private title: Title,
    private location: Location,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: Document,) {
    this.sortOrders.set('Code', SortOrder.none);
    this.sortOrders.set('Name', SortOrder.none);
    this.sortOrders.set('Status', SortOrder.none);
    this.sortOrders.set('HasBeenClassified', SortOrder.none);
    this.sortOrders.set('IsInScope', SortOrder.none);
  } 

  get canRefresh() {
    return !this.busyLoading;
  }

  get pageSizeButtonText(): string {
    return `Page size: ${this.pageSize}`;
  }

  get canAdd() {
    return !this.busyLoading;
  }

  get canDelete() {
    return !this.busyLoading;
  }

  get canResetFundForm() {
    return !this.busyLoading && !this.busySaving && !this.busyAdding && this.fundForm.dirty;
  }

  get canSaveFundForm() {
    return !this.busyLoading && !this.busySaving && this.fundForm.dirty && this.fundForm.valid;
  }

  get canSubmit() {
    return !this.busyLoading && !this.busySubmitting && this.viewmodel?.funds.length > 0;
  }

  ngOnInit() {
    this.title.setTitle("Fund Range");
    this.load();
  }

  load() {
    this.busyLoading = true;
    this.surveyId = this.route.snapshot.params.surveyId;

    const command = new GetFundRangeFundsCommand(this.page,
      this.pageSize,
      this.surveyId,
      this.filterForm.fund.typedValue,
      this.sortBy,
      this.sortOrder);

    this.fundRangeFundsService.getFundRangeFunds(command)
      .pipe(delay(DebugDelay.value),
        finalize(() => this.busyLoading = false))
      .subscribe((data) => {
        this.viewmodel = new FundRangeFundsViewModel(data);
      });
  }

  showAddManualRazor() {
    this.addManualSlideOutInstance.show();
  }

  closeRazor() {
    this.addManualSlideOutInstance.hide();
  }

  addNewFund() {
    this.busyAdding = true;

    const command = new AddNewFundToRangeCommand(this.surveyId,
      this.fundForm.name.value,
      this.fundForm.code.value,
      this.fundForm.currency.value);

    this.fundRangeFundsService.addNewFund(command)
      .pipe(
        delay(DebugDelay.value),
        finalize(() => this.busyAdding = false)
      )
      .subscribe(() => {
        this.fundForm.reset();
        this.addManualSlideOutInstance.hide();
        this.load();
      });
  }

  submitSurvey() {
    this.busySubmitting = true;
    this.surveyId = this.route.snapshot.params.surveyId;

    const confirmModal = this.modalService.createInstance(ConfirmModalComponent, {
      title: 'Confirm Survey Submission',
      size: 'sm',
      message: `Are you sure you want to submit this survey?`,
      primaryButton: new ModalButton('Submit', 'btn-light-primary', ModalResult.primary, 'Submitting')
    } as ConfirmModalConfig);

    confirmModal.result.pipe(
      tap(r => r !== ModalResult.primary ? confirmModal.hide() : {}),
      filter(r => r === ModalResult.primary),
      switchMap(() => {
        const command = new SubmitFundRangeSurveyCommand(this.surveyId, this.viewmodel.surveyRowVersion);
        return this.fundRangeFundsService.submitSurvey(command)
      })
    )
    .pipe(
        delay(DebugDelay.value),
        finalize(() => {
          confirmModal.hide(),
            this.busySubmitting = false;
        }))
      .subscribe(() => {
        this.toastr.success('Your survey has been submitted');
        this.goBack()
      });
  }


  runSearch() {
    this.page = 1;
    this.load();
  }

  clearFund() {
    this.filterForm.fund.reset();
  }

  resetFunds() {
    this.fundForm.reset();
  }

  clearFundName() {
    this.fundForm.name.reset();
  }

  clearFundCode() {
    this.fundForm.code.reset();
  }

  clearCurrency() {
    this.fundForm.currency.reset();
  }

  goBack(): void {
    this.location.back();
  }

  clearSearch() {
    this.filterForm.reset({ showNew: false });
    this.runSearch();
  }

  submitSearch(args: KeyboardEvent) {
    if (args.key === "Enter") {
      this.runSearch();
    }
  }

  pageChange(pageNumber: number) {
    this.page = pageNumber;
    this.document.body.scrollIntoView(true);
    this.load();
  }

  setPageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.load();
  }

  setSortOrder(args: [string, SortOrder]) {

    this.sortOrders.forEach((v, k, m) => {
      if (k === args[0]) {
        m.set(k, args[1]);
      }
      else {
        m.set(k, SortOrder.none);
      }
    });

    this.sortBy = args[0];
    this.sortOrder = args[1];
    this.page = 1;

    this.load();
  }

  viewClassification(fundId: string) {
    this.router.navigate(["surveys/fundrange/funds/survey", fundId]);
  }

  ngOnDestroy() {
    this.destroyed.next();
  }
}

class FilterForm extends TypedForm {
  fund = this.addTypedControl<string>("fund");
}

class NewFundForm extends TypedForm {
  name = this.addTypedControl<string>('name', [Validators.required, Validators.maxLength(100)]);
  code = this.addTypedControl<string>('code', [Validators.required, Validators.maxLength(50)]);
  currency = this.addTypedControl<string>('currency', [Validators.required]);
}
