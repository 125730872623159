
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Files-/-Protected-file" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
        <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" id="Combined-Shape" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
        <path d="M14.5,12 C15.0522847,12 15.5,12.4477153 15.5,13 L15.5,16 C15.5,16.5522847 15.0522847,17 14.5,17 L9.5,17 C8.94771525,17 8.5,16.5522847 8.5,16 L8.5,13 C8.5,12.4477153 8.94771525,12 9.5,12 L9.5,11.5 C9.5,10.1192881 10.6192881,9 12,9 C13.3807119,9 14.5,10.1192881 14.5,11.5 L14.5,12 Z M12,10 C11.1715729,10 10.5,10.6715729 10.5,11.5 L10.5,12 L13.5,12 L13.5,11.5 C13.5,10.6715729 12.8284271,10 12,10 Z" id="Combined-Shape" fill="#000000"></path>
    </g>
</svg>
