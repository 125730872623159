
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-General-/-Other2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <circle id="Oval-67" fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle id="Oval-67-Copy" fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle id="Oval-67-Copy-2" fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
</svg>
