import { PagerViewModel } from "src/app/shared/viewmodels/pager.viewmodel";
import { PricingSurveyViewModel } from "src/app/surveys/pricing-survey/viewmodels/pricing.survey.viewmodel";

export class PricingSurveysViewModel {

  surveys = new Array<PricingSurveyViewModel>();
  pager: PagerViewModel;

  constructor(obj?: any) {
    if (obj) {
      this.surveys = obj.surveys.map(a => new PricingSurveyViewModel(a));
      this.pager = new PagerViewModel(obj.pager);
    }
  }
}
