import { Component, Input } from '@angular/core';
import { FundRangeFundSurveyForm } from 'src/app/surveys/fundrange-survey/forms/fund.range.fund.survey.form';

@Component({
  selector: 'fit-fund-range-fund-survey-editor',
  templateUrl: './fund.range.fund.survey.editor.component.html',
  styleUrls: ['./fund.range.fund.survey.editor.component.scss']
})
export class FundRangeFundSurveyEditorComponent {

  @Input()
  survey: FundRangeFundSurveyForm;

  @Input()
  isReadOnly = false;

  @Input()
  isVATeam = false;

  @Input()
  navigation = 0

  ngOnInit() {
    this.navigation = 1;
  }
}


