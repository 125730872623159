<!--begin::Subheader-->
<div class="subheader py-2 py-lg-4 subheader-solid">
  <div class="d-flex align-items-center flex-wrap flex-sm-nowrap"
       [ngClass]="{'container': !isFullWidth, 'container-fluid': isFullWidth }">
    <!--begin::Info-->
    <ng-container *ngIf="!busyLoading">
      <div class="d-flex align-items-center flex-nowrap mr-2">
        <ng-content select="[before-title]"></ng-content>
        <span *ngIf="icon" class="svg-icon svg-icon-lg svg-icon-primary ml-0 mr-2">
          <fit-icon [icon]="icon"></fit-icon>
        </span>
        <!--begin::Page Title-->
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 subheader-title"
            [title]="title">
          {{ title }}
        </h5>
        <!--end::Page Title-->
      </div>
      <div class="d-flex align-items-center justify-content-between col">
        <ng-content></ng-content>
      </div>
      <!--end::Info-->
    </ng-container>
    <ng-container *ngIf="busyLoading">
      <div class="d-flex align-items-center flex-nowrap mr-2">
          <ngx-skeleton-loader animation="pulse" [theme]="{ width: '160px', height: '21px', 'margin-top': '12px'}"></ngx-skeleton-loader>
      </div>
    </ng-container>
  </div>
</div>
<!--end::Subheader-->
