import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { pipe, Subject, timer } from 'rxjs';
import { delay, finalize, takeUntil } from 'rxjs/operators';
import { LoginCommand } from 'src/app/login/commands/login.command';
import { LoginService } from 'src/app/login/services/login.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { LoginViewModel } from 'src/app/shared/viewmodels/login.viewmodel';

@Component({
  selector: 'fit-autologin-component',
  templateUrl: './autologin.component.html',
  styleUrls: ['./autologin.component.scss']
})

export class AutologinComponent implements OnInit, OnDestroy {

  dots = '';
  error = true;
  message = '';
  stopDots = new Subject();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private authService: AuthenticationService) {
  }
  
  ngOnInit(): void {
    this.error = false;
    this.createDots();
    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
     this.autoLoginAndRedirect(token);
    }
    else {
      this.error = true;
      this.message = 'The email link is invalid and has no token'
    }
  }

  autoLoginAndRedirect(token: string) {
    let command = new LoginCommand();
    command.token = token;

    this.loginService.autoLogin(command)
      .pipe(
        delay(1000),
        finalize(() => this.stopDots.next())
      )
      .subscribe(res => {
        if (res.passed) {
          this.authService.login(res);
          this.router.navigate([res.redirect]);
        }
        else {
          this.error = true;
          this.message = 'The email link is invalid and the system cannot log you in'
        }
      },
      err => {
        this.error = true;
        this.message = 'The email link is invalid and the system cannot log you in'
      });
  }

  ngOnDestroy(): void {
    this.stopDots.next();
  }

  createDots() {
    let iDots = 0;
    timer(0, 500)
      .pipe(takeUntil(this.stopDots))
      .subscribe(val => {
      if (iDots > 10) {
        iDots = 0;
      }
      this.dots = ''.padStart(iDots, '.');
      iDots++;
    })
  }

}
