import { Input, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { DateTime } from 'src/app/shared/types/date.time';
import { ToggleTooltipsService } from 'src/app/shared/services/toggle.tooltips.service';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type TooltipDirection = 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right' | 'left' | 'left-top' | 'left-bottom' | 'right' | 'right-top' | 'right-bottom';

@Component({
  selector: 'fit-editor',
  templateUrl: './editor.html',
})
export class Editor{

  @Input()
  control: FormControl = new FormControl();

  @Input()
  tooltipPlacement: TooltipDirection = 'right';

  @Input()
  isReadOnly = false;

  @Input()
  label: string;

  @Input()
  placeHolder: string;

  @Input()
  tooltipsEnabled = true;

  @Input()
  iconTooltips = new Array<string>();

  @Input()
  helperText: string;

  @Input()
  tooltip: string;

  @Input()
  tooltipTitle: string;

  @Input()
  fullWidth = true;

  @Input()
  defaultMargins = true;

  showTooltip = false;

  private destroyed = new Subject();
  
  constructor(private toggleTooltipsService: ToggleTooltipsService) {
  };


  ngOnInit() {
    this.toggleTooltipsService.tooltips.pipe(
      takeUntil(this.destroyed)
    ).subscribe(t => {
      if (t !== this) {
        this.showTooltip = false;
      }
    });

    fromEvent(window, 'click').pipe(
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.showTooltip = false;
    })
  }

  ngOnDestroy() {
    this.destroyed.next();
  }


  tooltipToggle(event: MouseEvent) {
    event.stopPropagation();
    this.showTooltip = !this.showTooltip;
    if (this.showTooltip) {
      this.toggleTooltipsService.toggleTooltips(this);
    }
  }


  get isRequired() {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : '';
    if (validator && validator.required) {
      return true;
    }
  }


  getErrorMessage(): string {

    let message = '';

    if (this.control.errors) {
      Object.keys(this.control.errors).forEach(key => {

        const value = this.control.errors[key];

        switch (key) {

          case 'required':
            message = 'Value required';
            break;

          case 'min':
            message = `Value must be greater than or equal to ${value.min}`;
            break;

          case 'max':
            message = `Value must be less than or equal to ${value.max}`;
            break;

          case 'minlength':
            message = `Value must be longer than ${value.requiredLength} characters`;
            break;

          case 'maxlength':
            message = `Value must be shorter than or equal to ${value.requiredLength} characters`;
            break;

          case 'email':
            message = 'Invalid email address';
            break;

          case 'minDate':
            message = `Minimum date allowed is ${new DateTime(value.requiredDate).toString('YYYY/MM/DD')}`;
            break;

          case 'maxDate':
            message = `Maximum date allowed is ${new DateTime(value.requiredDate).toString('YYYY/MM/DD')}`;
            break;

          case 'number':
            message = 'Invalid number';
            break;

          case 'alphaNumeric':
            message = 'Value must be alpha numeric';
            break;

          case 'date':
            message = 'Invalid date';
            break;

          case 'compare':
            message = 'Values do not match';
            break;

          case 'requiredValue':
            message = 'Required value does not match';
            break;

          case 'pattern':
            message = 'Invalid pattern';
            break;

          case 'idNumber':
            message = 'Invalid ID number';
            break;

          case 'socialSecurityNumber':
            message = 'Invalid Social Security Number';
            break;

          case 'imageFormat':
            message = 'Invalid image type (Must be jpeg, jpg, png or bmp)';
            break;

          case 'restrictedWords':
            message = `${value}`;
            break;

          default:
            message = `Validation failed [${JSON.stringify(value)}]`;
        }
      });
    }

    return message;
  }
}
