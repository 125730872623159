import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'fit-icon-button',
  templateUrl: './icon.button.component.html',
  styleUrls: ['./icon.button.component.css']
})
export class IconButtonComponent {

  @Input()
  busyLoading: boolean;

  @Input()
  icon: string;

  @Input()
  caption: string;

  @Input()
  title: string = '';

  @Input()
  busyCaption = 'Loading';

  @Input()
  class = 'btn-light-primary';

  @Output()
  onClick = new EventEmitter<MouseEvent>();

  @Input()
  isDisabled = false;

  @Input()
  width: string;

}
