import { Component, Input } from '@angular/core';

@Component({
  selector: 'fit-grid-skeleton',
  templateUrl: './grid.skeleton.component.html'
})

export class GridSkeletonComponent {
  animation = 'pulse';

  @Input() rowCount = 5;
  @Input() columnCount = 5;
  @Input() hasSearch = false;

  headerTheme = { width: '50px', height: '17px', margin: '0px' };
  dataTheme = { width: '30px', height: '17px', margin: '0px' };
  searchTheme = { width: '300px', height: '38px', margin: '0px' };

}
