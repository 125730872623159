import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxFilesizeModule } from 'ngx-filesize';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { IconsModule } from 'src/app/icons/icons.module';
import { ApexChartComponent } from 'src/app/shared/charts/apex.chart.component';
import { BreadCrumbs } from 'src/app/shared/components/bread.crumbs';
import { CleanSlate } from 'src/app/shared/components/clean.slate.component';
import { CollapseHeaderComponent } from 'src/app/shared/components/collapseheader.component';
import { DropdownComponent } from 'src/app/shared/components/dropdown.component';
import { IconButtonComponent } from 'src/app/shared/components/icon.button.component';
import { ImageComponent } from 'src/app/shared/components/image.component';
import { NgDropdownComponent } from 'src/app/shared/components/ngdropdown.component';
import { PaginatorComponent } from 'src/app/shared/components/paginator.component';
import { ProgressBar } from 'src/app/shared/components/progress.bar';
import { BarChartSkeletonComponent } from 'src/app/shared/components/skeletons/bar-chart.skeleton.component';
import { CardsSkeletonComponent } from 'src/app/shared/components/skeletons/cards.skeleton.component';
import { GridSkeletonComponent } from 'src/app/shared/components/skeletons/grid.skeleton.component';
import { LineChartSkeletonComponent } from 'src/app/shared/components/skeletons/line-chart.skeleton.component';
import { PieChartSkeletonComponent } from 'src/app/shared/components/skeletons/pie-chart.skeleton.component';
import { ScatterChartSkeletonComponent } from 'src/app/shared/components/skeletons/scatter-chart.skeleton.component';
import { SortheaderComponent } from 'src/app/shared/components/sortheader.component';
import { SubheaderComponent } from 'src/app/shared/components/subheader.component';
import { TabBodyComponent } from 'src/app/shared/components/tab.body.component';
import { TabLabelComponent } from 'src/app/shared/components/tab.label.component';
import { TabsComponent } from 'src/app/shared/components/tabs.component';
import { TestEditorsComponent } from 'src/app/shared/components/test.editors.component';
import { WizardComponent } from 'src/app/shared/components/wizard/wizard.component';
import { AutoExpandingTextarea } from 'src/app/shared/directives/auto-expanding-textarea.directive';
import { AutofocusDirective } from 'src/app/shared/directives/autofocus.directive';
import { HoverClassDirective } from 'src/app/shared/directives/hover.class.directive';
import { NoDecimalDirective } from 'src/app/shared/directives/no.decimal.directive';
import { OnlyNumberDirective } from 'src/app/shared/directives/number.directive';
import { SlideOutCollapsableDirective } from 'src/app/shared/directives/slide-out/slide-out-collapsable.directive';
import { SlideOutHideableDirective } from 'src/app/shared/directives/slide-out/slide-out-hideable.directive';
import { SlideOutDirective } from 'src/app/shared/directives/slide-out/slide-out.directive';
import { AttachmentsEditor } from 'src/app/shared/editors/attachments.editor';
import { CheckboxEditor } from 'src/app/shared/editors/checkbox.editor';
import { ColourSelectEditor } from 'src/app/shared/editors/colour.editor';
import { CurrencyEditor } from 'src/app/shared/editors/currency.editor';
import { DateEditor } from 'src/app/shared/editors/date.editor';
import { Editor } from 'src/app/shared/editors/editor';
import { EmailEditor } from 'src/app/shared/editors/email.editor';
import { IconSelectEditor } from 'src/app/shared/editors/icon.editor';
import { InputEditor } from 'src/app/shared/editors/input.editor';
import { MultiSelectEditor } from 'src/app/shared/editors/multiselect.editor';
import { NumberEditor } from 'src/app/shared/editors/number.editor';
import { PasswordEditor } from 'src/app/shared/editors/password.editor';
import { PhoneEditor } from 'src/app/shared/editors/phone.editor';
import { RadioButtonEditor } from 'src/app/shared/editors/radio.button.editor';
import { RadioEditor } from 'src/app/shared/editors/radio.editor';
import { SelectEditor } from 'src/app/shared/editors/select.editor';
import { TextEditor } from 'src/app/shared/editors/text.editor';
import { TextAreaEditor } from 'src/app/shared/editors/textarea.editor';
import { TimeEditor } from 'src/app/shared/editors/time.editor';
import { TypeaheadEditor } from 'src/app/shared/editors/typeahead.editor';
import { CanActivatePermissionsGuard } from 'src/app/shared/guards/can.activate.permissions.guard';
import { CanDeactivateGuard } from 'src/app/shared/guards/can.deactivate.guard';
import { IsAuthenticatedGuard } from 'src/app/shared/guards/is.authenticated.guard';
import { IsUnauthenticatedGuard } from 'src/app/shared/guards/is.unauthenticated.guard';
import { ConflictHttpInterceptor } from 'src/app/shared/interceptors/conflict.http.interceptor';
import { ServerErrorHttpInterceptor } from 'src/app/shared/interceptors/server-error.http.interceptor';
import { UnauthorizedHttpInterceptor } from 'src/app/shared/interceptors/unauthorized.http.interceptor';
import { ConfirmModalComponent } from 'src/app/shared/modals/confirm-modal/confirm.modal.component';
import { ModalHostDirective } from 'src/app/shared/modals/modal.host.directive';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { AuditActionIconClassPipe } from 'src/app/shared/pipes/audit.action.icon.class.pipe';
import { AuditActionIconPipe } from 'src/app/shared/pipes/audit.action.icon.pipe';
import { CalculationMatrixResultColourPipe } from 'src/app/shared/pipes/calculation.matrix.result.colour.pipe';
import { ClockPipe } from 'src/app/shared/pipes/clock.pipe';
import { CountryCodeClassPipe } from 'src/app/shared/pipes/country.code.class.pipe';
import { FileSizePipe } from 'src/app/shared/pipes/file.size.pipe';
import { FundFeeSourceColourClassPipe } from 'src/app/shared/pipes/fund.fee.source.colour.class.pipe';
import { LogPipe } from 'src/app/shared/pipes/log.pipe';
import { MessageDeconcatenationPipe } from 'src/app/shared/pipes/message.deconcatenation.pipe';
import { RatingColourPipe } from 'src/app/shared/pipes/rating.colour.pipe';
import { SafePipe } from 'src/app/shared/pipes/safe.html.pipe';
import { ScoreColourPipe } from 'src/app/shared/pipes/score.colour.pipe';
import { ShortNumberPipe } from 'src/app/shared/pipes/short.number.pipe';
import { StringSplitPipe } from 'src/app/shared/pipes/string.split.pipe';
import { SurveyAgeColourClassPipe } from 'src/app/shared/pipes/survey.age.colour.class.pipe';
import { SurveyStatusColourClassPipe } from 'src/app/shared/pipes/survey.status.colour.pipe';
import { ValidationStatusActionIconClassPipe } from 'src/app/shared/pipes/validation.status.action.icon.class.pipe';
import { ValidationStatusActionIconPipe } from 'src/app/shared/pipes/validation.status.action.icon.pipe';
import { WithLoadingPipe } from 'src/app/shared/pipes/with-loading.pipe';
import { RatingClassPipe } from 'src/app/shared/ratings/rating.class.pipe';
import { RatingStringPipe } from 'src/app/shared/ratings/rating.string.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CalculationsService } from 'src/app/shared/services/calculations.service';
import { CriteriaService } from 'src/app/shared/services/criteria.service';
import { ImportsService } from 'src/app/shared/services/imports.service';
import { ToggleTooltipsService } from 'src/app/shared/services/toggle.tooltips.service';
import { ValidationsService } from 'src/app/shared/services/validations.service';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ServerErrorHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ConflictHttpInterceptor, multi: true },
];

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    MomentModule,
    IconsModule,
    NgbDropdownModule,
    NgbModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    IconsModule,
    ToastrModule.forRoot(),
    NgApexchartsModule,
    NgxSkeletonLoaderModule,
    NgxDropzoneModule,
    NgxFilesizeModule,
    NgxMaskModule.forRoot(maskConfig),
    FlatpickrModule.forRoot(),
    DragDropModule,
  ],

  declarations: [
    ApexChartComponent,
    AttachmentsEditor,
    AutoExpandingTextarea,
    AutofocusDirective,
    AuditActionIconPipe,
    AuditActionIconClassPipe,
    BarChartSkeletonComponent,
    BreadCrumbs,
    CalculationMatrixResultColourPipe,
    CardsSkeletonComponent,
    CheckboxEditor,
    CleanSlate,
    ClockPipe,
    CollapseHeaderComponent,
    ColourSelectEditor,
    ConfirmModalComponent,
    CountryCodeClassPipe,
    CurrencyEditor,
    DateEditor,
    DropdownComponent,
    Editor,
    EmailEditor,
    FileSizePipe,
    GridSkeletonComponent,
    HoverClassDirective,
    IconButtonComponent,
    IconSelectEditor,
    ImageComponent,
    InputEditor,
    LineChartSkeletonComponent,
    LogPipe,
    MessageDeconcatenationPipe,
    ModalHostDirective,
    MultiSelectEditor,
    NgDropdownComponent,
    NoDecimalDirective,
    NumberEditor,
    OnlyNumberDirective,
    OnlyNumberDirective,
    PaginatorComponent,
    PasswordEditor,
    PhoneEditor,
    PieChartSkeletonComponent,
    ProgressBar,
    RadioButtonEditor,
    RadioEditor,
    RatingClassPipe,
    RatingColourPipe,
    RatingStringPipe,
    SafePipe,
    ScatterChartSkeletonComponent,
    SelectEditor,
    ShortNumberPipe,
    SlideOutCollapsableDirective,
    SlideOutDirective,
    SlideOutHideableDirective,
    SortheaderComponent,
    StringSplitPipe,
    SubheaderComponent,
    SurveyAgeColourClassPipe,
    SurveyStatusColourClassPipe,
    TabBodyComponent,
    TabLabelComponent,
    TabsComponent,
    TestEditorsComponent,
    TextAreaEditor,
    TextEditor,
    TimeEditor,
    TypeaheadEditor,
    ValidationStatusActionIconPipe,
    ValidationStatusActionIconClassPipe,
    WithLoadingPipe,
    FundFeeSourceColourClassPipe,
    WizardComponent,
    ScoreColourPipe
  ],

  exports: [
    ApexChartComponent,
    AttachmentsEditor,
    AuditActionIconPipe,
    AuditActionIconClassPipe,
    AutoExpandingTextarea,
    AutofocusDirective,
    BarChartSkeletonComponent,
    BreadCrumbs,
    CalculationMatrixResultColourPipe,
    CardsSkeletonComponent,
    CheckboxEditor,
    CleanSlate,
    ClockPipe,
    CollapseHeaderComponent,
    ColourSelectEditor,
    CountryCodeClassPipe,
    CurrencyEditor,
    DateEditor,
    DropdownComponent,
    Editor,
    EmailEditor,
    FileSizePipe,
    GridSkeletonComponent,
    HoverClassDirective,
    IconButtonComponent,
    IconSelectEditor,
    ImageComponent,
    InputEditor,
    LineChartSkeletonComponent,
    LogPipe,
    MessageDeconcatenationPipe,
    ModalHostDirective,
    MultiSelectEditor,
    NgDropdownComponent,
    NoDecimalDirective,
    NumberEditor,
    OnlyNumberDirective,
    OnlyNumberDirective,
    PaginatorComponent,
    PasswordEditor,
    PerfectScrollbarModule,
    NgbModule,
    PhoneEditor,
    PieChartSkeletonComponent,
    ProgressBar,
    RadioButtonEditor,
    RadioEditor,
    RatingColourPipe,
    RatingClassPipe,
    RatingStringPipe,
    SafePipe,
    ScatterChartSkeletonComponent,
    SelectEditor,
    ShortNumberPipe,
    SlideOutCollapsableDirective,
    SlideOutDirective,
    SlideOutHideableDirective,
    SortheaderComponent,
    StringSplitPipe,
    SubheaderComponent,
    SurveyAgeColourClassPipe,
    SurveyStatusColourClassPipe,
    TabBodyComponent,
    TabLabelComponent,
    TabsComponent,
    TestEditorsComponent,
    TextAreaEditor,
    TextEditor,
    TimeEditor,
    TypeaheadEditor,
    ValidationStatusActionIconPipe,
    ValidationStatusActionIconClassPipe,
    WithLoadingPipe,
    WizardComponent,
    FundFeeSourceColourClassPipe,
    ScoreColourPipe
  ],

  entryComponents: [
    ConfirmModalComponent
  ],
  providers: [DecimalPipe]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AuthenticationService,
        CalculationsService,
        CanActivatePermissionsGuard,
        CanDeactivateGuard,
        CriteriaService,
        ImportsService,
        IsAuthenticatedGuard,
        IsUnauthenticatedGuard,
        ModalService,
        ToggleTooltipsService,
        ValidationsService
      ]
    };
  }
}

