import { DateTime } from "src/app/shared/types/date.time";
import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { ViewModel } from "src/app/shared/viewmodels/viewmodel";

export class PricingSurveyEditorViewModel extends ViewModel {
  isLocked: string;
  status: NameValueViewModel<number>;
  fund: NameValueViewModel<string>;
  matchFund: NameValueViewModel<string>;
  thresholdCalculation: NameValueViewModel<number>;
  differenceCalculation: NameValueViewModel<number>;
  prevDifferenceCalculation: NameValueViewModel<number>;
  age: number;
  fundScenario: string;
  matchFundScenario: string;
  fundCharge: number;
  matchFundCharge: number;
  createdDate: DateTime;
  conclusion: NameValueViewModel<number>;
  comment: string;
  rejectComment: string;
  conclusionOptions: Array<NameValueViewModel<number>>

  constructor(obj?: PricingSurveyEditorViewModel) {
    super(obj);
    if (obj) {
      this.isLocked = obj?.isLocked;
      this.rowVersion = obj?.rowVersion;
      this.status = new NameValueViewModel(obj.status);
      this.fund = new NameValueViewModel(obj.fund);
      this.matchFund = new NameValueViewModel(obj.matchFund);
      this.conclusion = new NameValueViewModel(obj.conclusion);
      this.thresholdCalculation = new NameValueViewModel(obj.thresholdCalculation);
      this.differenceCalculation = new NameValueViewModel(obj.differenceCalculation);
      this.prevDifferenceCalculation = new NameValueViewModel(obj.prevDifferenceCalculation);
      this.age = obj?.age;
      this.fundScenario = obj?.fundScenario;
      this.matchFundScenario = obj?.matchFundScenario;
      this.fundCharge = obj?.fundCharge;
      this.matchFundCharge = obj?.matchFundCharge;
      this.comment = obj?.comment;
      this.createdDate = obj?.createdDate;
      this.rejectComment = obj?.rejectComment;
      this.conclusionOptions = obj.conclusionOptions.map(o => new NameValueViewModel(o));
    }
  }
}
