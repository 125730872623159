<fit-subheader title="Capture Quality of Service Survey" [busyLoading]="busyLoading" [isFullWidth]="false">
  <span>Provisional Rating:<span class="mt-2 mb-2 ml-4 label label-lg label-inline {{form?.provisionalRating | ratingClassPipe}}"> {{form?.provisionalRating | ratingStringPipe}}</span></span>
  <div class="d-flex flex-row flex-wrap">
    <fit-icon-button caption="Back"
                     (onClick)="goBack()"
                     icon="navigation-arrow-left"
                     class="btn-light-warning">
    </fit-icon-button>
    <fit-icon-button caption="Refresh"
                     (onClick)="load()"
                     icon="general-update"
                     [isDisabled]="!canRefresh"
                     [busyLoading]="busyLoading">
    </fit-icon-button>
  </div>
</fit-subheader>

<div class="container d-flex flex-column h-100">
  <ng-container *ngIf="!busyLoading else loading">
    <ng-container *ngTemplateOutlet="manageView"></ng-container>
  </ng-container>
</div>

<ng-template #manageView>

  <div class="card card-custom">
    <div class="card-header py-4">
      <h2 class="card-label font-weight-bold text-dark align-self-center m-0">
        Quality of Service | {{viewModel?.subService.name}}
      </h2>
      <div></div>
      <div class="card-toolbar">
        <fit-icon-button caption="Reset" (onClick)="resetSurvey()" [isDisabled]="!canReset" class="btn-light-warning"></fit-icon-button>
        <fit-icon-button caption="Save" (onClick)="saveSurvey()" [isDisabled]="!canSave" [busyLoading]="busySaving" busyCaption="Saving"></fit-icon-button>
        <fit-icon-button caption="Submit" (onClick)="submitSurvey()" [isDisabled]="!canSubmit" [busyLoading]="busySubmitting" busyCaption="Submitting"></fit-icon-button>
      </div>
    </div>
    <div class="card-body py-3">
      <div *ngIf="viewModel?.rejectComment" class="alert alert-custom alert-light-danger mb-5 py-3 px-9" style="flex-direction:column" role="alert">
        <div class="alert-heading"><b>Reject comment: </b></div>
        <p>{{viewModel?.rejectComment}}</p>
      </div>
      <fit-quality-survey-editor [survey]="form"
                                 [isReadOnly]="false">
      </fit-quality-survey-editor>
    </div>
  </div>

</ng-template>

<ng-template #loading>
  <fit-grid-skeleton [columnCount]="4"></fit-grid-skeleton>
</ng-template>

<ng-template #submittedSurvey>
  <div class="card card-custom">
    <fit-clean-slate [hasBorder]="false" message="Your survey has been submitted">
      <div class="d-flex justify-content-center">
        <img src="assets/img/clean-slate/handshake-slate.svg"
             width="120"
             class="mb-5" />
      </div>
    </fit-clean-slate>
  </div>
</ng-template>
