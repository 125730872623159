import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { UsersViewModel } from 'src/app/shared/viewmodels/users.viewmodel';
import { AddUserCommand } from "src/app/teams/users/commands/add.user.command";
import { DeleteUserCommand } from "src/app/teams/users/commands/delete.user.command";
import { EditUserCommand } from "src/app/teams/users/commands/edit.user.command";
import { GetUsersCommand } from "src/app/teams/users/commands/get.users.command";
import { environment } from "src/environments/environment";

@Injectable()
export class UserService extends RestServiceBase {

  private URL = environment.apiUrl + '/users';

  constructor(httpClient: HttpClient, authService: AuthenticationService) {
    super(httpClient, authService);
  }

  getUsers(command: GetUsersCommand): Observable<UsersViewModel> {
    return this.post(this.URL + `GetUsers`, command);
  }

  addUser(command: AddUserCommand) {
    return this.post(this.URL + `AddUser`, command);
  }

  editUser(command: EditUserCommand) {
    return this.post(this.URL + `EditUser`, command);
  }

  deleteUser(command: DeleteUserCommand) {
    return this.post(this.URL + `DeleteUser`, command);
  }
}
