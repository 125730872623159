import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { AppRoutingModule } from 'src/app/app.routing.module';
import { LoginModule } from 'src/app/login/login.module';
import { NavigationModule } from 'src/app/navigation/navigation.module';
import { NotfoundModule } from 'src/app/notfound/notfound.module';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConflictHttpInterceptor } from 'src/app/shared/interceptors/conflict.http.interceptor';
import { ServerErrorHttpInterceptor } from 'src/app/shared/interceptors/server-error.http.interceptor';
import { UnauthorizedHttpInterceptor } from 'src/app/shared/interceptors/unauthorized.http.interceptor';
import { NgxCaptureModule } from 'ngx-capture';
import { SurveysModule } from 'src/app/surveys/surveys.module';


import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  suppressScrollY: false,
  useBothWheelAxes: true
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ['user.read']);
  //protectedResourceMap.set(protectedResources.todoListApi.endpoint, protectedResources.todoListApi.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
    loginFailedRoute: "/login-failed"
  };
}
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MomentModule,
    ToastrModule.forRoot({ timeOut: 15000, preventDuplicates: true }),
    NavigationModule,
    SharedModule.forRoot(),
    LoginModule,
    SurveysModule,
    NotfoundModule,
    NgxDropzoneModule,
    NgxCaptureModule,
    MsalModule
  ],

  declarations: [
    AppComponent
  ],

  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  { provide: AuthenticationService, useClass: AuthenticationService },
  { provide: DEFAULT_CURRENCY_CODE, useValue: 'R' },
  { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
  { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ServerErrorHttpInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ConflictHttpInterceptor, multi: true }
  ],


  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  constructor() {
  }
}
