import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { merge } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'fit-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, AfterViewInit {

  userFirstName: string;
  userLastName: string;
  userEmailAddress: string;
  activeDropdowns: HTMLElement[] = [];

  @ViewChild('header')
  header: ElementRef<HTMLElement>;

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.userFirstName = this.authService.user.firstName;
      this.userLastName = this.authService.user.lastName;
      this.userEmailAddress = this.authService.user.email;
    }

    merge(this.authService.notifyLogin, this.authService.notifyLogout)
      .subscribe(_ => {
        if (this.authService.isLoggedIn) {
          this.userFirstName = this.authService.user.firstName;
          this.userLastName = this.authService.user.lastName;
          this.userEmailAddress = this.authService.user.email;
        }
        this.toggleHidden();
      });
  }

  ngAfterViewInit(): void {
    this.toggleHidden();
  }

  @HostListener('document:click', ['$event'])
  documentClick(_: MouseEvent) {
    this.closeAllDropdowns();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_: Event) {
    this.closeAllDropdowns();
  }

  toggleDropDown(event: MouseEvent, dropdownElem: HTMLElement): void {
    event.stopPropagation();
    if (!this.activeDropdowns.includes(dropdownElem)) {
      dropdownElem.style.display = 'block';
      this.activeDropdowns.push(dropdownElem);
    }
  }

  toggleHidden(): void {
    if (this.authService.isLoggedIn) {
      this.header.nativeElement.classList.remove('hidden');
    } else {
      this.header.nativeElement.classList.add('hidden');
      this.closeAllDropdowns();
    }
  }

  closeAllDropdowns(): void {
    this.activeDropdowns.forEach(x => x.style.display = 'none');
    this.activeDropdowns = [];
  }

  logout() {
    this.authService.logout();
  }

}
