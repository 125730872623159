import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoginCommand } from 'src/app/login/commands/login.command';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { LoginViewModel } from 'src/app/shared/viewmodels/login.viewmodel';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { environment } from "src/environments/environment";

@Injectable()
export class LoginService extends RestServiceBase {

  private LOGIN_URL: string = environment.apiUrl;
  
  constructor(httpClient: HttpClient, authService: AuthenticationService) {
    super(httpClient, authService);
  }

  login(command: LoginCommand): Observable<LoginViewModel> {
    return this.post<LoginViewModel>(this.LOGIN_URL + `/loginLogin`, command);
  }

  autoLogin(command: LoginCommand): Observable<LoginViewModel> {
    return this.post<LoginViewModel>(this.LOGIN_URL + `/loginAutoLogin`, command);
  }
}
