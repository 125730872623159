export const environment = {
  production: true,
  envName: 'dev',
  apiUrl: 'https://func-mg-dev1-t1-va-01.azurewebsites.net/api',
  apiKey: 'FDIyg4iqAmtsZBYZnZKXu_HbyftNJZ3LfICeFcbbraqhAzFuN7mKXQ==',
  clientID: '95d452fc-f988-4031-bfe8-e9219910af64',
  tenantID: 'aa42167d-6f8d-45ce-b655-d245ef97da66',
  redirectLogoutUri: 'http://localhost:7878/',
  redirectUri: 'https://valueassessment-dev1.mandg.com/assessments',
};
