import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { merge } from 'rxjs';
import { AsideMenuComponent } from 'src/app/navigation/components/aside.menu.components';
import { MobileHeaderComponent } from 'src/app/navigation/components/mobile.header.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ModalHostDirective } from './shared/modals/modal.host.directive';
import { ModalService } from './shared/modals/modal.service';

@Component({
  selector: 'fit-app-outlet',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild(ModalHostDirective)
  modalHost: ModalHostDirective;

  @ViewChild(AsideMenuComponent)
  asideMenuComponent: AsideMenuComponent;

  @ViewChild(MobileHeaderComponent)
  mobileHeaderComponent: MobileHeaderComponent;

  get userLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  constructor(
    private modalService: ModalService,
    private authService: AuthenticationService,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.setContentClasses();

    merge(this.authService.notifyLogin, this.authService.notifyLogout)
      .subscribe(_ => this.setContentClasses());

    setTimeout(() => this.modalService.setHost(this.modalHost));
  }

  ngAfterViewInit(): void {
    this.mobileHeaderComponent.onAsideToggleClick.subscribe(_ => {
      this.asideMenuComponent.toggleMenuVisibleMobile();
    });
  }

  setContentClasses() {
    if (this.userLoggedIn) {
      this.document.body.classList.add('header-fixed');
      this.document.body.classList.add('header-mobile-fixed');
      this.document.body.classList.add('aside-enabled');
      this.document.body.classList.add('aside-fixed');
      this.document.body.classList.add('aside-minimize-hoverable');
    } else {
      this.document.body.classList.remove('header-fixed');
      this.document.body.classList.remove('header-mobile-fixed');
      this.document.body.classList.remove('aside-enabled');
      this.document.body.classList.remove('aside-fixed');
      this.document.body.classList.remove('aside-minimize-hoverable');
    }
  }
}
