<div data-backdrop="static"
     class="modal fade"
     [ngClass]="{ 'show': visible }">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title"
            [innerHtml]="title | safeHtml">
        </h3>
      </div>
      <div class="modal-body"
           [innerHtml]="message | safeHtml">
      </div>
      <div class="modal-footer">
        <button class="btn"
                [ngClass]="[negativeButton.klass]"
                (click)="click(negativeButton)"
                [disabled]="isLoading">
          {{ negativeButton.text }}
        </button>
        <button *ngIf="secondaryButton"
                class="btn"
                [ngClass]="[secondaryButton.klass]"
                (click)="click(secondaryButton)"
                [disabled]="isLoading">
          <span *ngIf="secondaryButton.isLoading"
                [ngClass]="[secondaryButton.loadingClass]"
                class="spinner pr-9"></span>
          {{ secondaryButton.isLoading ? secondaryButton.loadingText : secondaryButton.text }}
        </button>
        <button class="btn"
                [ngClass]="[primaryButton.klass]"
                (click)="click(primaryButton)"
                [disabled]="isLoading">
          <span *ngIf="primaryButton.isLoading"
                [ngClass]="[primaryButton.loadingClass]"
                class="spinner pr-9"></span>
          {{ primaryButton.isLoading ? primaryButton.loadingText : primaryButton.text }}
        </button>
      </div>
    </div>
  </div>
</div>
