import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CancelCalculationsCommand } from "src/app/shared/commands/cancel.calculations.command";
import { GetCalculationsStatusCommand } from "src/app/shared/commands/get.calculations.status.command";
import { GetRunningCalculationsCommand } from "src/app/shared/commands/get.running.calculations.command";
import { RunCalculationsCommand } from "src/app/shared/commands/run.calculations.command";
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { CalculationsStatusViewModel } from "src/app/shared/viewmodels/calculations.status.viewmodel";
import { environment } from "src/environments/environment";

@Injectable()
export class CalculationsService extends RestServiceBase {

  private URL = environment.apiUrl + '/calculations';

  constructor(httpClient: HttpClient, authService: AuthenticationService) {
    super(httpClient, authService);
  }

  runCalculations(command: RunCalculationsCommand): Observable<CalculationsStatusViewModel> {
    return this.post<CalculationsStatusViewModel>(this.URL + `RunCalculations`, command);
  }

  cancelCalculations(command: CancelCalculationsCommand) {
    return this.post(this.URL + `CancelCalculations`, command);
  }

  getRunningCalculations(command: GetRunningCalculationsCommand): Observable<CalculationsStatusViewModel> {
    return this.post<CalculationsStatusViewModel>(this.URL + `GetRunningCalculations`, command);
  }

  getCalculationsStatus(command: GetCalculationsStatusCommand): Observable<CalculationsStatusViewModel> {
    return this.post<CalculationsStatusViewModel>(this.URL + `GetCalculationsStatus`, command);
  }
}
