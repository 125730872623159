import { Pipe, PipeTransform } from '@angular/core';
import { Rating } from 'src/app/shared/ratings/rating';

@Pipe({ name: 'ratingStringPipe' })
export class RatingStringPipe implements PipeTransform {

  transform(value: Rating): string {
    if (value == Rating.none)
      return "None";
    else if (value == Rating.mustImprove)
      return "Must Improve";
    else if (value == Rating.satisfactory)
      return "Satisfactory";
    else if (value == Rating.good)
      return "Good";
    else if (value == Rating.outstanding)
      return "Outstanding";
  }
}
