import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-files-folder-plus-icon',
  templateUrl: './files-folder-plus.component.html'
})
export class FilesFolderPlusComponent extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
