import { DateTime } from "src/app/shared/types/date.time";
import { DocumentViewModel } from "src/app/shared/viewmodels/document.viewmodel";
import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { ViewModel } from "src/app/shared/viewmodels/viewmodel";

export class QualitySurveyEditorViewModel extends ViewModel {
  isLocked: boolean;
  status: NameValueViewModel<number>;
  subService: NameValueViewModel<string>;
  createdDate: DateTime;
  kpiOrSLA: boolean;
  kpiOrSLATerms: string;
  kpiOrSLAMet: NameValueViewModel<number>
  kpiOrSLAEvidence: boolean;
  outsideKpiOrSla: boolean;
  additionalValues: string;
  fundIssues: string;
  improvements: string;
  comment: string;
  age: number;
  rejectComment: string;
  subServiceDefinition: string;
  kpiOrSLAMetOptions: Array<NameValueViewModel<number>>;
  attachments = new Array<DocumentViewModel>();
  evidenceAttachments = new Array<DocumentViewModel>();

  constructor(obj?: QualitySurveyEditorViewModel) {
    super(obj);
    if (obj) {
      this.isLocked = obj?.isLocked; 
      this.rowVersion = obj?.rowVersion; 
      this.status = new NameValueViewModel(obj.status);
      this.subService = new NameValueViewModel(obj.subService);
      this.kpiOrSLA = obj?.kpiOrSLA;
      this.kpiOrSLATerms = obj?.kpiOrSLATerms;
      this.kpiOrSLAMet = new NameValueViewModel(obj.kpiOrSLAMet);
      this.kpiOrSLAEvidence = obj?.kpiOrSLAEvidence;
      this.outsideKpiOrSla = obj?.outsideKpiOrSla;
      this.additionalValues = obj?.additionalValues;
      this.fundIssues = obj?.fundIssues;
      this.improvements = obj?.improvements;
      this.comment = obj?.comment;
      this.age = obj?.age;
      this.createdDate = obj?.createdDate;
      this.rejectComment = obj?.rejectComment;
      this.subServiceDefinition = obj?.subServiceDefinition;
      this.kpiOrSLAMetOptions = obj.kpiOrSLAMetOptions.map(o => new NameValueViewModel(o));
      this.attachments = obj?.attachments?.map((x: any) => new DocumentViewModel(x));
      this.evidenceAttachments = obj?.evidenceAttachments?.map((x: any) => new DocumentViewModel(x));                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
    }
    
  }
}
