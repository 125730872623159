import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { iconTypes } from 'src/app/icons/constants';
import { IconComponent } from 'src/app/icons/icon.component';

@NgModule({
  declarations: [
    IconComponent,
    ...iconTypes
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IconComponent
  ]
})
export class IconsModule { }
