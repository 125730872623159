
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Tools-/-Swiss-knife" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M15.7842712,14 L12.9779221,16.8063492 L7.82842712,11.6568542 C6.26632996,10.0947571 6.26632996,7.56209717 7.82842712,6 L14,12.1715729 L14,8 C14,4.6862915 16.6862915,2 20,2 L20,14 L15.7842712,14 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
        <path d="M5.5,12 L18.5,12 C20.9852814,12 23,14.0147186 23,16.5 C23,18.9852814 20.9852814,21 18.5,21 L5.5,21 C3.01471863,21 1,18.9852814 1,16.5 C1,14.0147186 3.01471863,12 5.5,12 Z M19.5,18 C20.3284271,18 21,17.3284271 21,16.5 C21,15.6715729 20.3284271,15 19.5,15 C18.6715729,15 18,15.6715729 18,16.5 C18,17.3284271 18.6715729,18 19.5,18 Z" id="Combined-Shape" fill="#000000"></path>
    </g>
</svg>
