<div class="row" [ngClass]="{'form-group': defaultMargins}">
  <div *ngIf="label"
         class="col-form-label text-left font-weight-bold"
         [ngClass]="fullWidth ? 'col-12 text-left': 'text-md-right col-12 col-md-9 px-2'">
    <div class="d-flex flex-row"
         [ngClass]="{'cursor-pointer': labelClickable && !isReadOnly}"
         (click)="labelClickable ? toggleControl() : null">
      <div class="d-flex position-relative">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <span *ngIf="tooltip" class="svg-icon" style="cursor: pointer"
              [ngClass]="{'svg-icon-primary': !hovering, 'svg-icon-warning': hovering}"
              (mouseenter)="hovering=true" (mouseleave)="hovering=false"
              container="body" tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
          <fit-icon icon="code-question-circle"></fit-icon>
        </span>


      </div>
    </div>
  </div>
  <div class="col-form-label"
       [ngClass]="fullWidth ? 'col-12' : 'col-12 col-md-3 px-2'">
    <div class="checkbox-inline">
      <label class="checkbox checkbox-outline">
        <input class="checkbox"
               type="checkbox"
               [formControl]="control"
               [attr.disabled]="isReadOnly ? true : null" />
        <span></span>
      </label>
    </div>
    <div *ngIf="helperText"
         class="form-text text-muted">{{ helperText }}</div>
  </div>
</div>

<ng-template #tipContent>
  <div *ngIf="tooltipTitle" class="d-flex">
    <span class="svg-icon svg-icon-primary">
      <fit-icon icon="code-info-circle"></fit-icon>
    </span>
    <b class="pl-1 tooltip-title">{{tooltipTitle}}</b>
  </div>
  <div class="d-flex tooltip-text">
    {{tooltip}}
  </div>
</ng-template>
