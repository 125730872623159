
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Tools-/-Spatula" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M8.79188247,3.31801948 L19.5720786,3.31801948 C19.8482209,3.31801948 20.0720786,3.54187711 20.0720786,3.81801948 C20.0720786,3.85094548 20.0688262,3.88379096 20.0623689,3.91607755 L18.4879986,11.7879291 C18.0774792,13.8405261 16.275227,15.3180195 14.1819805,15.3180195 L14.1819805,15.3180195 C12.088734,15.3180195 10.2864818,13.8405261 9.87596243,11.7879291 L8.30159213,3.91607755 C8.24743615,3.64529768 8.42304452,3.38188512 8.6938244,3.32772915 C8.72611099,3.32127183 8.75895647,3.31801948 8.79188247,3.31801948 Z" id="Rectangle-126" fill="#000000" opacity="0.3" transform="translate(14.181981, 9.318019) rotate(-315.000000) translate(-14.181981, -9.318019) "></path>
        <path d="M4.96174059,13.0963957 L7.84544682,13.0963957 L9.2623331,17.0271325 C9.83144628,18.6059707 9.01290245,20.3472293 7.43406428,20.9163425 C7.10356453,21.0354755 6.75490946,21.0963957 6.4035937,21.0963957 C4.72531489,21.0963957 3.36480109,19.7358819 3.36480109,18.0576031 C3.36480109,17.7062873 3.42572129,17.3576322 3.54485431,17.0271325 L4.96174059,13.0963957 Z M6.4035937,19.6678242 C7.19990719,19.6678242 7.84544682,19.028231 7.84544682,18.2392528 C7.84544682,17.4502746 7.19990719,16.8106814 6.4035937,16.8106814 C5.60728022,16.8106814 4.96174059,17.4502746 4.96174059,18.2392528 C4.96174059,19.028231 5.60728022,19.6678242 6.4035937,19.6678242 Z" id="Combined-Shape" fill="#000000" transform="translate(6.404008, 17.096396) rotate(-315.000000) translate(-6.404008, -17.096396) "></path>
    </g>
</svg>
