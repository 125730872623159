import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-communication-reply-all-icon',
  templateUrl: './communication-reply-all.component.html'
})
export class CommunicationReplyAllComponent extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
