
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Text-/-Paragraph" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M10.754,19.864 L10.754,12.54 C8.15,12.54 6.029,10.419 6.029,7.815 C6.029,5.211 8.15,3.09 10.754,3.09 L16.319,3.09 L16.319,19.864 L14.681,19.864 L14.681,4.728 L12.413,4.728 L12.413,19.864 L10.754,19.864 Z" id="¶" fill="#000000"></path>
    </g>
</svg>
