import { ModalResult } from './modal.result';

export class ModalButton {

  constructor(text: string,
    klass: string = 'btn-primary',
    result = ModalResult.primary,
    loadingText = 'Loading') {

    this.text = text;
    this.klass = klass;
    this.result = result;
    this.loadingText = loadingText;

  }

  text: string;
  klass: string;
  isLoading = false;
  loadingText: string;
  loadingClass: string = 'spinner-white';
  result: ModalResult;
}