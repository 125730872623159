<div *ngIf="label">
  <p class="font-weight-bold my-2">{{label}}</p>
</div>
<div ngbDropdown [placement]="direction" class="d-inline-block py-2 my-n2" container="body">
  <button class="btn mr-1 mt-1 d-flex align-items-center"
          [ngClass]="btnClass"
          [class.dropdown-toggle]="showToggleIcon"
          [disabled]="isDisabled"
          type="button"
          ngbDropdownToggle
          #button>
    <span class="d-flex flex-nowrap align-items-center">
      <ng-content select="[icon]"></ng-content>
      <span class="text-nowrap">{{ defaultText }}</span>
    </span>
    <ng-content select="[toolbar]"></ng-content>
  </button>
  <div ngbDropdownMenu aria-labelledby="id">
    <ng-content></ng-content>
  </div>
</div>

