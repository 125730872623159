import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate {

  constructor(private authService: AuthenticationService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.authService.isLoggedIn) {
      const allowedRoles = route.data.allowedRoles;

      if (!this.authService.isAuthorized(allowedRoles)) {
        this.router.navigate(['/teams/users']);
      }

      return true;
    }
    else {
      if (state.url === '/login') {
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/login'], {
          queryParams: {
            redirectUrl: state.url
          }
        });
      }
    }
    return false;
  }
}
