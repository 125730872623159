import { FormGroup, ValidatorFn } from '@angular/forms';
import { TypedFormControl } from 'src/app/shared/forms/typed.form.control';
import { TypedFormArray } from 'src/app/shared/forms/typed.form.array';

export class TypedForm extends FormGroup {

  constructor() {
    super({});
  }

  addTypedControl<T>(name: string, validators: ValidatorFn[] = [], initialValue: T = null, enabled = true): TypedFormControl<T> {
    const control = new TypedFormControl<T>(initialValue, validators);
    enabled ? control.enable() : control.disable();
    this.addControl(name, control);
    return control;
  }

  addTypedForm<T>(name: string, form: TypedForm): T{
    this.addControl(name, form);
    return form as unknown as T;
  }

}
