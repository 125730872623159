import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inScopeLabelClassPipe'
})
export class InScopeLabelClassPipe implements PipeTransform {

  transform(value: number): any {
    
    switch(value) {
      case 1: 
        return 'label-outline-dark';
      case 2: 
        return 'label-success';
      case 3: 
        return 'label-light-dark';
      default:
        return 'svg-icon-primary';
    }
  }
}
