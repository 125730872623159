import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { AddNewFundToRangeCommand } from 'src/app/surveys/fundrange-survey/fundrange-funds/commands/add.new.fund.to.range.command';
import { DeleteFundFromRangeCommand } from 'src/app/surveys/fundrange-survey/fundrange-funds/commands/delete.fund.from.range.command';
import { GetFundRangeFundsCommand } from 'src/app/surveys/fundrange-survey/fundrange-funds/commands/get.fund.range.funds.command';
import { SubmitFundRangeSurveyCommand } from 'src/app/surveys/fundrange-survey/fundrange-funds/commands/submit.fund.range.survey.command';
import { FundRangeFundsViewModel } from 'src/app/surveys/fundrange-survey/fundrange-funds/viewmodels/fund.range.funds.viewmodel';
import { environment } from 'src/environments/environment';

@Injectable()
export class FundRangeFundsService extends RestServiceBase {
  private URL = environment.apiUrl + '/cs';

  constructor(httpClient: HttpClient,
    authService: AuthenticationService,
    private router: Router) {
    super(httpClient, authService);
  }

  getFundRangeFunds(command: GetFundRangeFundsCommand): Observable<FundRangeFundsViewModel> {
    return this.post<FundRangeFundsViewModel>(this.URL + 'GetFundRangeFundsForUser', command)
  }

  addNewFund(command: AddNewFundToRangeCommand) {
    return this.post(this.URL + 'AddNewFundToRange', command);
  }

  deleteFund(command: DeleteFundFromRangeCommand) {
    return this.post(this.URL + 'DeleteFundFromRange', command);
  }

  submitSurvey(command: SubmitFundRangeSurveyCommand) {
    return this.post(this.URL + 'SubmitFundRangeSurvey', command);
  }
}
