import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { ViewModel } from "src/app/shared/viewmodels/viewmodel";

export class FundRangeFundViewModel extends ViewModel {
  fund: NameValueViewModel<string>;
  currency: NameValueViewModel<number>;
  status: string;
  isInScope: NameValueViewModel<number>;
  hasBeenClassified: boolean;

  constructor(obj?: FundRangeFundViewModel) {
    super(obj);
    this.fund = new NameValueViewModel(obj?.fund);
    this.currency = new NameValueViewModel(obj?.currency);
    this.status = obj?.status;
    this.isInScope = new NameValueViewModel(obj?.isInScope);
    this.hasBeenClassified = obj?.hasBeenClassified
  }
}
