import { Component, Input } from '@angular/core';
import { QualitySurveyForm } from 'src/app/surveys/quality-survey/forms/quality.survey.form';

@Component({
  selector: 'fit-quality-survey-editor',
  templateUrl: './quality.survey.editor.component.html',
  styleUrls: ['./quality.survey.editor.component.scss']
})
export class QualitySurveyEditorComponent {

  @Input()
  survey: QualitySurveyForm;

  @Input()
  isReadOnly = false;
}


