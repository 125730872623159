
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Code-/-Control" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M12,6.5 L3.64413575,13.7649112 C3.22168696,14.1206575 2.59083515,14.0665845 2.2350888,13.6441357 C1.87934245,13.221687 1.93341546,12.5908352 2.35586425,12.2350888 L11.0057493,4.44493319 C11.5881366,3.95450184 12.4455639,3.97817779 13,4.5 L21.6853647,12.2718 C22.0875384,12.6503165 22.1067165,13.2831909 21.7282,13.6853647 C21.3496835,14.0875384 20.7168091,14.1067165 20.3146353,13.7282 L12,6.5 Z" id="Path-89" fill="#000000" fill-rule="nonzero"></path>
    </g>
</svg>
