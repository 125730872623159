<div class="d-flex flex-row-fluid login">
  <div class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
       style="background-image: url('assets/media/bg/bg-3.jpg');">
    <div class="container mt-20" style="width:600px;">
      <div class="mb-10" style="background-color:#055a61;">
        <p class="text-center" style="color:white; font-size:20px;">M&G Value Assessment</p>
      </div>
      <fit-clean-slate [hasBorder]="false">
        <div class="d-flex justify-content-center">
          <img src="assets/img/clean-slate/submarine-slate.svg"
               width="120"
               class="mb-5" />
        </div>
      </fit-clean-slate>
      <div *ngIf="error == false" class="pl-20">
        <h4>Please wait while you are redirected{{dots}}</h4>
      </div>
      <div *ngIf="error == true" class="alert alert-danger text-center" role="alert">
        {{ message }}
      </div>
    </div>
  </div>
</div>
