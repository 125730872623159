import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { GetFundRangeFundSurveyCommand } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/commands/get.fund.range.fund.survey.command';
import { SaveFundRangeFundSurveyCommand } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/commands/save.fund.range.fund.survey.command';
import { FundRangeFundSurveyEditorViewModel } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/viewmodels/fund.range.fund.survey.editor.viewmodel';
import { environment } from 'src/environments/environment';

@Injectable()
export class FundRangeFundSurveyService extends RestServiceBase {
  private URL = environment.apiUrl + '/cs';

  constructor(httpClient: HttpClient,
    authService: AuthenticationService,
    private router: Router) {
    super(httpClient, authService);
  }

  getFundRangeFundSurvey(command: GetFundRangeFundSurveyCommand): Observable<FundRangeFundSurveyEditorViewModel> {
    return this.post<FundRangeFundSurveyEditorViewModel>(this.URL + 'GetFundRangeFundSurvey', command)
  }

  saveSurvey(command: SaveFundRangeFundSurveyCommand) {
    return this.post(this.URL + `SaveFundRangeFundSurvey`, command);
  }

}
