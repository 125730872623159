import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeaderService } from 'src/app/navigation/services/header.service';
import { NavigationService } from 'src/app/navigation/services/navigation.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { AsideMenuComponent } from 'src/app/navigation/components/aside.menu.components';
import { MobileHeaderComponent } from 'src/app/navigation/components/mobile.header.component';
import { TopbarComponent } from 'src/app/navigation/components/topbar.component';
import { IconsModule } from 'src/app/icons/icons.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    IconsModule
  ],
  declarations: [
    AsideMenuComponent,
    MobileHeaderComponent,
    TopbarComponent
  ],
  providers: [
    NavigationService,
    HeaderService
  ],
  exports: [
    AsideMenuComponent,
    MobileHeaderComponent,
    TopbarComponent
  ]
})

export class NavigationModule { }
