export enum Colour {
  blue = 0,
  indigo = 1,
  purple = 2,
  pink = 3,
  red = 4,
  orange = 5,
  yellow = 6,
  green = 7,
  teal = 8,
  cyan = 9,
  white = 10,
  gray = 11,
  graydark = 12,
  primary = 13,
  secondary = 14,
  success = 15,
  info = 16,
  warning = 17,
  danger = 18,
  light = 19,
  dark = 20,
  lightPrimary = 21,
  lightSecondary = 22,
  lightSuccess = 23,
  lightInfo = 24,
  lightWarning = 25,
  lightDanger = 26,
}

export class ColourValue {

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }

  name: string;
  value: string;
}

export class Palette {

  private static _colourMap = new Map<Colour, ColourValue>([
    [Colour.blue, new ColourValue('Blue', '#007bff')],
    [Colour.indigo, new ColourValue('Indigo', '#6610f2')],
    [Colour.purple, new ColourValue('Purple', '#6f42c1')],
    [Colour.pink, new ColourValue('Pink', '#e83e8c')],
    [Colour.red, new ColourValue('Red', '#dc3545')],
    [Colour.orange, new ColourValue('Orange', '#fd7e14')],
    [Colour.yellow, new ColourValue('Yellow', '#ffc107')],
    [Colour.green, new ColourValue('Green', '#28a745')],
    [Colour.teal, new ColourValue('Teal', '#20c997')],
    [Colour.cyan, new ColourValue('Cyan', '#17a2b8')],
    [Colour.white, new ColourValue('White', '#ffffff')],
    [Colour.gray, new ColourValue('Gray', '#7E8299')],
    [Colour.graydark, new ColourValue('Gray Dark', '#3F4254')],
    [Colour.primary, new ColourValue('Primary', '#3699FF')],
    [Colour.secondary, new ColourValue('Secondary', '#E4E6EF')],
    [Colour.success, new ColourValue('Success', '#1BC5BD')],
    [Colour.info, new ColourValue('Info', '#8950FC')],
    [Colour.warning, new ColourValue('Warning', '#FFA800')],
    [Colour.danger, new ColourValue('Danger', '#F64E60')],
    [Colour.light, new ColourValue('Light', '#F3F6F9')],
    [Colour.dark, new ColourValue('Dark', '#181C32')],
    [Colour.lightPrimary, new ColourValue('LightPrimary', '#E1F0FF')],
    [Colour.lightSecondary, new ColourValue('LightSecondary', '#EBEDF3')],
    [Colour.lightSuccess, new ColourValue('LightSuccess', '#C9F7F5')],
    [Colour.lightInfo, new ColourValue('LightInfo', '#EEE5FF')],
    [Colour.lightWarning, new ColourValue('LightWarning', '#FFF4DE')],
    [Colour.lightDanger, new ColourValue('LightDanger', '#FFE2E5')],
  ]);

  static getColours(): string[] {
    const colours = new Array<string>();
    this._colourMap.forEach(c => colours.push(c.value));
    return colours;
  }

  static getChartColours(): string[] {
    const colours = [
      this._colourMap.get(Colour.primary).value,
      this._colourMap.get(Colour.teal).value,
      this._colourMap.get(Colour.danger).value,
      this._colourMap.get(Colour.warning).value,
      this._colourMap.get(Colour.orange).value,
      this._colourMap.get(Colour.success).value,
      this._colourMap.get(Colour.info).value,
      this._colourMap.get(Colour.cyan).value,
      this._colourMap.get(Colour.pink).value,
      this._colourMap.get(Colour.green).value,
      this._colourMap.get(Colour.purple).value,
      this._colourMap.get(Colour.indigo).value,
      this._colourMap.get(Colour.yellow).value];
    return colours;
  }

  static getShuffledChartColours(): string[] {
    const colours = this.getChartColours();
    let currentIndex = colours.length;
    while (0 !== currentIndex) {
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      const temporaryValue = colours[currentIndex];
      colours[currentIndex] = colours[randomIndex];
      colours[randomIndex] = temporaryValue;
    }
    return colours;
  }

  static getColourMap(): Map<Colour, ColourValue> {
    return this._colourMap;
  }

  static getColour(colour: Colour): ColourValue {
    return this._colourMap.get(colour);
  }

  static getColourValueMap(): Map<Colour, string> {
    const map = new Map<Colour, string>();
    this._colourMap.forEach((v, k) => map.set(k, v.value));
    return map;
  }

  static getColourNameMap(): Map<string, string> {
    const map = new Map<string, string>();
    this._colourMap.forEach((v) => map.set(v.name, v.value));
    return map;
  }
}

