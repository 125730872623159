<div class="row" [ngClass]="{'form-group': defaultMargins}">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left' : 'text-md-right col-12 col-md-3'">
    <div class="d-flex flex-row">
      <div class="d-flex position-relative">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <span *ngIf="tooltip" class="svg-icon" style="cursor: pointer"
              [ngClass]="{'svg-icon-primary': !hovering, 'svg-icon-warning': hovering}"
              (mouseenter)="hovering=true" (mouseleave)="hovering=false"
              container="body" tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
          <fit-icon icon="code-question-circle"></fit-icon>
        </span>

      </div>
    </div>
  </div>


  <div class="col-12" [ngClass]="{ 'col-md-9' : !fullWidth, 'mt-3': busyLoading && !fullWidth, 'mt-5': busyLoading && fullWidth}">
    <!--Editable-->
    <div *ngIf="busyLoading" [ngClass]="fullWidth ? 'mb-7' : '' ">
      <span class="spinner spinner-primary"></span>
    </div>

    <div *ngIf="!isReadOnly && !busyLoading && options && options.length > 0">
      <select class="form-control"
              type="text"
              [ngClass]="{'is-invalid': control.invalid && control.touched}"
              [formControl]="control"
              [style.width]="width"
              (ngModelChange)="handleChange($event)"
              #input>
        <option *ngFor="let option of options"
                [ngValue]="value ? option[value] : option">
          {{display ? option[display] : option}}
        </option>
      </select>
      <span *ngIf="helperText"
            class="form-text text-muted">{{helperText}}</span>
      <div *ngIf="control.invalid && control.touched && !isReadOnly"
           class="fv-plugins-message-container">
        <div class="fv-help-block">
          {{getErrorMessage()}}
        </div>
      </div>
    </div>

    <!-- No Items -->
    <div *ngIf="!isReadOnly && !busyLoading && (!options || options?.length < 1)">
      <input class="form-control text-muted"
             type="text"
             [style.width]="width"
             [attr.placeholder]="placeHolder"
             [attr.autofocus]="autofocus"
             value="No Options"
             readonly disabled/>
      <span *ngIf="helperText"
            class="form-text text-muted">
        {{helperText}}
      </span>
    </div>

    <!--Read Only-->
    <div *ngIf="isReadOnly && !busyLoading">
      <input class="form-control"
             type="text"
             [formControl]="control"
             [style.width]="width"
             [attr.placeholder]="placeHolder"
             [attr.autofocus]="autofocus"
             readonly />
      <span *ngIf="helperText"
            class="form-text text-muted">
        {{helperText}}
      </span>
    </div>


  </div>
</div>


<ng-template #tipContent>
  <div *ngIf="tooltipTitle" class="d-flex">
    <span class="svg-icon svg-icon-primary">
      <fit-icon icon="code-info-circle"></fit-icon>
    </span>
    <b class="pl-1 tooltip-title">{{tooltipTitle}}</b>
  </div>
  <div class="d-flex tooltip-text">
    {{tooltip}}
  </div>

</ng-template>
