import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AutologinComponent } from 'src/app/auto-login/components/autologin.component';
import { LoginComponent } from 'src/app/login/components/login.component';
import { IsUnauthenticatedGuard } from 'src/app/shared/guards/is.unauthenticated.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: LoginComponent },
  //{ path: 'login', component: LoginComponent, canActivate: [IsUnauthenticatedGuard] },
  { path: 'autologin', component: AutologinComponent, canActivate: [MsalGuard] },
  { path: 'landing', loadChildren: () => import('src/app/landing/landing.module').then(m => m.LandingModule) },
  { path: 'teams', loadChildren: () => import('src/app/teams/teams.module').then(m => m.TeamsModule) },
  { path: 'assessments', loadChildren: () => import('src/app/assessments/assessments.module').then(m => m.AssessmentsModule) },
  { path: 'assessments/criteria/rates/:assessmentId', loadChildren: () => import('src/app/rates/rates.module').then(m => m.RatesModule) },
  { path: 'assessments/criteria/performance/:assessmentId', loadChildren: () => import('src/app/performance/performance.module').then(m => m.PerformanceModule) },
  { path: 'assessments/criteria/qualityofservice/:assessmentId', loadChildren: () => import('src/app/quality-of-service/quality.of.service.module').then(m => m.QualityOfServiceModule) },
  { path: 'assessments/criteria/comparable-services/:assessmentId', loadChildren: () => import('src/app/comparable-services/comparable.services.module').then(m => m.ComparableServicesModule) },
  { path: 'assessments/criteria/afm-costs/:assessmentId', loadChildren: () => import('src/app/afm-costs/afm.costs.module').then(m => m.AFMCostsModule) },
  { path: 'assessments/criteria/economies-of-scale/:assessmentId', loadChildren: () => import('src/app/economies-of-scale/economies.of.scale.module').then(m => m.EconomiesOfScaleModule) },
  { path: 'assessments/criteria/classes-of-units/:assessmentId', loadChildren: () => import('src/app/classes-of-units/classes.of.units.module').then(m => m.ClassesOfUnitsModule) },
  { path: 'assessments/criteria/cross-criteria/:assessmentId', loadChildren: () => import('src/app/cross-criteria/cross.criteria.module').then(m => m.CrossCriteriaModule) },
  { path: 'reference', loadChildren: () => import('src/app/reference/reference.module').then(m => m.ReferenceModule) },
  { path: 'error', loadChildren: () => import('src/app/server-error/server-error.module').then(m => m.ServerErrorModule) },
  { path: '', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
