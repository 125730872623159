<div class="form-group row">
  <label *ngIf="showLabel"
         class="col-12 font-weight-bold">
    Attachments
  </label>
  <div class="col">
    <ngx-dropzone (change)="onSelect($event)"
                  class="small-dropzone"
                  [maxFileSize]="maxFileSize"
                  [multiple]="allowMultiple"
                  [style.width]="width"
                  [disabled]="isReadOnly"
                  [disableClick]="shouldDisableClick">
      <ngx-dropzone-label>
        <span class="svg-icon svg-icon-2x svg-icon-primary">
          <fit-icon icon="files-file-plus"></fit-icon>
        </span>
        <span class="font-weight-bold text-muted">
          Drop files here, or click to upload
        </span>
      </ngx-dropzone-label>
    </ngx-dropzone>
    <small class="form-text text-muted">
      File max size is 25 MB
    </small>
    <table *ngIf="hasFiles"
           class="table table-sm table-borderless mt-2"
           [style.width]="width">
      <colgroup>
        <col width="100%" />
        <col width="0%" />
        <col width="0%" />
        <col width="0%" />
      </colgroup>
      <ng-container *ngFor="let attachment of attachments">
        <ng-container>
          <tr *ngIf="attachment.editState !== EditState.deleted">
            <td class="truncate-column">
              {{ attachment.name }}
            </td>
            <td class="text-right align-middle">
              <span class="label label-light-success label-inline m-0 nowrap font-weight-bold">
                {{ attachment.size | filesize }}
              </span>
            </td>
            <td class="pr-0">
              <a (click)="downloadAttachment(attachment)"
                 class="navi-link pointer-cursor"
                 title="Download">
                <span class="svg-icon navi-icon svg-icon-primary">
                  <fit-icon icon="files-download"></fit-icon>
                </span>
              </a>
            </td>
            <td>
              <a (click)="isReadOnly ? null : onRemove(attachment)"
                 class="navi-link"
                 [ngClass]="{'pointer-cursor': !isReadOnly}"
                 title="Delete">
                <span class="svg-icon navi-icon"
                      [ngClass]="{'svg-icon-danger': !isReadOnly, 'svg-icon-default': isReadOnly}">
                  <fit-icon icon="general-trash"></fit-icon>
                </span>
              </a>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </table>
  </div>
</div>
