
<div class="p-1 mb-2 rounded" *ngIf="hasSearch" style="background-color: white; width: 306px">
  <ngx-skeleton-loader [animation]="animation" [theme]="searchTheme"></ngx-skeleton-loader>
</div>
<div class="card col-12 px-0">
  <table class="table table-rounded p-4">
    <thead>
      <tr>
        <ng-container *ngFor="let i of [].constructor(columnCount)">
          <th><ngx-skeleton-loader [animation]="animation" [theme]="headerTheme"></ngx-skeleton-loader></th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let i of [].constructor(rowCount)">
        <tr>
          <ng-container *ngFor="let i of [].constructor(columnCount)">
            <td><ngx-skeleton-loader [animation]="animation" [theme]="dataTheme"></ngx-skeleton-loader></td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
