import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';

@Injectable()
export class HeaderService extends RestServiceBase {

  constructor(httpClient: HttpClient, authService: AuthenticationService) {
    super(httpClient, authService);
  }
}
