import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { delay, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { DebugDelay } from 'src/app/shared/debug/debug.delay';
import { ConfirmModalComponent, ConfirmModalConfig } from 'src/app/shared/modals/confirm-modal/confirm.modal.component';
import { ModalButton } from 'src/app/shared/modals/modal.button';
import { ModalResult } from 'src/app/shared/modals/modal.result';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { GetPricingSurveyCommand } from 'src/app/surveys/pricing-survey/commands/get.pricing.survey.command';
import { SubmitPricingSurveyCommand } from 'src/app/surveys/pricing-survey/commands/submit.pricing.survey.command';
import { PricingSurveyForm } from 'src/app/surveys/pricing-survey/forms/pricing.survey.form';
import { PricingSurveysService } from 'src/app/surveys/pricing-survey/pricing.surveys.service';
import { PricingSurveyEditorViewModel } from 'src/app/surveys/pricing-survey/viewmodels/pricing.survey.editor.viewmodel';

@Component({
  selector: 'fit-pricing-survey',
  templateUrl: './pricing.survey.component.html',
  styleUrls: ['./pricing.survey.component.scss']
})
export class PricingSurveyComponent {
  busyLoading = false;
  busySubmitting = false;

  showScenarios = true;
  surveyId: string;
  form: PricingSurveyForm;
  viewModel: PricingSurveyEditorViewModel;

  constructor(private captureService: PricingSurveysService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private title: Title,
    private location: Location,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: Document) {
  }

  get canRefresh() {
    return !this.busyLoading;
  }

  get canSubmit() {
    return !this.busyLoading && !this.busySubmitting && this.form?.valid && this.form?.dirty;
  }

  get canReset() {
    return !this.busyLoading && !this.busySubmitting && this.form?.dirty;
  }

  ngOnInit() {
    this.title.setTitle("Capture Survey");
    this.surveyId = this.route.snapshot.params.surveyId;
    this.load();
  }


  load() {
    this.busyLoading = true;

    const command = new GetPricingSurveyCommand(this.surveyId, null);

    this.captureService.getPricingSurvey(command)
      .pipe(delay(DebugDelay.value), finalize(() => this.busyLoading = false))
      .subscribe((data) => {
        this.viewModel = new PricingSurveyEditorViewModel(data);
        this.form = new PricingSurveyForm(this.viewModel);
      });
  }

  submitSurvey() {
    this.busySubmitting = true;

    event.stopPropagation();

    const confirmModal = this.modalService.createInstance(ConfirmModalComponent, {
      title: 'Confirm Survey Submission',
      size: 'sm',
      message: `Are you sure you want to submit the survey?`,
      primaryButton: new ModalButton('Submit', 'btn-light-primary', ModalResult.primary, 'Submitting')
    } as ConfirmModalConfig);

    confirmModal.result.pipe(
      tap(r => r !== ModalResult.primary ? confirmModal.hide() : {}),
      filter(r => r === ModalResult.primary),
      switchMap(() => {
        const command = new SubmitPricingSurveyCommand(
          this.form.getViewModel());

        return this.captureService.submitSurvey(command)
      })
    )
      .pipe(
        delay(DebugDelay.value),
        finalize(() => {
          confirmModal.hide(),
            this.busySubmitting = false;
        }))
      .subscribe(() => {
        this.toastr.success('Your survey has been submitted');
        this.goBack()
      });
  }

  resetSurvey() {
    this.form.populate(this.viewModel);
  }

  goBack(): void {
    this.location.back();
  }
}
