<!--begin::Main-->

<fit-mobile-header></fit-mobile-header>

<div class="d-flex flex-column flex-root">
  <!--begin::Page-->
  <div class="d-flex flex-row flex-column-fluid page">

    <fit-aside-menu></fit-aside-menu>

    <!--begin::Wrapper-->
    <div class="d-flex flex-column flex-row-fluid wrapper">

      <fit-topbar></fit-topbar>

      <div class="content d-flex flex-column flex-column-fluid"
           [ngClass]="{'p-0': !userLoggedIn}">
        <router-outlet></router-outlet>
      </div>

    </div>
    <!--end::Wrapper-->

  </div>
  <!--end::Page-->
</div>
<!--end::Main-->

<ng-template modalHost></ng-template>
