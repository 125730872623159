import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

export interface HttpRequestOptions {
  goToServerErrorPage: boolean;
  retryCount: number;
}

export const defaultRequestOptions: HttpRequestOptions = {
  goToServerErrorPage: false,
  retryCount: 2
};

@Injectable()
export abstract class RestServiceBase {

  defaultRequestOptions = {
    goToServerErrorPage: false,
    retryCount: 2
  };

  constructor(protected httpClient: HttpClient,
    protected authService: AuthenticationService) {
  }

  private getHeaders(options: HttpRequestOptions) : HttpHeaders {
    
    const headers = {
      'x-functions-key': environment.apiKey,
      'Authorization': this.authService.authToken,
    };

    headers['fit-retry-count'] = `${options.retryCount}`;

    if (options.goToServerErrorPage) {
      headers['fit-server-error-page'] = `${options.goToServerErrorPage}`;
    }

    return new HttpHeaders(headers);
  }

  private getOptions(options: Partial<HttpRequestOptions>): HttpRequestOptions {
    return {
      ...this.defaultRequestOptions,
      ...options
    };
  }

  get<T>(link: string, options: Partial<HttpRequestOptions> = this.defaultRequestOptions, params?: HttpParams): Observable<T> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.get<T>(link, {
      headers: headers,
      params: params
    });
  }

  getText(link: string, options: Partial<HttpRequestOptions> = this.defaultRequestOptions, params?: HttpParams): Observable<string> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.get(link, {
      headers: headers,
      params: params,
      responseType: 'text'
    });
  }

  getBlob(link: string, options: Partial<HttpRequestOptions> = this.defaultRequestOptions, params?: HttpParams): Observable<Blob> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.get(link, {
      headers: headers,
      params: params,
      responseType: 'blob'
    });
  }

  post<T>(link: string, body: any, options: Partial<HttpRequestOptions> = this.defaultRequestOptions): Observable<T> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.post<T>(link, body, { headers: headers });
  }

  postBlob(link: string,
    body: any,
    options: Partial<HttpRequestOptions> = this.defaultRequestOptions,
    params?: HttpParams): Observable<Blob> {

    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.post(link, body, {
      headers: headers,
      params: params,
      responseType: 'blob'
    });
  }

  postForm<T>(url: string, body: FormData,
    options: Partial<HttpRequestOptions> = this.defaultRequestOptions,
    params?: HttpParams): Observable<HttpEvent<T>> {

    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.post<T>(url, body, {
      headers: headers,
      observe: 'events',
      params: params,
      reportProgress: true
    });
  }

  put<T>(link: string, body: any, options: Partial<HttpRequestOptions> = this.defaultRequestOptions, params?: HttpParams): Observable<T> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.put<T>(link, body, {
      headers: headers,
      params: params
    });
  }

  delete<T>(link: string, options: Partial<HttpRequestOptions> = this.defaultRequestOptions): Observable<T> {
    const headers = this.getHeaders(this.getOptions(options));
    return this.httpClient.delete<T>(link, {
      headers: headers
    });
  }
}

