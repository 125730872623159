export enum PermissionItem {
  unknown = 0,
  viewQualitySurveyDashboard = 1,
  viewValueAssessments = 2,
  viewFunds = 3,
  viewShareClasses = 4,
  viewClassifications = 5,
  viewUsers = 6,
  viewGroups = 7,
  viewBenchmarks = 8,
  viewServices = 9,
  viewFundRanges = 10,
  viewPricingTeam = 11,
  viewFundRangeSurveyDashboard = 12,
  viewPricingSurveyDashboard = 13
}
