import { EditState } from 'src/app/shared/types/edit.state';
import { ViewModel } from 'src/app/shared/viewmodels/viewmodel';

export class DocumentViewModel extends ViewModel {
  name: string;
  file: File;
  fileName: string;
  fileType: string;
  externalStoreId: string;
  blobLink: string;
  fileSize: number;

  get size(): number {
    return this.fileSize * 1000;
  }

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.name = obj?.name;
      this.file = obj?.file;
      this.fileName = obj?.fileName;
      this.fileType = obj?.fileType;
      this.externalStoreId = obj?.externalStoreId;
      this.blobLink = obj?.blobLink;
      this.fileSize = obj?.fileSize;
      this.editState = EditState.unchanged;
    }
    
  }
}
