import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IconsModule } from 'src/app/icons/icons.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FundRangeSurveysComponent } from 'src/app/surveys/fundrange-survey/fund.range.surveys.component';
import { FundRangeSurveysService } from 'src/app/surveys/fundrange-survey/fund.range.surveys.service';
import { FundRangeFundSurveyComponent } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/fund.range.fund.survey.component';
import { FundRangeFundSurveyEditorComponent } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/fund.range.fund.survey.editor.component';
import { FundRangeFundSurveyService } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/fund.range.fund.survey.service';
import { FundRangeFundsComponent } from 'src/app/surveys/fundrange-survey/fundrange-funds/fund.range.funds.component';
import { FundRangeFundsService } from 'src/app/surveys/fundrange-survey/fundrange-funds/fund.range.funds.service';
import { InScopeLabelClassPipe } from 'src/app/surveys/fundrange-survey/pipes/in.scope.label.class.pipe';
import { PricingSurveyComponent } from 'src/app/surveys/pricing-survey/pricing.survey.component';
import { PricingSurveyEditorComponent } from 'src/app/surveys/pricing-survey/pricing.survey.editor.component';
import { PricingSurveysComponent } from 'src/app/surveys/pricing-survey/pricing.surveys.component';
import { PricingSurveysService } from 'src/app/surveys/pricing-survey/pricing.surveys.service';
import { QualitySurveyComponent } from 'src/app/surveys/quality-survey/quality.survey.component';
import { QualitySurveyEditorComponent } from 'src/app/surveys/quality-survey/quality.survey.editor.component';
import { QualitySurveysComponent } from 'src/app/surveys/quality-survey/quality.surveys.component';
import { QualitySurveysService } from 'src/app/surveys/quality-survey/quality.surveys.service';
import { SurveysRoutingModule } from 'src/app/surveys/routing/surveys.routing.module';

@NgModule({
  imports: [
    CommonModule,
    MomentModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    SurveysRoutingModule,
    IconsModule,
    NgxSkeletonLoaderModule
  ],
  declarations: [
    QualitySurveysComponent,
    QualitySurveyComponent,
    QualitySurveyEditorComponent,
    PricingSurveysComponent,
    PricingSurveyComponent,
    PricingSurveyEditorComponent,
    FundRangeSurveysComponent,
    FundRangeFundsComponent,
    FundRangeFundSurveyComponent,
    FundRangeFundSurveyEditorComponent,
    InScopeLabelClassPipe
  ],
  providers: [
    QualitySurveysService,
    PricingSurveysService,
    FundRangeSurveysService,
    FundRangeFundsService,
    FundRangeFundSurveyService
  ],
  exports: [
    QualitySurveyEditorComponent,
    PricingSurveyEditorComponent,
    FundRangeFundSurveyEditorComponent,
    InScopeLabelClassPipe
  ]
})
export class SurveysModule { }
