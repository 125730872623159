import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { PagerViewModel } from "src/app/shared/viewmodels/pager.viewmodel";
import { FundRangeFundViewModel } from "src/app/surveys/fundrange-survey/fundrange-funds/viewmodels/fund.range.fund.viewmodel";

export class FundRangeFundsViewModel {

  fundRange: string;
  funds = new Array<FundRangeFundViewModel>();
  pager: PagerViewModel;
  currencyList = new Array<NameValueViewModel<string>>();
  surveyRowVersion: string;
  surveyStatus: number;
  rejectComment: string;

  constructor(obj?: FundRangeFundsViewModel) {
    if (obj) {
      this.pager = new PagerViewModel(obj.pager);
      this.funds = obj.funds.map(a => new FundRangeFundViewModel(a));
      this.fundRange = obj.fundRange;
      this.currencyList = obj.currencyList.map(a => new NameValueViewModel<string>(a));
      this.surveyRowVersion = obj.surveyRowVersion;
      this.surveyStatus = obj.surveyStatus;
      this.rejectComment = obj.rejectComment;
    }
  }
}
