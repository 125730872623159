import { DateTime } from "src/app/shared/types/date.time";
import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { ViewModel } from "src/app/shared/viewmodels/viewmodel";

export class PricingSurveyViewModel extends ViewModel {
  status: NameValueViewModel<number>;
  fund: NameValueViewModel<string>;
  matchFund: NameValueViewModel<string>;
  createdDate: DateTime;
  age: number;
  valueAssessment: string;

  constructor(obj?: PricingSurveyViewModel) {
    super(obj);
    this.status = new NameValueViewModel(obj.status);
    this.fund = new NameValueViewModel(obj.fund);
    this.matchFund = new NameValueViewModel(obj.matchFund);
    this.createdDate = obj?.createdDate;
    this.age = obj?.age;
    this.valueAssessment = obj.valueAssessment;
  }
}
