import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { GetFundRangeSurveysCommand } from 'src/app/surveys/fundrange-survey/commands/get.fund.range.surveys.command';
import { FundRangeSurveysViewModel } from 'src/app/surveys/fundrange-survey/viewmodels/fund.range.surveys.viewmodel';
import { environment } from 'src/environments/environment';

@Injectable()
export class FundRangeSurveysService extends RestServiceBase {
  private URL = environment.apiUrl +'/cs';

  constructor(httpClient: HttpClient,
    authService: AuthenticationService,
    private router: Router) {
    super(httpClient, authService);
  }

  getFundRangeSurveysForUser(command: GetFundRangeSurveysCommand): Observable<FundRangeSurveysViewModel> {
    return this.post<FundRangeSurveysViewModel>(this.URL + 'GetFundRangeSurveysForUser', command)
  }
}
