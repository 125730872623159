<fit-subheader title="Capture Pricing Survey" [busyLoading]="busyLoading" [isFullWidth]="false">
  <div class="d-flex flex-row flex-wrap ml-auto">
    <fit-icon-button caption="Back"
                     (onClick)="goBack()"
                     icon="navigation-arrow-left"
                     class="btn-light-warning">
    </fit-icon-button>
    <fit-icon-button caption="Refresh"
                     (onClick)="load()"
                     icon="general-update"
                     [isDisabled]="!canRefresh"
                     [busyLoading]="busyLoading">
    </fit-icon-button>
  </div>
</fit-subheader>

<div class="container d-flex flex-column h-100">
  <ng-container *ngIf="!busyLoading else loading">
    <ng-container *ngTemplateOutlet="manageView"></ng-container>
  </ng-container>
</div>

<ng-template #manageView>

  <div class="card card-custom">
    <div class="card-header py-4">
      <h2 class="card-label font-weight-bold text-dark align-self-center m-0">
        Pricing Survey | {{viewModel?.fund.value}} - {{viewModel?.matchFund.name}}
      </h2>
      <div></div>
      <div class="card-toolbar">
        <fit-icon-button caption="Reset" (onClick)="resetSurvey()" [isDisabled]="!canReset" class="btn-light-warning"></fit-icon-button>
        <fit-icon-button caption="Submit" (onClick)="submitSurvey()" [isDisabled]="!canSubmit" [busyLoading]="busySubmitting" busyCaption="Submitting"></fit-icon-button>
      </div>
    </div>
    <div class="card-body py-3">
      <ng-container *ngTemplateOutlet="surveyDetails"></ng-container>
      <ng-container *ngTemplateOutlet="scenarios"></ng-container>
      <div *ngIf="viewModel?.rejectComment" class="alert alert-custom alert-light-danger mb-2 pt-3 px-9" style="flex-direction:column" role="alert">
        <div class="alert-heading"><b>Reject comment: </b></div>
        <p>{{viewModel?.rejectComment}}</p>
      </div>
      <fit-pricing-survey-editor [survey]="form"
                                 [isReadOnly]="false">
      </fit-pricing-survey-editor>
    </div>
  </div>

</ng-template>

<ng-template #loading>
  <fit-grid-skeleton [columnCount]="4"></fit-grid-skeleton>
</ng-template>

<ng-template #surveyDetails>
  <div class="card card-custom border mb-2" style="background-color: #fafafa; ">
    <div class="card-header min-h-50px">
      <div class="card-title m-0">
        Survey Details - Please justify the comparable services pricing outlier below
      </div>
    </div>
    <div class="card-body py-3">
      <div class="mt-3"><b>Status: </b><span class="ml-3 label label-lg label-inline {{viewModel?.status.value | surveyStatusColourClass}}">{{viewModel?.status.name}}</span></div>
      <div class="mt-3"><b>Date Created: </b><span class="ml-3">{{viewModel?.createdDate | amFromUtc | amLocal | amDateFormat: 'DD MMM YYYY'}}</span></div>
      <div class="mt-3"><b>Age: </b><span class="ml-3 label label-lg label-inline {{viewModel?.age | surveyAgeColourClass}}">{{viewModel?.age}} Day(s)</span></div>
      <div class="mt-3"><b>Scenario: </b><span class="ml-3">{{viewModel?.matchFundScenario}}</span></div>
      <div class="mt-3"><b>Threshold: </b><span class="ml-3">{{viewModel?.thresholdCalculation}}</span></div>
      <div class="mt-3"><b>Difference: </b><span class="ml-3">{{viewModel?.differenceCalculation.name}} = <span class="label label-lg label-inline label-warning">{{viewModel?.differenceCalculation.value}}</span></span></div>
      <div class="row mt-6">
        <div class="col">
          <span class="font-weight-bolder"><u>Fund</u></span>
          <div class="mt-3"><b>Code: </b><span class="ml-3">{{viewModel?.fund.value}}</span></div>
          <div class="mt-3"><b>Name: </b><span class="ml-3">{{viewModel?.fund.name}}</span></div>
          <div class="my-3"><b>Charge: </b><span class="ml-3">{{viewModel?.fundCharge}}</span></div>
        </div>
        <div class="col">
          <span class="font-weight-bolder"><u>Match Fund</u></span>
          <div class="mt-3"><b>Code: </b><span class="ml-3">{{viewModel?.matchFund.value}}</span></div>
          <div class="mt-3"><b>Name: </b><span class="ml-3">{{viewModel?.matchFund.name}}</span></div>
          <div class="my-3"><b>Charge: </b><span class="ml-3">{{viewModel?.matchFundCharge}}</span></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #scenarios>
  <div class="card card-custom border mb-2" style="background-color: #fafafa; ">
    <div class="card-header cursor-pointer min-h-50px"
         (click)="collapse.toggle()"
         [attr.aria-expanded]="showScenarios"
         aria-controls="collapseExample">
      <div class="card-title m-0">
        Scenarios
      </div>
      <div class="d-flex"
           style="align-items: center; cursor: pointer;"
           (click)="toggleDropRow(user)">
        <span class="svg-icon drop-row-icon d-block"
              style="vertical-align: middle;"
              [ngClass]="{'active': !showScenarios}">
          <fit-icon icon="design-triangle"></fit-icon>
        </span>
      </div>

    </div>
    <div class="card-body py-3"
         #collapse="ngbCollapse"
         [(ngbCollapse)]="showScenarios">
      <div>
        <div class="mt-3">
          <b>Scenario 1 </b>- Represents the Match being another Retail Fund. A tolerance of 5bps cheaper is allowed.
        </div>
        <div class="mt-3 d-flex flex-row">
          <div><b>Scenario 2 </b>-</div>
          <div>
            &nbsp;Represents other types of products and the tolerance is based on the charge of the in scope Fund on a sliding scale.<br>
            If the fund charge is &gt;50bps then a tolerance of 15bps is applied.<br>
            If it is &lt;= 50bps and &gt;30bps then a tolerance of 10bps is applied.<br>
            If it is &lt;=30bps then a tolerance of 5bps is applied.
          </div>
        </div>
        <div class="mt-3">
          <b>Scenario 3 </b>- Represents External Seg Mandates and 30bps tolerance applied
        </div>
        <div class="my-3">
          <b>Scenario 4 & 5 </b>- Represent Internal Seg Mandates and they are compared to the internal rate card
        </div>
      </div>
    </div>
  </div>
</ng-template>
