
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Tools-/-Axe" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M15.2426407,15.8284271 L21.6066017,9.46446609 L23.0208153,10.8786797 L16.6568542,17.2426407 L15.2426407,15.8284271 Z M1.80761184,16.5355339 L11.7071068,6.63603897 C12.0976311,6.24551468 12.7307961,6.24551468 13.1213203,6.63603897 L14.5355339,8.05025253 C14.9260582,8.44077682 14.9260582,9.0739418 14.5355339,9.46446609 L4.63603897,19.363961 C4.24551468,19.7544853 3.6123497,19.7544853 3.22182541,19.363961 L1.80761184,17.9497475 C1.41708755,17.5592232 1.41708755,16.9260582 1.80761184,16.5355339 Z M15.9497475,3.80761184 L17.363961,5.22182541 C17.7544853,5.6123497 17.7544853,6.24551468 17.363961,6.63603897 L16.6568542,7.34314575 C16.26633,7.73367004 15.633165,7.73367004 15.2426407,7.34314575 L13.8284271,5.92893219 C13.4379028,5.5384079 13.4379028,4.90524292 13.8284271,4.51471863 L14.5355339,3.80761184 C14.9260582,3.41708755 15.5592232,3.41708755 15.9497475,3.80761184 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
        <path d="M19.3284271,3.55025253 C16.9950938,4.88358587 15.3284271,5.55025253 14.3284271,5.55025253 C13.3284271,5.55025253 11.3284271,5.55025253 8.32842712,5.55025253 L8.32842712,10.5502525 C10.9950938,10.5502525 12.9950938,10.5502525 14.3284271,10.5502525 C15.6617605,10.5502525 17.3284271,11.2169192 19.3284271,12.5502525 L19.3284271,3.55025253 Z" id="Path-44" fill="#000000" transform="translate(13.828427, 8.050253) rotate(-675.000000) translate(-13.828427, -8.050253) "></path>
    </g>
</svg>
