import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetPricingSurveyCommand } from 'src/app/surveys/pricing-survey/commands/get.pricing.survey.command';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { GetPricingSurveysCommand } from 'src/app/surveys/pricing-survey/commands/get.pricing.surveys.command';
import { SubmitPricingSurveyCommand } from 'src/app/surveys/pricing-survey/commands/submit.pricing.survey.command';
import { PricingSurveyEditorViewModel } from 'src/app/surveys/pricing-survey/viewmodels/pricing.survey.editor.viewmodel';
import { PricingSurveysViewModel } from 'src/app/surveys/pricing-survey/viewmodels/pricing.surveys.viewmodel';
import { environment } from 'src/environments/environment';

@Injectable()
export class PricingSurveysService extends RestServiceBase {
  private URL = environment.apiUrl +'/cs';

  constructor(httpClient: HttpClient,
    authService: AuthenticationService,
    private router: Router) {
    super(httpClient, authService);
  }

  getPricingSurveysForUser(command: GetPricingSurveysCommand): Observable<PricingSurveysViewModel> {
    return this.post<PricingSurveysViewModel>(this.URL + 'GetPricingSurveysForUser', command)
  }

  getPricingSurvey(command: GetPricingSurveyCommand): Observable<PricingSurveyEditorViewModel> {
    return this.post<PricingSurveyEditorViewModel>(this.URL + 'GetPricingSurvey', command)
  }

  submitSurvey(command: SubmitPricingSurveyCommand) {
    return this.post(this.URL + 'SubmitPricingSurvey', command)
  }
}
