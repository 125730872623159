import { CalendarComponent } from 'src/app/icons/components/calendar.component';
import { ClockComponent } from 'src/app/icons/components/clock.component';
import { CodeBackspaceComponent } from 'src/app/icons/components/code-backspace.component';
import { CodeCMDComponent } from 'src/app/icons/components/code-cmd.component';
import { CodeCodeComponent } from 'src/app/icons/components/code-code.component';
import { CodeCommitComponent } from 'src/app/icons/components/code-commit.component';
import { CodeCompilingComponent } from 'src/app/icons/components/code-compiling.component';
import { CodeControlComponent } from 'src/app/icons/components/code-control.component';
import { CodeDoneCircleComponent } from 'src/app/icons/components/code-done-circle.component';
import { CodeErrorCircleComponent } from 'src/app/icons/components/code-error-circle.component';
import { CodeGit1Component } from 'src/app/icons/components/code-git1.component';
import { CodeGit2Component } from 'src/app/icons/components/code-git2.component';
import { CodeGit3Component } from 'src/app/icons/components/code-git3.component';
import { CodeGit4Component } from 'src/app/icons/components/code-git4.component';
import { CodeGithubComponent } from 'src/app/icons/components/code-github.component';
import { CodeInfoCircleComponent } from 'src/app/icons/components/code-info-circle.component';
import { CodeLeftCircleComponent } from 'src/app/icons/components/code-left-circle.component';
import { CodeLoadingComponent } from 'src/app/icons/components/code-loading.component';
import { CodeLockCircleComponent } from 'src/app/icons/components/code-lock-circle.component';
import { CodeLockOverturningComponent } from 'src/app/icons/components/code-lock-overturning.component';
import { CodeMinusComponent } from 'src/app/icons/components/code-minus.component';
import { CodeOptionComponent } from 'src/app/icons/components/code-option.component';
import { CodePlusComponent } from 'src/app/icons/components/code-plus.component';
import { CodePuzzleComponent } from 'src/app/icons/components/code-puzzle.component';
import { CodeQuestionCircleComponent } from 'src/app/icons/components/code-question-circle.component';
import { CodeRightCircleComponent } from 'src/app/icons/components/code-right-circle.component';
import { CodeSettings4Component } from 'src/app/icons/components/code-settings4.component';
import { CodeShiftComponent } from 'src/app/icons/components/code-shift.component';
import { CodeSpyComponent } from 'src/app/icons/components/code-spy.component';
import { CodeStopComponent } from 'src/app/icons/components/code-stop.component';
import { CodeTerminalComponent } from 'src/app/icons/components/code-terminal.component';
import { CodeThunderCircleComponent } from 'src/app/icons/components/code-thunder-circle.component';
import { CodeTimeScheduleComponent } from 'src/app/icons/components/code-time-schedule.component';
import { CodeWarning1CircleComponent } from 'src/app/icons/components/code-warning-1-circle.component';
import { CodeWarning2Component } from 'src/app/icons/components/code-warning-2.component';
import { CommunicationActiveCallComponent } from 'src/app/icons/components/communication-active-call.component';
import { CommunicationAddUserComponent } from 'src/app/icons/components/communication-add-user.component';
import { CommunicationAddressCardComponent } from 'src/app/icons/components/communication-address-card.component';
import { CommunicationAdressBook1Component } from 'src/app/icons/components/communication-adress-book1.component';
import { CommunicationAdressBook2Component } from 'src/app/icons/components/communication-adress-book2.component';
import { CommunicationArchiveComponent } from 'src/app/icons/components/communication-archive.component';
import { CommunicationCallComponent } from 'src/app/icons/components/communication-call.component';
import { CommunicationCall1Component } from 'src/app/icons/components/communication-call1.component';
import { CommunicationChatCheckComponent } from 'src/app/icons/components/communication-chat-check.component';
import { CommunicationChatErrorComponent } from 'src/app/icons/components/communication-chat-error.component';
import { CommunicationChatLockedComponent } from 'src/app/icons/components/communication-chat-locked.component';
import { CommunicationChatSmileComponent } from 'src/app/icons/components/communication-chat-smile.component';
import { CommunicationChat1Component } from 'src/app/icons/components/communication-chat1.component';
import { CommunicationChat2Component } from 'src/app/icons/components/communication-chat2.component';
import { CommunicationChat4Component } from 'src/app/icons/components/communication-chat4.component';
import { CommunicationChat5Component } from 'src/app/icons/components/communication-chat5.component';
import { CommunicationChat6Component } from 'src/app/icons/components/communication-chat6.component';
import { CommunicationClipboardCheckComponent } from 'src/app/icons/components/communication-clipboard-check.component';
import { CommunicationClipboardListComponent } from 'src/app/icons/components/communication-clipboard-list.component';
import { CommunicationContact1Component } from 'src/app/icons/components/communication-contact1.component';
import { CommunicationDeleteUserComponent } from 'src/app/icons/components/communication-delete-user.component';
import { CommunicationDialNumbersComponent } from 'src/app/icons/components/communication-dial-numbers.component';
import { CommunicationFlagComponent } from 'src/app/icons/components/communication-flag.component';
import { CommunicationForwardComponent } from 'src/app/icons/components/communication-forward.component';
import { CommunicationGroupChatComponent } from 'src/app/icons/components/communication-group-chat.component';
import { CommunicationGroupComponent } from 'src/app/icons/components/communication-group.component';
import { CommunicationIncomingBoxComponent } from 'src/app/icons/components/communication-incoming-box.component';
import { CommunicationIncomingCallComponent } from 'src/app/icons/components/communication-incoming-call.component';
import { CommunicationIncomingMailComponent } from 'src/app/icons/components/communication-incoming-mail.component';
import { CommunicationMailAtComponent } from 'src/app/icons/components/communication-mail-at.component';
import { CommunicationMailAttachmentComponent } from 'src/app/icons/components/communication-mail-attachment.component';
import { CommunicationMailBoxComponent } from 'src/app/icons/components/communication-mail-box.component';
import { CommunicationMailErrorComponent } from 'src/app/icons/components/communication-mail-error.component';
import { CommunicationMailHeartComponent } from 'src/app/icons/components/communication-mail-heart.component';
import { CommunicationMailLockedComponent } from 'src/app/icons/components/communication-mail-locked.component';
import { CommunicationMailNotificationComponent } from 'src/app/icons/components/communication-mail-notification.component';
import { CommunicationMailOpenedComponent } from 'src/app/icons/components/communication-mail-opened.component';
import { CommunicationMailUnockedComponent } from 'src/app/icons/components/communication-mail-unocked.component';
import { CommunicationMailComponent } from 'src/app/icons/components/communication-mail.component';
import { CommunicationMissedCallComponent } from 'src/app/icons/components/communication-missed-call.component';
import { CommunicationOutgoingBoxComponent } from 'src/app/icons/components/communication-outgoing-box.component';
import { CommunicationOutgoingCallComponent } from 'src/app/icons/components/communication-outgoing-call.component';
import { CommunicationOutgoingMailComponent } from 'src/app/icons/components/communication-outgoing-mail.component';
import { CommunicationReadedMailComponent } from 'src/app/icons/components/communication-readed-mail.component';
import { CommunicationReplyAllComponent } from 'src/app/icons/components/communication-reply-all.component';
import { CommunicationReplyComponent } from 'src/app/icons/components/communication-reply.component';
import { CommunicationRightComponent } from 'src/app/icons/components/communication-right.component';
import { CommunicationRSSComponent } from 'src/app/icons/components/communication-rss.component';
import { CommunicationSafeChatComponent } from 'src/app/icons/components/communication-safe-chat.component';
import { CommunicationSendComponent } from 'src/app/icons/components/communication-send.component';
import { CommunicationSendingMailComponent } from 'src/app/icons/components/communication-sending-mail.component';
import { CommunicationSendingComponent } from 'src/app/icons/components/communication-sending.component';
import { CommunicationShareComponent } from 'src/app/icons/components/communication-share.component';
import { CommunicationShieldThunderComponent } from 'src/app/icons/components/communication-shield-thunder.component';
import { CommunicationShieldUserComponent } from 'src/app/icons/components/communication-shield-user.component';
import { CommunicationSnoozedMailComponent } from 'src/app/icons/components/communication-snoozed-mail.component';
import { CommunicationSpamComponent } from 'src/app/icons/components/communication-spam.component';
import { CommunicationThumbtackComponent } from 'src/app/icons/components/communication-thumbtack.component';
import { CommunicationUrgentMailComponent } from 'src/app/icons/components/communication-urgent-mail.component';
import { CommunicationWriteComponent } from 'src/app/icons/components/communication-write.component';
import { DesignAdjustComponent } from 'src/app/icons/components/design-adjust.component';
import { DesignAnchorCenterDownComponent } from 'src/app/icons/components/design-anchor-center-down.component';
import { DesignAnchorCenterUpComponent } from 'src/app/icons/components/design-anchor-center-up.component';
import { DesignAnchorCenterComponent } from 'src/app/icons/components/design-anchor-center.component';
import { DesignAnchorLeftDownComponent } from 'src/app/icons/components/design-anchor-left-down.component';
import { DesignAnchorLeftUpComponent } from 'src/app/icons/components/design-anchor-left-up.component';
import { DesignAnchorLeftComponent } from 'src/app/icons/components/design-anchor-left.component';
import { DesignAnchorRightDownComponent } from 'src/app/icons/components/design-anchor-right-down.component';
import { DesignAnchorRightUpComponent } from 'src/app/icons/components/design-anchor-right-up.component';
import { DesignAnchorRightComponent } from 'src/app/icons/components/design-anchor-right.component';
import { DesignArrowsComponent } from 'src/app/icons/components/design-arrows.component';
import { DesignBezierCurveComponent } from 'src/app/icons/components/design-bezier-curve.component';
import { DesignBorderComponent } from 'src/app/icons/components/design-border.component';
import { DesignBrushComponent } from 'src/app/icons/components/design-brush.component';
import { DesignBucketComponent } from 'src/app/icons/components/design-bucket.component';
import { DesignCap1Component } from 'src/app/icons/components/design-cap-1.component';
import { DesignCap2Component } from 'src/app/icons/components/design-cap-2.component';
import { DesignCap3Component } from 'src/app/icons/components/design-cap-3.component';
import { DesignCircleComponent } from 'src/app/icons/components/design-circle.component';
import { DesignColorProfileComponent } from 'src/app/icons/components/design-color-profile.component';
import { DesignColorComponent } from 'src/app/icons/components/design-color.component';
import { DesignComponentComponent } from 'src/app/icons/components/design-component.component';
import { DesignCropComponent } from 'src/app/icons/components/design-crop.component';
import { DesignDifferenceComponent } from 'src/app/icons/components/design-difference.component';
import { DesignEditComponent } from 'src/app/icons/components/design-edit.component';
import { DesignEraserComponent } from 'src/app/icons/components/design-eraser.component';
import { DesignFlattenComponent } from 'src/app/icons/components/design-flatten.component';
import { DesignFlipHorizontalComponent } from 'src/app/icons/components/design-flip-horizontal.component';
import { DesignFlipVerticalComponent } from 'src/app/icons/components/design-flip-vertical.component';
import { DesignHorizontalComponent } from 'src/app/icons/components/design-horizontal.component';
import { DesignImageComponent } from 'src/app/icons/components/design-image.component';
import { DesignInterselectComponent } from 'src/app/icons/components/design-interselect.component';
import { DesignJoin1Component } from 'src/app/icons/components/design-join-1.component';
import { DesignJoin2Component } from 'src/app/icons/components/design-join-2.component';
import { DesignJoin3Component } from 'src/app/icons/components/design-join-3.component';
import { DesignLayersComponent } from 'src/app/icons/components/design-layers.component';
import { DesignLineComponent } from 'src/app/icons/components/design-line.component';
import { DesignMagicComponent } from 'src/app/icons/components/design-magic.component';
import { DesignMaskComponent } from 'src/app/icons/components/design-mask.component';
import { DesignPatchComponent } from 'src/app/icons/components/design-patch.component';
import { DesignPenToolVectorComponent } from 'src/app/icons/components/design-pen-tool-vector.component';
import { DesignPenAndRullerComponent } from 'src/app/icons/components/design-penandruller.component';
import { DesignPencilComponent } from 'src/app/icons/components/design-pencil.component';
import { DesignPickerComponent } from 'src/app/icons/components/design-picker.component';
import { DesignPixelsComponent } from 'src/app/icons/components/design-pixels.component';
import { DesignPolygonComponent } from 'src/app/icons/components/design-polygon.component';
import { DesignPositionComponent } from 'src/app/icons/components/design-position.component';
import { DesignRectangleComponent } from 'src/app/icons/components/design-rectangle.component';
import { DesignSaturationComponent } from 'src/app/icons/components/design-saturation.component';
import { DesignSelectComponent } from 'src/app/icons/components/design-select.component';
import { DesignSketchComponent } from 'src/app/icons/components/design-sketch.component';
import { DesignStampComponent } from 'src/app/icons/components/design-stamp.component';
import { DesignSubstractComponent } from 'src/app/icons/components/design-substract.component';
import { DesignTargetComponent } from 'src/app/icons/components/design-target.component';
import { DesignTriangleComponent } from 'src/app/icons/components/design-triangle.component';
import { DesignUnionComponent } from 'src/app/icons/components/design-union.component';
import { DesignVerticalComponent } from 'src/app/icons/components/design-vertical.component';
import { DesignZoomMinusComponent } from 'src/app/icons/components/design-zoomminus.component';
import { DesignZoomPlusComponent } from 'src/app/icons/components/design-zoomplus.component';
import { DevicesCPU1Component } from 'src/app/icons/components/devices-cpu1.component';
import { DocumentsCssComponent } from 'src/app/icons/components/documents-css.component';
import { DocumentsCsvComponent } from 'src/app/icons/components/documents-csv.component';
import { DocumentsDocComponent } from 'src/app/icons/components/documents-doc.component';
import { DocumentsHtmlComponent } from 'src/app/icons/components/documents-html.component';
import { DocumentsJavascriptComponent } from 'src/app/icons/components/documents-javascript.component';
import { DocumentsJpgComponent } from 'src/app/icons/components/documents-jpg.component';
import { DocumentsMp4Component } from 'src/app/icons/components/documents-mp4.component';
import { DocumentsPdfComponent } from 'src/app/icons/components/documents-pdf.component';
import { DocumentsXmlComponent } from 'src/app/icons/components/documents-xml.component';
import { DocumentsZipComponent } from 'src/app/icons/components/documents-zip.component';
import { FilesCloudDownloadComponent } from 'src/app/icons/components/files-cloud-download.component';
import { FilesCloudUploadComponent } from 'src/app/icons/components/files-cloud-upload.component';
import { FilesCompilationComponent } from 'src/app/icons/components/files-compilation.component';
import { FilesCompiledFileComponent } from 'src/app/icons/components/files-compiled-file.component';
import { FilesDeletedFileComponent } from 'src/app/icons/components/files-deleted-file.component';
import { FilesDeletedFolderComponent } from 'src/app/icons/components/files-deleted-folder.component';
import { FilesDownloadComponent } from 'src/app/icons/components/files-download.component';
import { FilesDownloadedFileComponent } from 'src/app/icons/components/files-downloadedfile.component';
import { FilesDownloadsFolderComponent } from 'src/app/icons/components/files-downloads-folder.component';
import { FilesExportComponent } from 'src/app/icons/components/files-export.component';
import { FilesFileCloudComponent } from 'src/app/icons/components/files-file-cloud.component';
import { FilesFileDoneComponent } from 'src/app/icons/components/files-file-done.component';
import { FilesFileMinusComponent } from 'src/app/icons/components/files-file-minus.component';
import { FilesFilePlusComponent } from 'src/app/icons/components/files-file-plus.component';
import { FilesFileComponent } from 'src/app/icons/components/files-file.component';
import { FilesFolderCheckComponent } from 'src/app/icons/components/files-folder-check.component';
import { FilesFolderCloudComponent } from 'src/app/icons/components/files-folder-cloud.component';
import { FilesFolderErrorComponent } from 'src/app/icons/components/files-folder-error.component';
import { FilesFolderHeartComponent } from 'src/app/icons/components/files-folder-heart.component';
import { FilesFolderMinusComponent } from 'src/app/icons/components/files-folder-minus.component';
import { FilesFolderPlusComponent } from 'src/app/icons/components/files-folder-plus.component';
import { FilesFolderSolidComponent } from 'src/app/icons/components/files-folder-solid.component';
import { FilesFolderStarComponent } from 'src/app/icons/components/files-folder-star.component';
import { FilesFolderThunderComponent } from 'src/app/icons/components/files-folder-thunder.component';
import { FilesFolderComponent } from 'src/app/icons/components/files-folder.component';
import { FilesGroupFoldersComponent } from 'src/app/icons/components/files-group-folders.component';
import { FilesImportComponent } from 'src/app/icons/components/files-import.component';
import { FilesLockedFolderComponent } from 'src/app/icons/components/files-locked-folder.component';
import { FilesMediaFolderComponent } from 'src/app/icons/components/files-media-folder.component';
import { FilesMediaComponent } from 'src/app/icons/components/files-media.component';
import { FilesMusicComponent } from 'src/app/icons/components/files-music.component';
import { FilesPictures1Component } from 'src/app/icons/components/files-pictures1.component';
import { FilesPictures2Component } from 'src/app/icons/components/files-pictures2.component';
import { FilesProtectedFileComponent } from 'src/app/icons/components/files-protected-file.component';
import { FilesSelectedFileComponent } from 'src/app/icons/components/files-selected-file.component';
import { FilesShareComponent } from 'src/app/icons/components/files-share.component';
import { FilesUploadFolderComponent } from 'src/app/icons/components/files-upload-folder.component';
import { FilesUploadComponent } from 'src/app/icons/components/files-upload.component';
import { FilesUploadedFileComponent } from 'src/app/icons/components/files-uploaded-file.component';
import { FilesUserFolderComponent } from 'src/app/icons/components/files-user-folder.component';
import { GeneralAttachment1Component } from 'src/app/icons/components/general-attachment1.component';
import { GeneralAttachment2Component } from 'src/app/icons/components/general-attachment2.component';
import { GeneralBinocularComponent } from 'src/app/icons/components/general-binocular.component';
import { GeneralBookmarkComponent } from 'src/app/icons/components/general-bookmark.component';
import { GeneralCalendarComponent } from 'src/app/icons/components/general-calendar.component';
import { GeneralClipComponent } from 'src/app/icons/components/general-clip.component';
import { GeneralClipboardComponent } from 'src/app/icons/components/general-clipboard.component';
import { GeneralCursorComponent } from 'src/app/icons/components/general-cursor.component';
import { GeneralDislikeComponent } from 'src/app/icons/components/general-dislike.component';
import { GeneralDuplicateComponent } from 'src/app/icons/components/general-duplicate.component';
import { GeneralEditComponent } from 'src/app/icons/components/general-edit.component';
import { GeneralExpandArrowsComponent } from 'src/app/icons/components/general-expand-arrows.component';
import { GeneralFireComponent } from 'src/app/icons/components/general-fire.component';
import { GeneralFolderComponent } from 'src/app/icons/components/general-folder.component';
import { GeneralHalfHeartComponent } from 'src/app/icons/components/general-half-heart.component';
import { GeneralHalfStarComponent } from 'src/app/icons/components/general-half-star.component';
import { GeneralHandshakeComponent } from 'src/app/icons/components/general-handshake.component';
import { GeneralHeartComponent } from 'src/app/icons/components/general-heart.component';
import { GeneralHiddenComponent } from 'src/app/icons/components/general-hidden.component';
import { GeneralLikeComponent } from 'src/app/icons/components/general-like.component';
import { GeneralLockComponent } from 'src/app/icons/components/general-lock.component';
import { GeneralNotification2Component } from 'src/app/icons/components/general-notification2.component';
import { GeneralNotifications1Component } from 'src/app/icons/components/general-notifications1.component';
import { GeneralOther1Component } from 'src/app/icons/components/general-other1.component';
import { GeneralOther2Component } from 'src/app/icons/components/general-other2.component';
import { GeneralPaperComponent } from 'src/app/icons/components/general-paper.component';
import { GeneralPrintComponent } from 'src/app/icons/components/general-print.component';
import { GeneralSadComponent } from 'src/app/icons/components/general-sad.component';
import { GeneralSaveComponent } from 'src/app/icons/components/general-save.component';
import { GeneralScaleComponent } from 'src/app/icons/components/general-scale.component';
import { GeneralScissorsComponent } from 'src/app/icons/components/general-scissors.component';
import { GeneralSearchComponent } from 'src/app/icons/components/general-search.component';
import { GeneralSettings1Component } from 'src/app/icons/components/general-settings-1.component';
import { GeneralSettings2Component } from 'src/app/icons/components/general-settings-2.component';
import { GeneralSettings3Component } from 'src/app/icons/components/general-settings3.component';
import { GeneralShieldCheckComponent } from 'src/app/icons/components/general-shield-check.component';
import { GeneralShieldDisabledComponent } from 'src/app/icons/components/general-shield-disabled.component';
import { GeneralShieldProtectedComponent } from 'src/app/icons/components/general-shield-protected.component';
import { GeneralSizeComponent } from 'src/app/icons/components/general-size.component';
import { GeneralSmileComponent } from 'src/app/icons/components/general-smile.component';
import { GeneralStarComponent } from 'src/app/icons/components/general-star.component';
import { GeneralThunderMoveComponent } from 'src/app/icons/components/general-thunder-move.component';
import { GeneralThunderComponent } from 'src/app/icons/components/general-thunder.component';
import { GeneralTrashComponent } from 'src/app/icons/components/general-trash.component';
import { GeneralUnlockComponent } from 'src/app/icons/components/general-unlock.component';
import { GeneralUpdateComponent } from 'src/app/icons/components/general-update.component';
import { GeneralUserComponent } from 'src/app/icons/components/general-user.component';
import { GeneralVisibleComponent } from 'src/app/icons/components/general-visible.component';
import { HomeBoxComponent } from 'src/app/icons/components/home-box.component';
import { HomeBuildingComponent } from 'src/app/icons/components/home-building.component';
import { HomeLibraryComponent } from 'src/app/icons/components/home-library.component';
import { LayoutLayout3dComponent } from 'src/app/icons/components/layout-layout-3d.component';
import { LayoutLayout4BlocksComponent } from 'src/app/icons/components/layout-layout-4-blocks.component';
import { LayoutLayoutArrangeComponent } from 'src/app/icons/components/layout-layout-arrange.component';
import { LayoutLayoutGridComponent } from 'src/app/icons/components/layout-layout-grid.component';
import { LayoutLayoutHorizontalComponent } from 'src/app/icons/components/layout-layout-horizontal.component';
import { LayoutLayoutLeftPanel1Component } from 'src/app/icons/components/layout-layout-left-panel-1.component';
import { LayoutLayoutLeftPanel2Component } from 'src/app/icons/components/layout-layout-left-panel-2.component';
import { LayoutLayoutPolygonComponent } from 'src/app/icons/components/layout-layout-polygon.component';
import { LayoutLayoutRightPanel1Component } from 'src/app/icons/components/layout-layout-right-panel-1.component';
import { LayoutLayoutRightPanel2Component } from 'src/app/icons/components/layout-layout-right-panel-2.component';
import { LayoutLayoutTopPanel1Component } from 'src/app/icons/components/layout-layout-top-panel-1.component';
import { LayoutLayoutTopPanel2Component } from 'src/app/icons/components/layout-layout-top-panel-2.component';
import { LayoutLayoutTopPanel3Component } from 'src/app/icons/components/layout-layout-top-panel-3.component';
import { LayoutLayoutTopPanel4Component } from 'src/app/icons/components/layout-layout-top-panel-4.component';
import { LayoutLayoutTopPanel5Component } from 'src/app/icons/components/layout-layout-top-panel-5.component';
import { LayoutLayoutTopPanel6Component } from 'src/app/icons/components/layout-layout-top-panel-6.component';
import { LayoutLayoutVerticalComponent } from 'src/app/icons/components/layout-layout-vertical.component';
import { MapCompassComponent } from 'src/app/icons/components/map-compass.component';
import { MapDirection1Component } from 'src/app/icons/components/map-direction1.component';
import { MapDirection2Component } from 'src/app/icons/components/map-direction2.component';
import { MapLocationArrowComponent } from 'src/app/icons/components/map-location-arrow.component';
import { MapMarker1Component } from 'src/app/icons/components/map-marker1.component';
import { MapMarker2Component } from 'src/app/icons/components/map-marker2.component';
import { MapPositionComponent } from 'src/app/icons/components/map-position.component';
import { MathFunctionComponent } from 'src/app/icons/components/math-function.component';
import { MediaPlayComponent } from 'src/app/icons/components/media-play.component';
import { MountainComponent } from 'src/app/icons/components/mountain.component';
import { NavigationAngleDoubleDownComponent } from 'src/app/icons/components/navigation-angle-double-down.component';
import { NavigationAngleDoubleLeftComponent } from 'src/app/icons/components/navigation-angle-double-left.component';
import { NavigationAngleDoubleRightComponent } from 'src/app/icons/components/navigation-angle-double-right.component';
import { NavigationAngleDoubleUpComponent } from 'src/app/icons/components/navigation-angle-double-up.component';
import { NavigationAngleDownComponent } from 'src/app/icons/components/navigation-angle-down.component';
import { NavigationAngleLeftComponent } from 'src/app/icons/components/navigation-angle-left.component';
import { NavigationAngleRightComponent } from 'src/app/icons/components/navigation-angle-right.component';
import { NavigationArrowDownComponent } from 'src/app/icons/components/navigation-arrow-down.component';
import { NavigationArrowFromBottomComponent } from 'src/app/icons/components/navigation-arrow-from-bottom.component';
import { NavigationArrowFromLeftComponent } from 'src/app/icons/components/navigation-arrow-from-left.component';
import { NavigationArrowFromRightComponent } from 'src/app/icons/components/navigation-arrow-from-right.component';
import { NavigationArrowFromTopComponent } from 'src/app/icons/components/navigation-arrow-from-top.component';
import { NavigationArrowLeftComponent } from 'src/app/icons/components/navigation-arrow-left.component';
import { NavigationArrowRightComponent } from 'src/app/icons/components/navigation-arrow-right.component';
import { NavigationArrowToBottomComponent } from 'src/app/icons/components/navigation-arrow-to-bottom.component';
import { NavigationArrowToLeftComponent } from 'src/app/icons/components/navigation-arrow-to-left.component';
import { NavigationArrowToRightComponent } from 'src/app/icons/components/navigation-arrow-to-right.component';
import { NavigationArrowToUpComponent } from 'src/app/icons/components/navigation-arrow-to-up.component';
import { NavigationArrowUpComponent } from 'src/app/icons/components/navigation-arrow-up.component';
import { NavigationArrowsHComponent } from 'src/app/icons/components/navigation-arrows-h.component';
import { NavigationArrowsVComponent } from 'src/app/icons/components/navigation-arrows-v.component';
import { NavigationCheckComponent } from 'src/app/icons/components/navigation-check.component';
import { NavigationCloseComponent } from 'src/app/icons/components/navigation-close.component';
import { NavigationDoubleCheckComponent } from 'src/app/icons/components/navigation-double-check.component';
import { NavigationDown2Component } from 'src/app/icons/components/navigation-down-2.component';
import { NavigationDownLeftComponent } from 'src/app/icons/components/navigation-down-left.component';
import { NavigationDownRightComponent } from 'src/app/icons/components/navigation-down-right.component';
import { NavigationExchangeComponent } from 'src/app/icons/components/navigation-exchange.component';
import { NavigationLeft2Component } from 'src/app/icons/components/navigation-left-2.component';
import { NavigationLeft3Component } from 'src/app/icons/components/navigation-left-3.component';
import { NavigationMinusComponent } from 'src/app/icons/components/navigation-minus.component';
import { NavigationPlusComponent } from 'src/app/icons/components/navigation-plus.component';
import { NavigationRight2Component } from 'src/app/icons/components/navigation-right-2.component';
import { NavigationRight3Component } from 'src/app/icons/components/navigation-right-3.component';
import { NavigationRouteComponent } from 'src/app/icons/components/navigation-route.component';
import { NavigationSignInComponent } from 'src/app/icons/components/navigation-sign-in.component';
import { NavigationSignOutComponent } from 'src/app/icons/components/navigation-sign-out.component';
import { NavigationUp2Component } from 'src/app/icons/components/navigation-up-2.component';
import { NavigationUpDownComponent } from 'src/app/icons/components/navigation-up-down.component';
import { NavigationUpLeftComponent } from 'src/app/icons/components/navigation-up-left.component';
import { NavigationUpRightComponent } from 'src/app/icons/components/navigation-up-right.component';
import { NavigationWaitingComponent } from 'src/app/icons/components/navigation-waiting.component';
import { RectangleComponent } from 'src/app/icons/components/rectangle.component';
import { ShoppingATMComponent } from 'src/app/icons/components/shopping-atm.component';
import { ShoppingBag1Component } from 'src/app/icons/components/shopping-bag1.component';
import { ShoppingBag2Component } from 'src/app/icons/components/shopping-bag2.component';
import { ShoppingBarcodeReadComponent } from 'src/app/icons/components/shopping-barcode-read.component';
import { ShoppingBarcodeScanComponent } from 'src/app/icons/components/shopping-barcode-scan.component';
import { ShoppingBarcodeComponent } from 'src/app/icons/components/shopping-barcode.component';
import { ShoppingBitcoinComponent } from 'src/app/icons/components/shopping-bitcoin.component';
import { ShoppingBox1Component } from 'src/app/icons/components/shopping-box1.component';
import { ShoppingBox2Component } from 'src/app/icons/components/shopping-box2.component';
import { ShoppingBox3Component } from 'src/app/icons/components/shopping-box3.component';
import { ShoppingCalculatorComponent } from 'src/app/icons/components/shopping-calculator.component';
import { ShoppingCart1Component } from 'src/app/icons/components/shopping-cart1.component';
import { ShoppingCart2Component } from 'src/app/icons/components/shopping-cart2.component';
import { ShoppingCart3Component } from 'src/app/icons/components/shopping-cart3.component';
import { ShoppingChartBar1Component } from 'src/app/icons/components/shopping-chart-bar1.component';
import { ShoppingChartBar2Component } from 'src/app/icons/components/shopping-chart-bar2.component';
import { ShoppingChartBar3Component } from 'src/app/icons/components/shopping-chart-bar3.component';
import { ShoppingChartLine1Component } from 'src/app/icons/components/shopping-chart-line1.component';
import { ShoppingChartLine2Component } from 'src/app/icons/components/shopping-chart-line2.component';
import { ShoppingChartPieComponent } from 'src/app/icons/components/shopping-chart-pie.component';
import { ShoppingCreditCardComponent } from 'src/app/icons/components/shopping-credit-card.component';
import { ShoppingDollarComponent } from 'src/app/icons/components/shopping-dollar.component';
import { ShoppingEuroComponent } from 'src/app/icons/components/shopping-euro.component';
import { ShoppingGiftComponent } from 'src/app/icons/components/shopping-gift.component';
import { ShoppingLoaderComponent } from 'src/app/icons/components/shopping-loader.component';
import { ShoppingMCComponent } from 'src/app/icons/components/shopping-mc.component';
import { ShoppingMoneyComponent } from 'src/app/icons/components/shopping-money.component';
import { ShoppingPoundComponent } from 'src/app/icons/components/shopping-pound.component';
import { ShoppingPrice1Component } from 'src/app/icons/components/shopping-price1.component';
import { ShoppingPrice2Component } from 'src/app/icons/components/shopping-price2.component';
import { ShoppingRoubleComponent } from 'src/app/icons/components/shopping-rouble.component';
import { ShoppingSafeComponent } from 'src/app/icons/components/shopping-safe.component';
import { ShoppingSale1Component } from 'src/app/icons/components/shopping-sale1.component';
import { ShoppingSale2Component } from 'src/app/icons/components/shopping-sale2.component';
import { ShoppingSettingsComponent } from 'src/app/icons/components/shopping-settings.component';
import { ShoppingSort1Component } from 'src/app/icons/components/shopping-sort1.component';
import { ShoppingSort2Component } from 'src/app/icons/components/shopping-sort2.component';
import { ShoppingSort3Component } from 'src/app/icons/components/shopping-sort3.component';
import { ShoppingTicketComponent } from 'src/app/icons/components/shopping-ticket.component';
import { ShoppingWalletComponent } from 'src/app/icons/components/shopping-wallet.component';
import { ShoppingWallet2Component } from 'src/app/icons/components/shopping-wallet2.component';
import { ShoppingWallet3Component } from 'src/app/icons/components/shopping-wallet3.component';
import { TextAlignAutoComponent } from 'src/app/icons/components/text-align-auto.component';
import { TextAlignCenterComponent } from 'src/app/icons/components/text-align-center.component';
import { TextAlignJustifyComponent } from 'src/app/icons/components/text-align-justify.component';
import { TextAlignLeftComponent } from 'src/app/icons/components/text-align-left.component';
import { TextAlignRightComponent } from 'src/app/icons/components/text-align-right.component';
import { TextArticleComponent } from 'src/app/icons/components/text-article.component';
import { TextBoldComponent } from 'src/app/icons/components/text-bold.component';
import { TextBulletListComponent } from 'src/app/icons/components/text-bullet-list.component';
import { TextCodeComponent } from 'src/app/icons/components/text-code.component';
import { TextDotsComponent } from 'src/app/icons/components/text-dots.component';
import { TextEditTextComponent } from 'src/app/icons/components/text-edit-text.component';
import { TextFilterComponent } from 'src/app/icons/components/text-filter.component';
import { TextFontComponent } from 'src/app/icons/components/text-font.component';
import { TextH1Component } from 'src/app/icons/components/text-h1.component';
import { TextH2Component } from 'src/app/icons/components/text-h2.component';
import { TextItallicComponent } from 'src/app/icons/components/text-itallic.component';
import { TextMenuComponent } from 'src/app/icons/components/text-menu.component';
import { TextParagraphComponent } from 'src/app/icons/components/text-paragraph.component';
import { TextQuote1Component } from 'src/app/icons/components/text-quote1.component';
import { TextQuote2Component } from 'src/app/icons/components/text-quote2.component';
import { TextRedoComponent } from 'src/app/icons/components/text-redo.component';
import { TextStrikethroughComponent } from 'src/app/icons/components/text-strikethrough.component';
import { TextTextHeightComponent } from 'src/app/icons/components/text-text-height.component';
import { TextTextWidthComponent } from 'src/app/icons/components/text-text-width.component';
import { TextTextComponent } from 'src/app/icons/components/text-text.component';
import { TextToggleLeftComponent } from 'src/app/icons/components/text-toggle-left.component';
import { TextToggleRightComponent } from 'src/app/icons/components/text-toggle-right.component';
import { TextToggleComponent } from 'src/app/icons/components/text-toggle.component';
import { TextUnderlineComponent } from 'src/app/icons/components/text-underline.component';
import { TextUndoComponent } from 'src/app/icons/components/text-undo.component';
import { ToolsAngleGrinderComponent } from 'src/app/icons/components/tools-angle-grinder.component';
import { ToolsAxeComponent } from 'src/app/icons/components/tools-axe.component';
import { ToolsBrushComponent } from 'src/app/icons/components/tools-brush.component';
import { ToolsCompassComponent } from 'src/app/icons/components/tools-compass.component';
import { ToolsHummerComponent } from 'src/app/icons/components/tools-hummer.component';
import { ToolsHummer2Component } from 'src/app/icons/components/tools-hummer2.component';
import { ToolsPantoneComponent } from 'src/app/icons/components/tools-pantone.component';
import { ToolsRoadConeComponent } from 'src/app/icons/components/tools-road-cone.component';
import { ToolsRollerComponent } from 'src/app/icons/components/tools-roller.component';
import { ToolsRouletteComponent } from 'src/app/icons/components/tools-roulette.component';
import { ToolsScrewdriverComponent } from 'src/app/icons/components/tools-screwdriver.component';
import { ToolsShovelComponent } from 'src/app/icons/components/tools-shovel.component';
import { ToolsSpatulaComponent } from 'src/app/icons/components/tools-spatula.component';
import { ToolsSwissKnifeComponent } from 'src/app/icons/components/tools-swiss-knife.component';
import { ToolsToolsComponent } from 'src/app/icons/components/tools-tools.component';
import { IconBase } from 'src/app/icons/icon.base';


export const iconMap: { [key: string]: typeof IconBase } = {
  'calendar': CalendarComponent,
  'clock': ClockComponent,
  'code-backspace': CodeBackspaceComponent,
  'code-cmd': CodeCMDComponent,
  'code-code': CodeCodeComponent,
  'code-commit': CodeCommitComponent,
  'code-compiling': CodeCompilingComponent,
  'code-control': CodeControlComponent,
  'code-done-circle': CodeDoneCircleComponent,
  'code-error-circle': CodeErrorCircleComponent,
  'code-git1': CodeGit1Component,
  'code-git2': CodeGit2Component,
  'code-git3': CodeGit3Component,
  'code-git4': CodeGit4Component,
  'code-github': CodeGithubComponent,
  'code-info-circle': CodeInfoCircleComponent,
  'code-left-circle': CodeLeftCircleComponent,
  'code-loading': CodeLoadingComponent,
  'code-lock-circle': CodeLockCircleComponent,
  'code-lock-overturning': CodeLockOverturningComponent,
  'code-minus': CodeMinusComponent,
  'code-option': CodeOptionComponent,
  'code-plus': CodePlusComponent,
  'code-puzzle': CodePuzzleComponent,
  'code-question-circle': CodeQuestionCircleComponent,
  'code-right-circle': CodeRightCircleComponent,
  'code-settings4': CodeSettings4Component,
  'code-shift': CodeShiftComponent,
  'code-spy': CodeSpyComponent,
  'code-stop': CodeStopComponent,
  'code-terminal': CodeTerminalComponent,
  'code-thunder-circle': CodeThunderCircleComponent,
  'code-time-schedule': CodeTimeScheduleComponent,
  'code-warning-1-circle': CodeWarning1CircleComponent,
  'code-warning-2': CodeWarning2Component,
  'communication-active-call': CommunicationActiveCallComponent,
  'communication-add-user': CommunicationAddUserComponent,
  'communication-address-card': CommunicationAddressCardComponent,
  'communication-adress-book1': CommunicationAdressBook1Component,
  'communication-adress-book2': CommunicationAdressBook2Component,
  'communication-archive': CommunicationArchiveComponent,
  'communication-call1': CommunicationCall1Component,
  'communication-call': CommunicationCallComponent,
  'communication-chat-check': CommunicationChatCheckComponent,
  'communication-chat-error': CommunicationChatErrorComponent,
  'communication-chat-locked': CommunicationChatLockedComponent,
  'communication-chat-smile': CommunicationChatSmileComponent,
  'communication-chat1': CommunicationChat1Component,
  'communication-chat2': CommunicationChat2Component,
  'communication-chat4': CommunicationChat4Component,
  'communication-chat5': CommunicationChat5Component,
  'communication-chat6': CommunicationChat6Component,
  'communication-clipboard-check': CommunicationClipboardCheckComponent,
  'communication-clipboard-list': CommunicationClipboardListComponent,
  'communication-contact1': CommunicationContact1Component,
  'communication-delete-user': CommunicationDeleteUserComponent,
  'communication-dial-numbers': CommunicationDialNumbersComponent,
  'communication-flag': CommunicationFlagComponent,
  'communication-forward': CommunicationForwardComponent,
  'communication-group-chat': CommunicationGroupChatComponent,
  'communication-group': CommunicationGroupComponent,
  'communication-incoming-box': CommunicationIncomingBoxComponent,
  'communication-incoming-call': CommunicationIncomingCallComponent,
  'communication-incoming-mail': CommunicationIncomingMailComponent,
  'communication-mail-at': CommunicationMailAtComponent,
  'communication-mail-attachment': CommunicationMailAttachmentComponent,
  'communication-mail-box': CommunicationMailBoxComponent,
  'communication-mail-error': CommunicationMailErrorComponent,
  'communication-mail-heart': CommunicationMailHeartComponent,
  'communication-mail-locked': CommunicationMailLockedComponent,
  'communication-mail-notification': CommunicationMailNotificationComponent,
  'communication-mail-opened': CommunicationMailOpenedComponent,
  'communication-mail-unocked': CommunicationMailUnockedComponent,
  'communication-mail': CommunicationMailComponent,
  'communication-missed-call': CommunicationMissedCallComponent,
  'communication-outgoing-box': CommunicationOutgoingBoxComponent,
  'communication-outgoing-call': CommunicationOutgoingCallComponent,
  'communication-outgoing-mail': CommunicationOutgoingMailComponent,
  'communication-readed-mail': CommunicationReadedMailComponent,
  'communication-reply-all': CommunicationReplyAllComponent,
  'communication-reply': CommunicationReplyComponent,
  'communication-right': CommunicationRightComponent,
  'communication-rss': CommunicationRSSComponent,
  'communication-safe-chat': CommunicationSafeChatComponent,
  'communication-send': CommunicationSendComponent,
  'communication-sending-mail': CommunicationSendingMailComponent,
  'communication-sending': CommunicationSendingComponent,
  'communication-share': CommunicationShareComponent,
  'communication-shield-thunder': CommunicationShieldThunderComponent,
  'communication-shield-user': CommunicationShieldUserComponent,
  'communication-snoozed-mail': CommunicationSnoozedMailComponent,
  'communication-spam': CommunicationSpamComponent,
  'communication-thumbtack': CommunicationThumbtackComponent,
  'communication-urgent-mail': CommunicationUrgentMailComponent,
  'communication-write': CommunicationWriteComponent,
  'design-adjust': DesignAdjustComponent,
  'design-anchor-center-down': DesignAnchorCenterDownComponent,
  'design-anchor-center-up': DesignAnchorCenterUpComponent,
  'design-anchor-center': DesignAnchorCenterComponent,
  'design-anchor-left-down': DesignAnchorLeftDownComponent,
  'design-anchor-left-up': DesignAnchorLeftUpComponent,
  'design-anchor-left': DesignAnchorLeftComponent,
  'design-anchor-right-down': DesignAnchorRightDownComponent,
  'design-anchor-right-up': DesignAnchorRightUpComponent,
  'design-anchor-right': DesignAnchorRightComponent,
  'design-arrows': DesignArrowsComponent,
  'design-bezier-curve': DesignBezierCurveComponent,
  'design-border': DesignBorderComponent,
  'design-brush': DesignBrushComponent,
  'design-bucket': DesignBucketComponent,
  'design-cap-1': DesignCap1Component,
  'design-cap-2': DesignCap2Component,
  'design-cap-3': DesignCap3Component,
  'design-circle': DesignCircleComponent,
  'design-color-profile': DesignColorProfileComponent,
  'design-color': DesignColorComponent,
  'design-component': DesignComponentComponent,
  'design-crop': DesignCropComponent,
  'design-difference': DesignDifferenceComponent,
  'design-edit': DesignEditComponent,
  'design-eraser': DesignEraserComponent,
  'design-flatten': DesignFlattenComponent,
  'design-flip-horizontal': DesignFlipHorizontalComponent,
  'design-flip-vertical': DesignFlipVerticalComponent,
  'design-horizontal': DesignHorizontalComponent,
  'design-image': DesignImageComponent,
  'design-interselect': DesignInterselectComponent,
  'design-join-1': DesignJoin1Component,
  'design-join-2': DesignJoin2Component,
  'design-join-3': DesignJoin3Component,
  'design-layers': DesignLayersComponent,
  'design-line': DesignLineComponent,
  'design-magic': DesignMagicComponent,
  'design-mask': DesignMaskComponent,
  'design-patch': DesignPatchComponent,
  'design-pen-tool-vector': DesignPenToolVectorComponent,
  'design-penandruller': DesignPenAndRullerComponent,
  'design-pencil': DesignPencilComponent,
  'design-picker': DesignPickerComponent,
  'design-pixels': DesignPixelsComponent,
  'design-polygon': DesignPolygonComponent,
  'design-position': DesignPositionComponent,
  'design-rectangle': DesignRectangleComponent,
  'design-saturation': DesignSaturationComponent,
  'design-select': DesignSelectComponent,
  'design-sketch': DesignSketchComponent,
  'design-stamp': DesignStampComponent,
  'design-substract': DesignSubstractComponent,
  'design-target': DesignTargetComponent,
  'design-triangle': DesignTriangleComponent,
  'design-union': DesignUnionComponent,
  'design-vertical': DesignVerticalComponent,
  'design-zoomminus': DesignZoomMinusComponent,
  'design-zoomplus': DesignZoomPlusComponent,
  'devices-cpu1': DevicesCPU1Component,
  'documents-css': DocumentsCssComponent,
  'documents-csv': DocumentsCsvComponent,
  'documents-doc': DocumentsDocComponent,
  'documents-html': DocumentsHtmlComponent,
  'documents-javascript': DocumentsJavascriptComponent,
  'documents-jpg': DocumentsJpgComponent,
  'documents-mp4': DocumentsMp4Component,
  'documents-pdf': DocumentsPdfComponent,
  'documents-xml': DocumentsXmlComponent,
  'documents-zip': DocumentsZipComponent,
  'files-cloud-download': FilesCloudDownloadComponent,
  'files-cloud-upload': FilesCloudUploadComponent,
  'files-compilation': FilesCompilationComponent,
  'files-compiled-file': FilesCompiledFileComponent,
  'files-deleted-file': FilesDeletedFileComponent,
  'files-deleted-folder': FilesDeletedFolderComponent,
  'files-download': FilesDownloadComponent,
  'files-downloadedfile': FilesDownloadedFileComponent,
  'files-downloads-folder': FilesDownloadsFolderComponent,
  'files-export': FilesExportComponent,
  'files-file-cloud': FilesFileCloudComponent,
  'files-file-done': FilesFileDoneComponent,
  'files-file-minus': FilesFileMinusComponent,
  'files-file-plus': FilesFilePlusComponent,
  'files-file': FilesFileComponent,
  'files-folder-check': FilesFolderCheckComponent,
  'files-folder-cloud': FilesFolderCloudComponent,
  'files-folder-error': FilesFolderErrorComponent,
  'files-folder-heart': FilesFolderHeartComponent,
  'files-folder-minus': FilesFolderMinusComponent,
  'files-folder-plus': FilesFolderPlusComponent,
  'files-folder-solid': FilesFolderSolidComponent,
  'files-folder-star': FilesFolderStarComponent,
  'files-folder-thunder': FilesFolderThunderComponent,
  'files-folder': FilesFolderComponent,
  'files-group-folders': FilesGroupFoldersComponent,
  'files-import': FilesImportComponent,
  'files-locked-folder': FilesLockedFolderComponent,
  'files-media-folder': FilesMediaFolderComponent,
  'files-media': FilesMediaComponent,
  'files-music': FilesMusicComponent,
  'files-pictures1': FilesPictures1Component,
  'files-pictures2': FilesPictures2Component,
  'files-protected-file': FilesProtectedFileComponent,
  'files-selected-file': FilesSelectedFileComponent,
  'files-share': FilesShareComponent,
  'files-upload-folder': FilesUploadFolderComponent,
  'files-upload': FilesUploadComponent,
  'files-uploaded-file': FilesUploadedFileComponent,
  'files-user-folder': FilesUserFolderComponent,
  'general-attachment1': GeneralAttachment1Component,
  'general-attachment2': GeneralAttachment2Component,
  'general-binocular': GeneralBinocularComponent,
  'general-bookmark': GeneralBookmarkComponent,
  'general-calendar': GeneralCalendarComponent,
  'general-clip': GeneralClipComponent,
  'general-clipboard': GeneralClipboardComponent,
  'general-cursor': GeneralCursorComponent,
  'general-dislike': GeneralDislikeComponent,
  'general-duplicate': GeneralDuplicateComponent,
  'general-edit': GeneralEditComponent,
  'general-expand-arrows': GeneralExpandArrowsComponent,
  'general-fire': GeneralFireComponent,
  'general-folder': GeneralFolderComponent,
  'general-half-heart': GeneralHalfHeartComponent,
  'general-half-star': GeneralHalfStarComponent,
  'general-handshake': GeneralHandshakeComponent,
  'general-heart': GeneralHeartComponent,
  'general-hidden': GeneralHiddenComponent,
  'general-like': GeneralLikeComponent,
  'general-lock': GeneralLockComponent,
  'general-notification2': GeneralNotification2Component,
  'general-notifications1': GeneralNotifications1Component,
  'general-other1': GeneralOther1Component,
  'general-other2': GeneralOther2Component,
  'general-paper': GeneralPaperComponent,
  'general-print': GeneralPrintComponent,
  'general-sad': GeneralSadComponent,
  'general-save': GeneralSaveComponent,
  'general-scale': GeneralScaleComponent,
  'general-scissors': GeneralScissorsComponent,
  'general-search': GeneralSearchComponent,
  'general-settings3': GeneralSettings3Component,
  'general-settings-1': GeneralSettings1Component,
  'general-settings-2': GeneralSettings2Component,
  'general-shield-check': GeneralShieldCheckComponent,
  'general-shield-disabled': GeneralShieldDisabledComponent,
  'general-shield-protected': GeneralShieldProtectedComponent,
  'general-size': GeneralSizeComponent,
  'general-smile': GeneralSmileComponent,
  'general-star': GeneralStarComponent,
  'general-thunder-move': GeneralThunderMoveComponent,
  'general-thunder': GeneralThunderComponent,
  'general-trash': GeneralTrashComponent,
  'general-unlock': GeneralUnlockComponent,
  'general-update': GeneralUpdateComponent,
  'general-user': GeneralUserComponent,
  'general-visible': GeneralVisibleComponent,
  'home-box': HomeBoxComponent,
  'home-building': HomeBuildingComponent,
  'home-library': HomeLibraryComponent,
  'layout-layout-3d': LayoutLayout3dComponent,
  'layout-layout-4-blocks': LayoutLayout4BlocksComponent,
  'layout-layout-arrange': LayoutLayoutArrangeComponent,
  'layout-layout-grid': LayoutLayoutGridComponent,
  'layout-layout-horizontal': LayoutLayoutHorizontalComponent,
  'layout-layout-left-panel-1': LayoutLayoutLeftPanel1Component,
  'layout-layout-left-panel-2': LayoutLayoutLeftPanel2Component,
  'layout-layout-polygon': LayoutLayoutPolygonComponent,
  'layout-layout-right-panel-1': LayoutLayoutRightPanel1Component,
  'layout-layout-right-panel-2': LayoutLayoutRightPanel2Component,
  'layout-layout-top-panel-1': LayoutLayoutTopPanel1Component,
  'layout-layout-top-panel-2': LayoutLayoutTopPanel2Component,
  'layout-layout-top-panel-3': LayoutLayoutTopPanel3Component,
  'layout-layout-top-panel-4': LayoutLayoutTopPanel4Component,
  'layout-layout-top-panel-5': LayoutLayoutTopPanel5Component,
  'layout-layout-top-panel-6': LayoutLayoutTopPanel6Component,
  'layout-layout-vertical': LayoutLayoutVerticalComponent,
  'map-compass': MapCompassComponent,
  'map-direction1': MapDirection1Component,
  'map-direction2': MapDirection2Component,
  'map-location-arrow': MapLocationArrowComponent,
  'map-marker1': MapMarker1Component,
  'map-marker2': MapMarker2Component,
  'map-position': MapPositionComponent,
  'math-function': MathFunctionComponent,
  'media-play': MediaPlayComponent,
  'mountain': MountainComponent,
  'navigation-angle-double-down': NavigationAngleDoubleDownComponent,
  'navigation-angle-double-left': NavigationAngleDoubleLeftComponent,
  'navigation-angle-double-right': NavigationAngleDoubleRightComponent,
  'navigation-angle-double-up': NavigationAngleDoubleUpComponent,
  'navigation-angle-down': NavigationAngleDownComponent,
  'navigation-angle-left': NavigationAngleLeftComponent,
  'navigation-angle-right': NavigationAngleRightComponent,
  'navigation-arrow-down': NavigationArrowDownComponent,
  'navigation-arrow-from-bottom': NavigationArrowFromBottomComponent,
  'navigation-arrow-from-left': NavigationArrowFromLeftComponent,
  'navigation-arrow-from-right': NavigationArrowFromRightComponent,
  'navigation-arrow-from-top': NavigationArrowFromTopComponent,
  'navigation-arrow-left': NavigationArrowLeftComponent,
  'navigation-arrow-right': NavigationArrowRightComponent,
  'navigation-arrow-to-bottom': NavigationArrowToBottomComponent,
  'navigation-arrow-to-left': NavigationArrowToLeftComponent,
  'navigation-arrow-to-right': NavigationArrowToRightComponent,
  'navigation-arrow-to-up': NavigationArrowToUpComponent,
  'navigation-arrow-up': NavigationArrowUpComponent,
  'navigation-arrows-h': NavigationArrowsHComponent,
  'navigation-arrows-v': NavigationArrowsVComponent,
  'navigation-check': NavigationCheckComponent,
  'navigation-close': NavigationCloseComponent,
  'navigation-double-check': NavigationDoubleCheckComponent,
  'navigation-down-2': NavigationDown2Component,
  'navigation-down-left': NavigationDownLeftComponent,
  'navigation-down-right': NavigationDownRightComponent,
  'navigation-exchange': NavigationExchangeComponent,
  'navigation-left-3': NavigationLeft3Component,
  'navigation-left-2': NavigationLeft2Component,
  'navigation-minus': NavigationMinusComponent,
  'navigation-plus': NavigationPlusComponent,
  'navigation-right-3': NavigationRight3Component,
  'navigation-right-2': NavigationRight2Component,
  'navigation-route': NavigationRouteComponent,
  'navigation-sign-in': NavigationSignInComponent,
  'navigation-sign-out': NavigationSignOutComponent,
  'navigation-up-2': NavigationUp2Component,
  'navigation-up-down': NavigationUpDownComponent,
  'navigation-up-left': NavigationUpLeftComponent,
  'navigation-up-right': NavigationUpRightComponent,
  'navigation-waiting': NavigationWaitingComponent,
  'rectangle': RectangleComponent,
  'shopping-atm': ShoppingATMComponent,
  'shopping-bag1': ShoppingBag1Component,
  'shopping-bag2': ShoppingBag2Component,
  'shopping-barcode-read': ShoppingBarcodeReadComponent,
  'shopping-barcode-scan': ShoppingBarcodeScanComponent,
  'shopping-barcode': ShoppingBarcodeComponent,
  'shopping-bitcoin': ShoppingBitcoinComponent,
  'shopping-box1': ShoppingBox1Component,
  'shopping-box2': ShoppingBox2Component,
  'shopping-box3': ShoppingBox3Component,
  'shopping-calculator': ShoppingCalculatorComponent,
  'shopping-cart1': ShoppingCart1Component,
  'shopping-cart2': ShoppingCart2Component,
  'shopping-cart3': ShoppingCart3Component,
  'shopping-chart-bar1': ShoppingChartBar1Component,
  'shopping-chart-bar2': ShoppingChartBar2Component,
  'shopping-chart-bar3': ShoppingChartBar3Component,
  'shopping-chart-line1': ShoppingChartLine1Component,
  'shopping-chart-line2': ShoppingChartLine2Component,
  'shopping-chart-pie': ShoppingChartPieComponent,
  'shopping-credit-card': ShoppingCreditCardComponent,
  'shopping-dollar': ShoppingDollarComponent,
  'shopping-euro': ShoppingEuroComponent,
  'shopping-gift': ShoppingGiftComponent,
  'shopping-loader': ShoppingLoaderComponent,
  'shopping-mc': ShoppingMCComponent,
  'shopping-money': ShoppingMoneyComponent,
  'shopping-pound': ShoppingPoundComponent,
  'shopping-price1': ShoppingPrice1Component,
  'shopping-price2': ShoppingPrice2Component,
  'shopping-rouble': ShoppingRoubleComponent,
  'shopping-safe': ShoppingSafeComponent,
  'shopping-sale1': ShoppingSale1Component,
  'shopping-sale2': ShoppingSale2Component,
  'shopping-settings': ShoppingSettingsComponent,
  'shopping-sort1': ShoppingSort1Component,
  'shopping-sort2': ShoppingSort2Component,
  'shopping-sort3': ShoppingSort3Component,
  'shopping-ticket': ShoppingTicketComponent,
  'shopping-wallet': ShoppingWalletComponent,
  'shopping-wallet2': ShoppingWallet2Component,
  'shopping-wallet3': ShoppingWallet3Component,
  'text-align-auto': TextAlignAutoComponent,
  'text-align-center': TextAlignCenterComponent,
  'text-align-justify': TextAlignJustifyComponent,
  'text-align-left': TextAlignLeftComponent,
  'text-align-right': TextAlignRightComponent,
  'text-article': TextArticleComponent,
  'text-bold': TextBoldComponent,
  'text-bullet-list': TextBulletListComponent,
  'text-code': TextCodeComponent,
  'text-dots': TextDotsComponent,
  'text-edit-text': TextEditTextComponent,
  'text-filter': TextFilterComponent,
  'text-font': TextFontComponent,
  'text-h1': TextH1Component,
  'text-h2': TextH2Component,
  'text-itallic': TextItallicComponent,
  'text-menu': TextMenuComponent,
  'text-paragraph': TextParagraphComponent,
  'text-quote1': TextQuote1Component,
  'text-quote2': TextQuote2Component,
  'text-redo': TextRedoComponent,
  'text-strikethrough': TextStrikethroughComponent,
  'text-text-height': TextTextHeightComponent,
  'text-text-width': TextTextWidthComponent,
  'text-text': TextTextComponent,
  'text-toggle-left': TextToggleLeftComponent,
  'text-toggle-right': TextToggleRightComponent,
  'text-toggle': TextToggleComponent,
  'text-underline': TextUnderlineComponent,
  'text-undo': TextUndoComponent,
  'tools-angle-grinder': ToolsAngleGrinderComponent,
  'tools-axe': ToolsAxeComponent,
  'tools-brush': ToolsBrushComponent,
  'tools-compass': ToolsCompassComponent,
  'tools-hummer': ToolsHummerComponent,
  'tools-hummer2': ToolsHummer2Component,
  'tools-pantone': ToolsPantoneComponent,
  'tools-road-cone': ToolsRoadConeComponent,
  'tools-roller': ToolsRollerComponent,
  'tools-roulette': ToolsRouletteComponent,
  'tools-screwdriver': ToolsScrewdriverComponent,
  'tools-shovel': ToolsShovelComponent,
  'tools-spatula': ToolsSpatulaComponent,
  'tools-swiss-knife': ToolsSwissKnifeComponent,
  'tools-tools': ToolsToolsComponent
};

export const iconTypes = [
  CalendarComponent,
  ClockComponent,
  CodeBackspaceComponent,
  CodeCMDComponent,
  CodeCodeComponent,
  CodeCommitComponent,
  CodeCompilingComponent,
  CodeControlComponent,
  CodeDoneCircleComponent,
  CodeErrorCircleComponent,
  CodeGit1Component,
  CodeGit2Component,
  CodeGit3Component,
  CodeGit4Component,
  CodeGithubComponent,
  CodeInfoCircleComponent,
  CodeLeftCircleComponent,
  CodeLoadingComponent,
  CodeLockCircleComponent,
  CodeLockOverturningComponent,
  CodeMinusComponent,
  CodeOptionComponent,
  CodePlusComponent,
  CodePuzzleComponent,
  CodeQuestionCircleComponent,
  CodeRightCircleComponent,
  CodeSettings4Component,
  CodeShiftComponent,
  CodeSpyComponent,
  CodeStopComponent,
  CodeTerminalComponent,
  CodeThunderCircleComponent,
  CodeTimeScheduleComponent,
  CodeWarning1CircleComponent,
  CodeWarning2Component,
  CommunicationActiveCallComponent,
  CommunicationAddUserComponent,
  CommunicationAddressCardComponent,
  CommunicationAdressBook1Component,
  CommunicationAdressBook2Component,
  CommunicationArchiveComponent,
  CommunicationCall1Component,
  CommunicationCallComponent,
  CommunicationChatCheckComponent,
  CommunicationChatErrorComponent,
  CommunicationChatLockedComponent,
  CommunicationChatSmileComponent,
  CommunicationChat1Component,
  CommunicationChat2Component,
  CommunicationChat4Component,
  CommunicationChat5Component,
  CommunicationChat6Component,
  CommunicationClipboardCheckComponent,
  CommunicationClipboardListComponent,
  CommunicationContact1Component,
  CommunicationDeleteUserComponent,
  CommunicationDialNumbersComponent,
  CommunicationFlagComponent,
  CommunicationForwardComponent,
  CommunicationGroupChatComponent,
  CommunicationGroupComponent,
  CommunicationIncomingBoxComponent,
  CommunicationIncomingCallComponent,
  CommunicationIncomingMailComponent,
  CommunicationMailAtComponent,
  CommunicationMailAttachmentComponent,
  CommunicationMailBoxComponent,
  CommunicationMailErrorComponent,
  CommunicationMailHeartComponent,
  CommunicationMailLockedComponent,
  CommunicationMailNotificationComponent,
  CommunicationMailOpenedComponent,
  CommunicationMailUnockedComponent,
  CommunicationMailComponent,
  CommunicationMissedCallComponent,
  CommunicationOutgoingBoxComponent,
  CommunicationOutgoingCallComponent,
  CommunicationOutgoingMailComponent,
  CommunicationReadedMailComponent,
  CommunicationReplyAllComponent,
  CommunicationReplyComponent,
  CommunicationRightComponent,
  CommunicationRSSComponent,
  CommunicationSafeChatComponent,
  CommunicationSendComponent,
  CommunicationSendingMailComponent,
  CommunicationSendingComponent,
  CommunicationShareComponent,
  CommunicationShieldThunderComponent,
  CommunicationShieldUserComponent,
  CommunicationSnoozedMailComponent,
  CommunicationSpamComponent,
  CommunicationThumbtackComponent,
  CommunicationUrgentMailComponent,
  CommunicationWriteComponent,
  DesignAdjustComponent,
  DesignAnchorCenterDownComponent,
  DesignAnchorCenterUpComponent,
  DesignAnchorCenterComponent,
  DesignAnchorLeftDownComponent,
  DesignAnchorLeftUpComponent,
  DesignAnchorLeftComponent,
  DesignAnchorRightDownComponent,
  DesignAnchorRightUpComponent,
  DesignAnchorRightComponent,
  DesignArrowsComponent,
  DesignBezierCurveComponent,
  DesignBorderComponent,
  DesignBrushComponent,
  DesignBucketComponent,
  DesignCap1Component,
  DesignCap2Component,
  DesignCap3Component,
  DesignCircleComponent,
  DesignColorProfileComponent,
  DesignColorComponent,
  DesignComponentComponent,
  DesignCropComponent,
  DesignDifferenceComponent,
  DesignEditComponent,
  DesignEraserComponent,
  DesignFlattenComponent,
  DesignFlipHorizontalComponent,
  DesignFlipVerticalComponent,
  DesignHorizontalComponent,
  DesignImageComponent,
  DesignInterselectComponent,
  DesignJoin1Component,
  DesignJoin2Component,
  DesignJoin3Component,
  DesignLayersComponent,
  DesignLineComponent,
  DesignMagicComponent,
  DesignMaskComponent,
  DesignPatchComponent,
  DesignPenToolVectorComponent,
  DesignPenAndRullerComponent,
  DesignPencilComponent,
  DesignPickerComponent,
  DesignPixelsComponent,
  DesignPolygonComponent,
  DesignPositionComponent,
  DesignRectangleComponent,
  DesignSaturationComponent,
  DesignSelectComponent,
  DesignSketchComponent,
  DesignStampComponent,
  DesignSubstractComponent,
  DesignTargetComponent,
  DesignTriangleComponent,
  DesignUnionComponent,
  DesignVerticalComponent,
  DesignZoomMinusComponent,
  DesignZoomPlusComponent,
  DevicesCPU1Component,
  DocumentsCssComponent,
  DocumentsCsvComponent,
  DocumentsDocComponent,
  DocumentsHtmlComponent,
  DocumentsJavascriptComponent,
  DocumentsJpgComponent,
  DocumentsMp4Component,
  DocumentsPdfComponent,
  DocumentsXmlComponent,
  DocumentsZipComponent,
  FilesCloudDownloadComponent,
  FilesCloudUploadComponent,
  FilesCompilationComponent,
  FilesCompiledFileComponent,
  FilesDeletedFileComponent,
  FilesDeletedFolderComponent,
  FilesDownloadComponent,
  FilesDownloadedFileComponent,
  FilesDownloadsFolderComponent,
  FilesExportComponent,
  FilesFileCloudComponent,
  FilesFileDoneComponent,
  FilesFileMinusComponent,
  FilesFilePlusComponent,
  FilesFileComponent,
  FilesFolderCheckComponent,
  FilesFolderCloudComponent,
  FilesFolderErrorComponent,
  FilesFolderHeartComponent,
  FilesFolderMinusComponent,
  FilesFolderPlusComponent,
  FilesFolderSolidComponent,
  FilesFolderStarComponent,
  FilesFolderThunderComponent,
  FilesFolderComponent,
  FilesGroupFoldersComponent,
  FilesImportComponent,
  FilesLockedFolderComponent,
  FilesMediaFolderComponent,
  FilesMediaComponent,
  FilesMusicComponent,
  FilesPictures1Component,
  FilesPictures2Component,
  FilesProtectedFileComponent,
  FilesSelectedFileComponent,
  FilesShareComponent,
  FilesUploadFolderComponent,
  FilesUploadComponent,
  FilesUploadedFileComponent,
  FilesUserFolderComponent,
  GeneralAttachment1Component,
  GeneralAttachment2Component,
  GeneralBinocularComponent,
  GeneralBookmarkComponent,
  GeneralCalendarComponent,
  GeneralClipComponent,
  GeneralClipboardComponent,
  GeneralCursorComponent,
  GeneralDislikeComponent,
  GeneralDuplicateComponent,
  GeneralEditComponent,
  GeneralExpandArrowsComponent,
  GeneralFireComponent,
  GeneralFolderComponent,
  GeneralHalfHeartComponent,
  GeneralHalfStarComponent,
  GeneralHandshakeComponent,
  GeneralHeartComponent,
  GeneralHiddenComponent,
  GeneralLikeComponent,
  GeneralLockComponent,
  GeneralNotification2Component,
  GeneralNotifications1Component,
  GeneralOther1Component,
  GeneralOther2Component,
  GeneralPaperComponent,
  GeneralPrintComponent,
  GeneralSadComponent,
  GeneralSaveComponent,
  GeneralScaleComponent,
  GeneralScissorsComponent,
  GeneralSearchComponent,
  GeneralSettings3Component,
  GeneralSettings1Component,
  GeneralSettings2Component,
  GeneralShieldCheckComponent,
  GeneralShieldDisabledComponent,
  GeneralShieldProtectedComponent,
  GeneralSizeComponent,
  GeneralSmileComponent,
  GeneralStarComponent,
  GeneralThunderMoveComponent,
  GeneralThunderComponent,
  GeneralTrashComponent,
  GeneralUnlockComponent,
  GeneralUpdateComponent,
  GeneralUserComponent,
  GeneralVisibleComponent,
  HomeBoxComponent,
  HomeBuildingComponent,
  HomeLibraryComponent,
  LayoutLayout3dComponent,
  LayoutLayout4BlocksComponent,
  LayoutLayoutArrangeComponent,
  LayoutLayoutGridComponent,
  LayoutLayoutHorizontalComponent,
  LayoutLayoutLeftPanel1Component,
  LayoutLayoutLeftPanel2Component,
  LayoutLayoutPolygonComponent,
  LayoutLayoutRightPanel1Component,
  LayoutLayoutRightPanel2Component,
  LayoutLayoutTopPanel1Component,
  LayoutLayoutTopPanel2Component,
  LayoutLayoutTopPanel3Component,
  LayoutLayoutTopPanel4Component,
  LayoutLayoutTopPanel5Component,
  LayoutLayoutTopPanel6Component,
  LayoutLayoutVerticalComponent,
  MapCompassComponent,
  MapDirection1Component,
  MapDirection2Component,
  MapLocationArrowComponent,
  MapMarker1Component,
  MapMarker2Component,
  MapPositionComponent,
  MediaPlayComponent,
  MountainComponent,
  NavigationAngleDoubleDownComponent,
  NavigationAngleDoubleLeftComponent,
  NavigationAngleDoubleRightComponent,
  NavigationAngleDoubleUpComponent,
  NavigationAngleDownComponent,
  NavigationAngleLeftComponent,
  NavigationAngleRightComponent,
  NavigationArrowDownComponent,
  NavigationArrowFromBottomComponent,
  NavigationArrowFromLeftComponent,
  NavigationArrowFromRightComponent,
  NavigationArrowFromTopComponent,
  NavigationArrowLeftComponent,
  NavigationArrowRightComponent,
  NavigationArrowToBottomComponent,
  NavigationArrowToLeftComponent,
  NavigationArrowToRightComponent,
  NavigationArrowToUpComponent,
  NavigationArrowUpComponent,
  NavigationArrowsHComponent,
  NavigationArrowsVComponent,
  NavigationCheckComponent,
  NavigationCloseComponent,
  NavigationDoubleCheckComponent,
  NavigationDown2Component,
  NavigationDownLeftComponent,
  NavigationDownRightComponent,
  NavigationExchangeComponent,
  NavigationLeft3Component,
  NavigationLeft2Component,
  NavigationMinusComponent,
  NavigationPlusComponent,
  NavigationRight3Component,
  NavigationRight2Component,
  NavigationRouteComponent,
  NavigationSignInComponent,
  NavigationSignOutComponent,
  NavigationUp2Component,
  NavigationUpDownComponent,
  NavigationUpLeftComponent,
  NavigationUpRightComponent,
  NavigationWaitingComponent,
  RectangleComponent,
  ShoppingATMComponent,
  ShoppingBag1Component,
  ShoppingBag2Component,
  ShoppingBarcodeReadComponent,
  ShoppingBarcodeScanComponent,
  ShoppingBarcodeComponent,
  ShoppingBitcoinComponent,
  ShoppingBox1Component,
  ShoppingBox2Component,
  ShoppingBox3Component,
  ShoppingCalculatorComponent,
  ShoppingCart1Component,
  ShoppingCart2Component,
  ShoppingCart3Component,
  ShoppingChartBar1Component,
  ShoppingChartBar2Component,
  ShoppingChartBar3Component,
  ShoppingChartLine1Component,
  ShoppingChartLine2Component,
  ShoppingChartPieComponent,
  ShoppingCreditCardComponent,
  ShoppingDollarComponent,
  ShoppingEuroComponent,
  ShoppingGiftComponent,
  ShoppingLoaderComponent,
  ShoppingMCComponent,
  ShoppingMoneyComponent,
  ShoppingPoundComponent,
  ShoppingPrice1Component,
  ShoppingPrice2Component,
  ShoppingRoubleComponent,
  ShoppingSafeComponent,
  ShoppingSale1Component,
  ShoppingSale2Component,
  ShoppingSettingsComponent,
  ShoppingSort1Component,
  ShoppingSort2Component,
  ShoppingSort3Component,
  ShoppingTicketComponent,
  ShoppingWalletComponent,
  ShoppingWallet2Component,
  ShoppingWallet3Component,
  TextAlignAutoComponent,
  TextAlignCenterComponent,
  TextAlignJustifyComponent,
  TextAlignLeftComponent,
  TextAlignRightComponent,
  TextArticleComponent,
  TextBoldComponent,
  TextBulletListComponent,
  TextCodeComponent,
  TextDotsComponent,
  TextEditTextComponent,
  TextFilterComponent,
  TextFontComponent,
  TextH1Component,
  TextH2Component,
  TextItallicComponent,
  TextMenuComponent,
  TextParagraphComponent,
  TextQuote1Component,
  TextQuote2Component,
  TextRedoComponent,
  TextStrikethroughComponent,
  TextTextHeightComponent,
  TextTextWidthComponent,
  TextTextComponent,
  TextToggleLeftComponent,
  TextToggleRightComponent,
  TextToggleComponent,
  TextUnderlineComponent,
  TextUndoComponent,
  ToolsAngleGrinderComponent,
  ToolsAxeComponent,
  ToolsBrushComponent,
  ToolsCompassComponent,
  ToolsHummerComponent,
  ToolsHummer2Component,
  ToolsPantoneComponent,
  ToolsRoadConeComponent,
  ToolsRollerComponent,
  ToolsRouletteComponent,
  ToolsScrewdriverComponent,
  ToolsShovelComponent,
  ToolsSpatulaComponent,
  ToolsSwissKnifeComponent,
  ToolsToolsComponent
];

