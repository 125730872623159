import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Injectable()
export class CanActivatePermissionsGuard implements CanActivate {

  constructor(private authService: AuthenticationService,
    private router: Router) { }
  
  canActivate(route: ActivatedRouteSnapshot) {
    const activator = route.data.activator as CanActivatePermissionsGuardFunc;
    if (activator(this.authService)) {
      return true;
    }
    else {
      this.router.navigate(['/home']);
    }
    return false;
  }
}

export type CanActivatePermissionsGuardFunc = (service: AuthenticationService) => Boolean;

