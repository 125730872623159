import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-navigation-exchange-icon',
  templateUrl: './navigation-exchange.component.html'
})
export class NavigationExchangeComponent extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
