
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Design-/-Line" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M13,9 L13,6 C9.04563815,7.48814977 6.78867438,8.99350441 5,13 L8,13 C8.7428521,12.2 9.98856336,10 13,9 Z" id="Oval" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
        <circle id="Oval-7" fill="#000000" cx="18" cy="7.5" r="3"></circle>
        <circle id="Oval-7-Copy" fill="#000000" cx="6" cy="18" r="3"></circle>
    </g>
</svg>
