<fit-subheader title="Capture Fund Range Survey" [busyLoading]="busyLoading" [isFullWidth]="false">
  <div class="d-flex flex-row flex-wrap ml-auto">
    <fit-icon-button caption="Back"
                     (onClick)="goBack()"
                     icon="navigation-arrow-left"
                     class="btn-light-warning">
    </fit-icon-button>
    <fit-icon-button caption="Refresh"
                     (onClick)="load()"
                     icon="general-update"
                     [isDisabled]="!canRefresh"
                     [busyLoading]="busyLoading">
    </fit-icon-button>
  </div>
</fit-subheader>

<div class="container d-flex flex-column h-100">
  <ng-container *ngIf="!busyLoading else loading">
    <ng-container *ngTemplateOutlet="manageView"></ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <fit-grid-skeleton [columnCount]="4"></fit-grid-skeleton>
</ng-template>

<ng-template #manageView>
  <div class="card card-custom">
    <div class="card-header py-3 d-flex flex-row justify-content-between align-items-center">
      <div class="mb-0 mr-3">
        <h2>Fund Range | {{viewmodel?.fundRange}}</h2>
      </div>
      <div class="card-toolbar">
        <div class="d-flex flex-row">
          <fit-icon-button caption="Add New Fund"
                           [class]="'btn-outline-primary'"
                           busyCaption="Saving"
                           icon="code-plus"
                           (onClick)="showAddManualRazor()"
                           [busyLoading]="busyAdding">
          </fit-icon-button>
          <fit-icon-button caption="Submit"
                           (onClick)="submitSurvey()"
                           [isDisabled]="!canSubmit"
                           [busyLoading]="busySubmitting"
                           busyCaption="Submitting">
          </fit-icon-button>
        </div>
      </div>
    </div>

    <div class="card-body py-3">
      <div class="card card-custom py-3">
        <div class="card-body py-3">
          <form [formGroup]="filterForm" (keydown.enter)="submitSearch($event)">
            <div class="row justify-content-start">
              <div class="col-3">
                <div class="d-flex my-2">
                  <p class="font-weight-bold mb-0">Fund</p>
                  <ng-container *ngIf="filterForm.fund.value">
                    <span class="svg-icon svg-icon-sm svg-icon-danger cursor-pointer ml-1"
                          (click)="clearFund()"
                          title="Clear Fund Filters">
                      <fit-icon icon="code-error-circle"></fit-icon>
                    </span>
                  </ng-container>
                </div>
                <fit-text-editor [control]="filterForm?.fund"
                                 placeHolder="Fund">
                </fit-text-editor>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button class="btn btn-light-primary btn-sm mr-3"
                        (click)="runSearch()">
                  <span class="svg-icon">
                    <fit-icon icon="general-search">
                    </fit-icon>
                  </span>
                  Search
                </button>
                <button class="btn btn-sm"
                        (click)="clearSearch()">
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card-body py-3">
        <div *ngIf="viewmodel?.surveyStatus == 5" class="alert alert-custom alert-light-danger mb-5 py-3 px-9" style="flex-direction:column" role="alert">
          <div class="alert-heading"><b>Reject comment: </b></div>
          <p>{{viewmodel?.rejectComment}}</p>
        </div>
      </div>

      <div class="card scroll card-custom p-0 mb-2">
        <table class="table table-rounded table-hover p-4 mb-0">
          <thead>
            <tr>
              <th scope="col" class="py-3 col-2">
                <fit-sortheader header="Fund Code" tag="Code" [sortOrder]="sortOrders.get('Code')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
              </th>
              <th scope="col" class="py-3 col-4">
                <fit-sortheader header="Fund Name" tag="Name" [sortOrder]="sortOrders.get('Name')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
              </th>
              <th scope="col" class="py-3 col-2">
                <fit-sortheader header="Status" tag="Status" [sortOrder]="sortOrders.get('Status')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
              </th>
              <th scope="col" class="py-3 col-2">
                <fit-sortheader header="Completed" tag="HasBeenClassified" [sortOrder]="sortOrders.get('HasBeenClassified')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
              </th>
              <th scope="col" class="py-3 col-2">
                <fit-sortheader header="In Scope" tag="IsInScope" [sortOrder]="sortOrders.get('IsInScope')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
              </th>
              <th scope="col" class="is-narrow text-right pr-20">Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="!viewmodel?.funds || viewmodel?.funds.length === 0">
              <tr>
                <td colspan="7">
                  <div class="container my-5">
                    <fit-clean-slate [hasBorder]="false" message="No funds have been added to this fund range or your filters returned no results.">
                      <div class="d-flex justify-content-center">
                        <img src="assets/img/clean-slate/package-slate.svg"
                             width="120"
                             class="mb-6" />
                      </div>
                    </fit-clean-slate>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngFor="let fund of viewmodel?.funds; let i = index">
              <tr>
                <td>
                  {{fund.fund.value}}
                </td>
                <td>
                  {{fund.fund.name}}
                </td>
                <td>
                  {{fund.status}}
                </td>
                <td>
                  <span class="label label-inline "
                        [ngClass]="fund.hasBeenClassified ? 'label-success': 'label-danger'">
                    {{fund.hasBeenClassified ? 'Yes' : 'No'}}
                  </span>
                </td>
                <td>
                  <span class="label label-inline {{ fund.isInScope.value | inScopeLabelClassPipe }}">
                    {{fund.isInScope.name}}
                  </span>
                </td>
                <td>
                  <div class="d-flex flex-row" (click)="$event.stopPropagation()">
                    <fit-icon-button title="Edit Survey"
                                     icon="general-edit"
                                     (onClick)="viewClassification(fund.fund.instanceId)"
                                     class="btn-light-primary">
                    </fit-icon-button>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div class="d-flex justify-content-between flex-wrap mt-3">
        <div class="d-flex mb-2">
          <fit-paginator [totalItems]="viewmodel?.pager.recordCount"
                         [currentPage]="viewmodel?.pager.page"
                         [itemsPerPage]="viewmodel?.pager.pageSize"
                         (pageChange)="pageChange($event)">
          </fit-paginator>
        </div>
        <div class="d-flex">
          <fit-ngdropdown [showToggleIcon]="false"
                          [defaultText]="pageSizeButtonText">
            <a ngbDropDownItem
               class="dropdown-item"
               (click)="setPageSize(10)">10</a>
            <a ngbDropDownItem
               class="dropdown-item"
               (click)="setPageSize(25)">25</a>
            <a ngbDropDownItem
               class="dropdown-item"
               (click)="setPageSize(50)">50</a>
          </fit-ngdropdown>
        </div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #loading>
  <fit-grid-skeleton [columnCount]="4"></fit-grid-skeleton>
</ng-template>

<ng-template #submittedSurvey>
  <div class="card card-custom">
    <fit-clean-slate [hasBorder]="false" message="Your survey has been submitted">
      <div class="d-flex justify-content-center">
        <img src="assets/img/clean-slate/handshake-slate.svg"
             width="120"
             class="mb-5" />
      </div>
    </fit-clean-slate>
  </div>
</ng-template>

<div class="col col-auto"
     fitSlideOutCollapsable="(min-width: 100px)">
  <div class="card slideout__funds scroll"
       fitSlideOut="(min-width: 100px)"
       fitSlideOutPosition="right"
       [fitSlideOutDefaultStyle]="{ height: 'auto' }"
       #addManualSlideout="fitSlideOut">
    <div class="card-header d-flex justify-content-between shadow-sm p-5">
      <div class="font-size-h5 font-weight-bolder">
        Add New Fund | {{viewmodel?.fundRange}}
      </div>
      <button class="btn btn-icon btn-light-danger"
              fitSlideOutHideable="(max-width: 100px)"
              [fitSlideOutHideableSlideout]="addManualSlideout"
              (click)="closeRazor()">
        <span class="svg-icon svg-icon-md svg-icon-dark m-0">
          <fit-icon icon="navigation-close"></fit-icon>
        </span>
      </button>
    </div>
    <div class="card-body slideout-body p-3">
      <form [formGroup]="fundForm">
        <div class="tooltop-auto-width">
          <div class="d-flex my-2">
            <p class="font-weight-bold mb-0">Fund Code</p>
            <ng-container *ngIf="fundForm.code.value">
              <span class="svg-icon svg-icon-sm svg-icon-danger cursor-pointer ml-1"
                    (click)="clearFundCode()"
                    title="Clear Fund Code">
                <fit-icon icon="code-error-circle"></fit-icon>
              </span>
            </ng-container>
          </div>
          <fit-text-editor [control]="fundForm.code"
                           tooltipTitle="Code"
                           tooltip="Code of Fund."
                           helperText="Enter a fund code of 50 characters or less; examples are AMER or AUST">
          </fit-text-editor>
        </div>

        <div class="tooltop-auto-width">
          <div class="d-flex my-2">
            <p class="font-weight-bold mb-0">Fund Name</p>
            <ng-container *ngIf="fundForm.name.value">
              <span class="svg-icon svg-icon-sm svg-icon-danger cursor-pointer ml-1"
                    (click)="clearFundName()"
                    title="Clear Fund Name">
                <fit-icon icon="code-error-circle"></fit-icon>
              </span>
            </ng-container>
          </div>
          <fit-text-editor [control]="fundForm.name"
                           tooltipTitle="Name"
                           tooltip="Name of Fund."
                           helperText="Enter a fund name of 100 characters or less; an example is M&G Property Portfolio">
          </fit-text-editor>
        </div>

        <div class="tooltop-auto-width">
          <div class="d-flex my-2">
            <p class="font-weight-bold mb-0">Currency</p>
            <ng-container *ngIf="fundForm.currency.value">
              <span class="svg-icon svg-icon-sm svg-icon-danger cursor-pointer ml-1"
                    (click)="clearCurrency()"
                    title="Clear Currency">
                <fit-icon icon="code-error-circle"></fit-icon>
              </span>
            </ng-container>
          </div>
          <fit-select-editor [control]="fundForm.currency"
                             [options]="viewmodel?.currencyList"
                             tooltipTitle="Currency"
                             tooltip="Currency of Fund."
                             value="value">
          </fit-select-editor>
        </div>
      </form>
      <div class="d-flex flex-row justify-content-end">
        <fit-icon-button caption="Reset"
                         (onClick)="resetFunds()"
                         [isDisabled]="!canResetFundForm"
                         class="btn-light-warning">
        </fit-icon-button>
        <fit-icon-button caption="Save"
                         busyCaption="Saving"
                         (onClick)="addNewFund()"
                         [isDisabled]="!canSaveFundForm"
                         [busyLoading]="busyAdding">
        </fit-icon-button>
      </div>
    </div>
  </div>
</div>
