
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Design-/-Join-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <circle id="Oval" fill="#000000" opacity="0.3" cx="8" cy="16" r="2"></circle>
        <path d="M9,19 L5.00096004,19 C4.96315967,14.3606423 6.04480907,10.9610276 8.41240045,8.89946335 C10.6738787,6.93029637 13.8932784,5.99355491 18.0040306,6.00000246 L17.9977567,9.99999754 C14.7751756,9.99494305 12.4778257,10.6633977 11.0391383,11.9161251 C9.70656404,13.0764552 8.97080234,15.4164797 9,19 Z" id="Path-3" fill="#000000" fill-rule="nonzero" transform="translate(11.502017, 12.499985) scale(-1, 1) translate(-11.502017, -12.499985) "></path>
    </g>
</svg>
