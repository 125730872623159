import { Injectable, ComponentFactoryResolver, Type, ViewContainerRef, TemplateRef } from '@angular/core';
import { ModalHostDirective } from './modal.host.directive';
import { Modal } from 'src/app/shared/modals/modal.interface';
import { ModalConfig } from 'src/app/shared/modals/modal.config.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modalHost: ModalHostDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  get containerRef(): ViewContainerRef {
    return this.modalHost.viewContainerRef;
  }

  setHost(host: ModalHostDirective) {
    this.modalHost = host;
  }

  getHost(): ModalHostDirective {
    return this.modalHost;
  }

  createInstance<T extends Modal>(type: Type<T>, config: ModalConfig) {
    if (this.modalHost) {
      this.clearHost();
      const factory = this.componentFactoryResolver.resolveComponentFactory(type);
      const componentRef = this.containerRef.createComponent(factory);
      const instance = componentRef.instance as T;
      instance.configure(config);
      return instance;
    }
    return null;
  }

  createTemplateInstance<T>(template: TemplateRef<T>, context: T): void {
    if (this.modalHost) {
      this.clearHost();
      this.containerRef.createEmbeddedView(template, context);
    }
  }

  clearHost(): void {
    this.containerRef.clear();
  }

}
