import { DateTime } from "src/app/shared/types/date.time";
import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { ViewModel } from "src/app/shared/viewmodels/viewmodel";

export class QualitySurveyViewModel extends ViewModel {
  status: NameValueViewModel<string>;
  subService: NameValueViewModel<number>;
  createdDate: DateTime;
  hasBeenMailed: boolean;
  age: number;
  service: string;
  valueAssessment: string;

  constructor(obj?: QualitySurveyViewModel) {
    super(obj);
    if (obj) {
      this.status = new NameValueViewModel(obj.status);
      this.subService = new NameValueViewModel(obj.subService);
      this.createdDate = obj.createdDate;
      this.hasBeenMailed = obj.hasBeenMailed;
      this.age = obj.age;
      this.service = obj.service;
      this.valueAssessment = obj.valueAssessment;
    }
  }
}
