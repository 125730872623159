import { Component, Input } from '@angular/core';
import { InputEditor } from 'src/app/shared/editors/input.editor';
import { ToggleTooltipsService } from 'src/app/shared/services/toggle.tooltips.service';

@Component({
  selector: 'fit-number-editor',
  templateUrl: './number.editor.html',
  styleUrls: ['./editor.scss']
})
export class NumberEditor extends InputEditor {

  constructor(toggleTooltipsService: ToggleTooltipsService) {
    super('number', toggleTooltipsService);
  }

  enforceBoundries(event: any) {
    if (event.target.value > this.max) {
      this.control.setValue(this.max);
    } 
  }

  @Input()
  min: number;

  @Input()
  max: number;

  @Input()
  step: number;

  @Input()
  append: string
}
