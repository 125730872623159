import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotfoundRoutingModule } from 'src/app/notfound/routing/notfound.routing.module';
import { NotfoundComponent } from 'src/app/notfound/components/notfound.component';

@NgModule({
  imports: [CommonModule, NotfoundRoutingModule],

  declarations: [NotfoundComponent]
})

export class NotfoundModule { }
