import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Editor } from 'src/app/shared/editors/editor';

@Component({
  selector: 'fit-select-editor',
  templateUrl: './select.editor.html',
  styleUrls: ['./editor.scss','./select.editor.css']
})
export class SelectEditor extends Editor{

  @Input() width = '100%';

  @Input() busyLoading = false;

  @Input() options = new Array<any>();

  @Input() addNullOption = false;

  @Input() value: string;

  @Input() display: string;

  @Output() selectionChanged = new EventEmitter<any>();

  handleChange(value: any) {
    this.selectionChanged.emit(value);
  }
}
