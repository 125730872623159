
<ng-container *ngIf="survey">
  <form [formGroup]="survey">
    <div class="card card-custom border mb-2">
      <div class="card-header min-h-50px">
        <div class="card-title m-0">
          Step 1: Enter KPI Details
        </div>
      </div>
      <div class="card-body py-3">
        <fit-checkbox-editor [control]="survey?.kpiOrSla"
                             [defaultMargins]="false"
                             [isReadOnly]="isReadOnly"
                             label="KPI/SLA"
                             helperText="Do you use KPIs or SLA terms to measure the quality of this service?"
                             tooltip="If yes, please list the most relevant KPIs or SLA terms.
                                If no, the service will be marked as 'Must Improve'.">
        </fit-checkbox-editor>
        <ng-container *ngIf="survey?.kpiOrSla.typedValue">
          <fit-textarea-editor [control]="survey?.kpiOrSlaTerms"
                               [isReadOnly]="isReadOnly"
                               width="75%"
                               label="KPI/SLA Terms"
                               helperText="Please list the most relevant KPIs or SLA terms">
          </fit-textarea-editor>
          <fit-select-editor [control]="survey?.kpiOrSlaMet"
                             [options]="survey?.viewModel.kpiOrSLAMetOptions"
                             [isReadOnly]="isReadOnly"
                             width="350px"
                             label="KPI/SLA Measurement"
                             helperText="Have the KPIs or SLA terms used to measure the quality of this service been met, exceeded or not met over the reference period?"
                             tooltip="If met, the service will be marked as at least ‘Satisfactory’.
                             If exceeded, the service will be marked as at least ‘Good’.
                             If not met, the service will be marked as ‘Must Improve’.">
          </fit-select-editor>
          <ng-container *ngIf="survey?.kpiOrSlaMet.typedValue && survey?.kpiOrSlaMet.typedValue.value == 2">
            <fit-checkbox-editor [control]="survey?.kpiOrSlaEvidence"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Evidence"
                                 helperText="Is there evidence you can provide to suggest that this service sits within the top quartile of the industry or M&G's peer group?"
                                 tooltip="Evidence may take the form of internally-generated research or independent third-party research (the latter is preferable).
                                If such evidence exists, the service will be marked as 'Outstanding'.
                                If not, the service will be marked as 'Good'.">
            </fit-checkbox-editor>
            <ng-container *ngIf="survey?.kpiOrSlaMet.typedValue && survey?.kpiOrSlaMet.typedValue.value == 2 && survey?.kpiOrSlaEvidence.typedValue">
              <span>Please attach the relevant evidence below:</span><span style="color:red">*</span>
              <fit-attachments-editor label="Evidence Attachments"
                                      [isReadOnly]="isReadOnly"
                                      [showLabel]="false"
                                      [attachments]="survey?.evidenceFiles"
                                      [allowMultiple]="true"
                                      width="75%">
              </fit-attachments-editor>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="survey?.kpiOrSlaMet.typedValue && survey?.kpiOrSlaMet.typedValue.value == 1">
            <fit-checkbox-editor [control]="survey?.outsideKpiOrSla"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Evidence of Additional Value Add"
                                 helperText="Was there any additional value assessed for this service outside of KPI/SLA terms that, in your view, would justify a rating of ‘Good’?
                                 If so, please enter information in the box below – this will change the service rating from ‘Satisfactory’ to ‘Good’?"
                                 tooltip="If there was additional value assessed by this service, You will need to give a description of it">
            </fit-checkbox-editor>
            <ng-container *ngIf="survey?.outsideKpiOrSla.typedValue">
              <fit-textarea-editor [control]="survey?.additionalValues"
                                   [isReadOnly]="isReadOnly"
                                   width="75%"
                                   label="Description of Additional Value Added"
                                   helperText="Please describe the additional value added for this service">
              </fit-textarea-editor>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="card card-custom border">
      <div class="card-header min-h-50px">
        <div class="card-title m-0">
          Step 2: Enter Service Details
        </div>
      </div>
      <div class="card-body py-3">
        <fit-textarea-editor [control]="survey?.subServiceDefinition"
                             [isReadOnly]="isReadOnly"
                             width="75%"
                             label="Service Definition"
                             helperText="Please check that the current service definition remains correct.">

        </fit-textarea-editor>
        <fit-textarea-editor [control]="survey?.fundIssues"
                             [isReadOnly]="isReadOnly"
                             width="75%"
                             label="Fund-specific Issues"
                             helperText="Were any fund-specific issues identified for the service (pertaining to in-scope funds) during the reference period?">

        </fit-textarea-editor>
        <fit-textarea-editor [control]="survey?.improvements"
                             [isReadOnly]="isReadOnly"
                             width="75%"
                             label="Improvements"
                             helperText="Are you planning any improvements, or would you like to highlight any improvements made during the reference period?">

        </fit-textarea-editor>
        <fit-textarea-editor [control]="survey?.comment"
                             [isReadOnly]="isReadOnly"
                             width="75%"
                             label="Comments"
                             helperText="Please provide any other comments or context to your previous answers.">

        </fit-textarea-editor>
        <fit-attachments-editor label="Attachments"
                                [isReadOnly]="isReadOnly"
                                [showLabel]="true"
                                [attachments]="survey?.files"
                                [allowMultiple]="true"
                                width="75%">
        </fit-attachments-editor>
      </div>
    </div>
  </form>
</ng-container>


