import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[fitSlideOutCollapsable]'
})
export class SlideOutCollapsableDirective implements AfterViewInit, OnDestroy {

  @Input('fitSlideOutCollapsable')
  mediaQuery: string | string[];

  private destroyed = new Subject();
  private destroyed$ = this.destroyed.asObservable();

  constructor(private elementRef: ElementRef<HTMLElement>,
    private mediaMatcher: MediaMatcher) {
  }

  ngAfterViewInit(): void {
    const { padding, margin } = window.getComputedStyle(this.elementRef.nativeElement);
    this.setVisibility({
      width: 'auto',
      padding,
      margin
    });

    fromEvent(window, 'resize').pipe(
      takeUntil(this.destroyed$)
    ).subscribe(_ => this.setVisibility({
      width: 'auto',
      padding,
      margin
    }));
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private setVisibility(defaultStyle: { width: string, padding: string, margin: string; }) {

    const isMatch = (mediaQuery: string) =>
      this.mediaMatcher.matchMedia(mediaQuery).matches;

    const setStyle = (match: boolean) => {
      if (match) {
        this.elementRef.nativeElement.style.width = '0';
        this.elementRef.nativeElement.style.padding = '0';
        this.elementRef.nativeElement.style.margin = '0';
      } else {
        this.elementRef.nativeElement.style.width = defaultStyle.width;
        this.elementRef.nativeElement.style.padding = defaultStyle.padding;
        this.elementRef.nativeElement.style.margin = defaultStyle.margin;
      }
    };

    if (typeof this.mediaQuery === 'string') {
      setStyle(isMatch(this.mediaQuery as string));
    } else if (typeof this.mediaQuery === 'object' && Array.isArray(this.mediaQuery)) {
      setStyle(this.mediaQuery.some(mediaQuery => isMatch(mediaQuery)));
    }
  }
}
