import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-files-protected-file-icon',
  templateUrl: './files-protected-file.component.html'
})
export class FilesProtectedFileComponent extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
