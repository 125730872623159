import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Injectable()
export class IsUnauthenticatedGuard implements CanActivate {

  constructor(private authService: AuthenticationService,
    private router: Router) { }

  canActivate() {
    if (!this.authService.isLoggedIn) {
      return true;
    }
    else {
      this.router.navigate([this.authService.getAuthorizedPath()]);
    }
    return false;
  }
}
