
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Map-/-Direction1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M12,16.381038 L12,6.47213595 L5.99460483,18.4829263 L12,16.381038 Z M2.88230018,20.2353996 L11.2844582,3.43108351 C11.4820496,3.03590071 11.9625881,2.87572123 12.3577709,3.07331263 C12.5125928,3.15072359 12.6381308,3.27626158 12.7155418,3.43108351 L21.1176998,20.2353996 C21.3152912,20.6305824 21.1551117,21.1111209 20.7599289,21.3087123 C20.5664522,21.4054506 20.3420471,21.4197165 20.1378777,21.3482572 L12,18.5 L3.86212227,21.3482572 C3.44509941,21.4942152 2.98871325,21.2744737 2.84275525,20.8574509 C2.77129597,20.6532815 2.78556182,20.4288764 2.88230018,20.2353996 Z" id="Path-99" fill="#000000" fill-rule="nonzero"></path>
    </g>
</svg>
