<div class="pagination pagination-md"
     role="navigation"
     *ngIf="totalItems > 0">
  <ul class="pagination">
    <li [class]="class"
        [ngClass]="{'page-item': true, 'disabled': !canGoPrevious }">
      <a class="page-link"
         [ngClass]="class"
         (click)="goToPage(currentPage - 1)">
        Previous
      </a>
    </li>
    <li [class]="class"
        [ngClass]="{'page-item': true, 'active': currentPage === buttonOneNumber}">
      <a class="page-link"
         [ngClass]="class"
         (click)="goToPage(buttonOneNumber)">
        {{ buttonOneNumber }}
      </a>
    </li>
    <li *ngIf="this.lastPageNumber > 1"
        [class]="class"
        [ngClass]="{'page-item active': true, 'active': currentPage === buttonTwoNumber}"
        aria-current="page">
      <a class="page-link"
         [ngClass]="class"
         (click)="goToPage(buttonTwoNumber)">
        {{ buttonTwoNumber }}
      </a>
    </li>
    <li *ngIf="this.lastPageNumber > 2"
        [class]="class"
        [ngClass]="{'page-item': true, 'active': currentPage === buttonThreeNumber}">
      <a class="page-link"
         [ngClass]="class"
         (click)="goToPage(buttonThreeNumber)">
        {{ buttonThreeNumber }}
      </a>
    </li>
    <li [class]="class"
        [ngClass]="{'page-item': true, 'disabled': !canGoNext }">
      <a class="page-link"
         [ngClass]="class"
         (click)="goToPage(currentPage + 1)">
        Next
      </a>
    </li>
  </ul>

  <div *ngIf="showPageOf" class="d-flex ml-3"
         [ngClass]="class">
    Page {{currentPage}} of {{lastPageNumber}}
  </div>
</div>
