import { Validators } from "@angular/forms";
import { TypedForm } from "src/app/shared/forms/typed.form";
import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { FundRangeFundSurveyEditorViewModel } from "src/app/surveys/fundrange-survey/fundrange-fund-survey/viewmodels/fund.range.fund.survey.editor.viewmodel";

export class ClassificationsForm extends TypedForm  {

  investmentTechnique = this.addTypedControl<NameValueViewModel<string>>('investmentTechnique');
  investmentGeography = this.addTypedControl<NameValueViewModel<string>>('investmentGeography');
  investmentUniverse = this.addTypedControl<NameValueViewModel<string>>('investmentUniverse');
  publicPrivate = this.addTypedControl<NameValueViewModel<string>>('publicPrivate');
  assetClass = this.addTypedControl<NameValueViewModel<string>>('assetClass');
  passiveType = this.addTypedControl<NameValueViewModel<string>>('passiveType');
  scenario = this.addTypedControl<NameValueViewModel<number>>('scenario');

  viewModel: FundRangeFundSurveyEditorViewModel;

  constructor(isVATeam: boolean = false) {
    super();
    if (isVATeam) {
      Object.keys(this.controls).forEach(key => {
        this.controls[key].setValidators([Validators.required]);
        this.controls[key].updateValueAndValidity();
      });
    }
  }

  populate(viewModel: FundRangeFundSurveyEditorViewModel) {
    this.viewModel = viewModel;

    this.investmentTechnique.setTypedValue(this.viewModel.investmentTechniques.find(c => c.value == viewModel.investmentTechnique.value));
    this.investmentGeography.setTypedValue(this.viewModel.investmentGeographies.find(c => c.value == viewModel.investmentGeography.value));
    this.investmentUniverse.setTypedValue(this.viewModel.investmentUniverses.find(c => c.value == viewModel.investmentUniverse.value));
    this.publicPrivate.setTypedValue(this.viewModel.publicPrivates.find(c => c.value == viewModel.publicPrivate.value));
    this.assetClass.setTypedValue(this.viewModel.assetClasses.find(c => c.value == viewModel.assetClass.value));
    this.passiveType.setTypedValue(this.viewModel.passiveTypes.find(c => c.value == viewModel.passiveType.value));
    this.scenario.setTypedValue(this.viewModel.scenarioTypeList.find(c => c.value == viewModel.scenario.value));
  }

  getViewModel(viewModel: FundRangeFundSurveyEditorViewModel): FundRangeFundSurveyEditorViewModel {
    viewModel.investmentTechnique = this.investmentTechnique.typedValue;
    viewModel.investmentGeography = this.investmentGeography.typedValue;
    viewModel.investmentUniverse = this.investmentUniverse.typedValue;
    viewModel.publicPrivate = this.publicPrivate.typedValue;
    viewModel.assetClass = this.assetClass.typedValue;
    viewModel.passiveType = this.passiveType.typedValue;
    viewModel.scenario = this.scenario.typedValue;

    return viewModel;
  }
}
