import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-shopping-money-icon',
  templateUrl: './shopping-money.component.html'
})
export class ShoppingMoneyComponent extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
