<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Stockholm-icons-/-Communication-/-Group" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path d="m19 7.79v6.57c-.49.37-1.14.47-1.72.27l-.19-.07c.59-1.16.37-2.58-.56-3.53l-3.37-3.37 2.68-.94z" fill="#000000" />
    <path d="m10.87 12.27 3.53 3.47v.01c-.09.32-.26.61-.5.85-.43.42-1 .63-1.56.6l-3.76-3.76c-.09.08-.13.19-.13.3 0 .13.06.27.19.35l3.57 3.5c-.1.24-.24.46-.43.65-.41.41-.95.61-1.49.61-.52 0-1.04-.19-1.44-.58l-2.2-1.99c-.48-.44-1.04-.75-1.66-.94.01-.03.01-.06.01-.09v-8.4l2.57-1.06c.74-.31 1.57-.25 2.25.12l-.16.08c-.86.43-1.47 1.25-1.64 2.23-.15.95.17 1.93.85 2.62.5.5 1.17.81 1.88.87-.05.07-.07.14-.07.21 0 .14.06.27.19.35z" fill="#000000" />
    <path d="m16.14 14.24c-.08.16-.18.3-.31.43-.39.39-.85.61-1.33.65l-3.61-3.6h.12c.48 0 .96-.12 1.38-.33l.32-.17 1.72-.87 1.39 1.39s0 0 .01.01c.66.67.77 1.7.31 2.49z" fill="#000000" />
    <path d="m3.25 16h-2.5c-.414 0-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75h2.5c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75z" fill="#000000" />
    <path d="m23.25 16h-2.5c-.414 0-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75h2.5c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75z" fill="#000000" />
    <path d="m17.94 6.1c-.01 0-.02-.01-.04-.01l-2.29-.94c-.71-.29-1.51-.27-2.2.08l-2.73 1.37-.57.28c-.58.29-.99.85-1.1 1.49-.1.64.11 1.3.57 1.76.39.39.91.59 1.43.59.31 0 .63-.07.92-.22l.33-.17 1.43-.72 3.98-2 1.33.55v-1.41c0-.07 0-.14.02-.2z" fill="#000000" />
    </g>
</svg>
