import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from 'src/app/login/components/login.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoginService } from 'src/app/login/services/login.service';
import { UserService } from '../teams/users/user.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],

  declarations: [LoginComponent],

  providers: [LoginService, UserService]
})
export class LoginModule { }
