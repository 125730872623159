import { Component, Input } from '@angular/core';
import { Editor } from 'src/app/shared/editors/editor';

@Component({
  selector: 'fit-textarea-editor',
  templateUrl: './textarea.editor.html',
  styleUrls: ['./editor.scss']
})
export class TextAreaEditor extends Editor {

  @Input()
  width = '100%';

  @Input()
  height = '130px';

}
