
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Navigation-/-Right-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
        <path d="M11.7071032,12.7071045 C11.3165789,13.0976288 10.6834139,13.0976288 10.2928896,12.7071045 C9.90236532,12.3165802 9.90236532,11.6834152 10.2928896,11.2928909 L16.2928896,5.29289093 C16.6714686,4.914312 17.281055,4.90106637 17.675721,5.26284357 L23.675721,10.7628436 C24.08284,11.136036 24.1103429,11.7686034 23.7371505,12.1757223 C23.3639581,12.5828413 22.7313908,12.6103443 22.3242718,12.2371519 L17.0300721,7.38413553 L11.7071032,12.7071045 Z" id="Path-94" fill="#000000" fill-rule="nonzero" transform="translate(16.999999, 8.999997) scale(1, -1) rotate(90.000000) translate(-16.999999, -8.999997) "></path>
        <path d="M15.5,8 C16.0522847,8 16.5,8.44771525 16.5,9 C16.5,9.55228475 16.0522847,10 15.5,10 L9,10 C8.44771525,10 8,10.4477153 8,11 L8,21.0415946 C8,21.5938793 7.55228475,22.0415946 7,22.0415946 C6.44771525,22.0415946 6,21.5938793 6,21.0415946 L6,11 C6,9.34314575 7.34314575,8 9,8 L15.5,8 Z" id="Path-96" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
    </g>
</svg>
