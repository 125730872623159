import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentViewModel } from 'src/app/shared/viewmodels/document.viewmodel';
import { EditState } from 'src/app/shared/types/edit.state';

@Component({
  selector: 'fit-attachments-editor',
  templateUrl: './attachments.editor.html',
  styleUrls: ['./attachments.editor.scss']
})
export class AttachmentsEditor {

  EditState = EditState;

  @Input()
  width = "100%";

  @Input()
  isReadOnly = false;

  @Input()
  maxFileSize = 25000000;

  @Input()
  attachments: DocumentViewModel[];

  @Output()
  attachmentsChange = new EventEmitter<DocumentViewModel[]>();

  rejectedFiles: File[] = [];

  @Input()
  showLabel = true;

  @Input()
  allowMultiple = true;

  get hasFiles(): boolean {
    return this.attachments?.length > 0 || this.rejectedFiles.length > 0;
  }

  get shouldDisableClick(): boolean {
    return this.allowMultiple == false && this.attachments.length >= 1;
  }

  isExistingDocument(document: DocumentViewModel): boolean {
    return !!document.externalStoreId;
  }

  onSelect(event: { addedFiles: File[], rejectedFiles: File[]; }): void {
    this.attachments.push(...event.addedFiles.map(fileToDocumentViewModel));
    this.attachmentsChange.emit(this.attachments);
    this.rejectedFiles.push(...event.rejectedFiles);
  }

  onRemove(file: DocumentViewModel): void {
    if (this.isExistingDocument(file)) {
      file.editState = EditState.deleted;
    } else {
      this.attachments.splice(this.attachments.indexOf(file), 1);
    }
  }

  downloadAttachment(attachment: DocumentViewModel) {
    let link = document.createElement('a');
    link.download = attachment.fileName;
    if (attachment.blobLink) {
      link.href = attachment.blobLink;
    } else {
      link.href = window.URL.createObjectURL(new Blob([attachment.file]));
    }
    link.click();
  }
}

const fileToDocumentViewModel = (file: File): DocumentViewModel => {
  const viewModel = new DocumentViewModel();
  viewModel.name = file.name;
  viewModel.file = file;
  viewModel.fileName = file.name;
  viewModel.fileType = file.type;
  viewModel.fileSize = file.size / 1000;
  viewModel.editState = EditState.added;
  return viewModel;
};
