import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from 'src/app/shared/guards/is.authenticated.guard';
import { FundRangeSurveysComponent } from 'src/app/surveys/fundrange-survey/fund.range.surveys.component';
import { FundRangeFundSurveyComponent } from 'src/app/surveys/fundrange-survey/fundrange-fund-survey/fund.range.fund.survey.component';
import { FundRangeFundsComponent } from 'src/app/surveys/fundrange-survey/fundrange-funds/fund.range.funds.component';
import { PricingSurveyComponent } from 'src/app/surveys/pricing-survey/pricing.survey.component';
import { PricingSurveysComponent } from 'src/app/surveys/pricing-survey/pricing.surveys.component';
import { QualitySurveyComponent } from 'src/app/surveys/quality-survey/quality.survey.component';
import { QualitySurveysComponent } from 'src/app/surveys/quality-survey/quality.surveys.component';

const routes: Routes = [
  {
    path: 'surveys/quality',
    component: QualitySurveysComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'surveys/quality/survey/:surveyId',
    component: QualitySurveyComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'surveys/pricing',
    component: PricingSurveysComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'surveys/pricing/survey/:surveyId',
    component: PricingSurveyComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'surveys/fundrange',
    component: FundRangeSurveysComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'surveys/fundrange/funds/:surveyId',
    component: FundRangeFundsComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'surveys/fundrange/funds/survey/:fundId',
    component: FundRangeFundSurveyComponent,
    canActivate: [IsAuthenticatedGuard]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveysRoutingModule { }
