<fit-subheader title="Capture Fund Classification" [busyLoading]="busyLoading" [isFullWidth]="false">
  <div class="d-flex flex-row flex-wrap ml-auto">
    <fit-icon-button caption="Back"
                     (onClick)="goBack()"
                     icon="navigation-arrow-left"
                     class="btn-light-warning">
    </fit-icon-button>
    <fit-icon-button caption="Refresh"
                     (onClick)="load()"
                     icon="general-update"
                     [isDisabled]="!canRefresh"
                     [busyLoading]="busyLoading">
    </fit-icon-button>
  </div>
</fit-subheader>

<div class="container d-flex flex-column h-100">
  <ng-container *ngIf="!busyLoading else loading">
    <ng-container *ngTemplateOutlet="manageView"></ng-container>
  </ng-container>
</div>

<ng-template #manageView>

  <div class="card card-custom">
    <div class="card-header py-4 flex-column">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="card-label font-weight-bold text-dark text-nowrap align-self-center m-0">
          Fund Range Survey | {{viewmodel?.fund.getShortName(30)}}
        </h2>
        <div class="btn-group" role="group" aria-label="Navigation buttons">
          <label class="btn btn-outline-secondary align-self-center mb-0"
                 (click)="navigate(1)"
                 [ngClass]="{'active' : isNavigationActive(1)}">
            1: Strategy
            <span class="svg-icon"
                  [ngClass]="form.strategy.invalid ? 'svg-icon-danger' : 'svg-icon-success'">
              <fit-icon [icon]="form.strategy.invalid ? 'code-error-circle' : 'code-done-circle'" class=""></fit-icon>
            </span>
          </label>
          <label class="btn btn-outline-secondary align-self-center mb-0"
                 (click)="navigate(2)"
                 [ngClass]="{'active' : isNavigationActive(2)}">
            2: Classifications
            <span class="svg-icon"
                  [ngClass]="form.classifications.invalid ? 'svg-icon-danger' : 'svg-icon-success'">
              <fit-icon [icon]="form.classifications.invalid ? 'code-error-circle' : 'code-done-circle'" class=""></fit-icon>
            </span>
          </label>
        </div>
        <div class="d-flex flex-row align-self-center">
          <fit-icon-button caption="Reset" (onClick)="resetSurvey()" [isDisabled]="!canReset" class="btn-light-warning"></fit-icon-button>
          <fit-icon-button caption="Save" (onClick)="saveSurvey()" [isDisabled]="!canSave" [busyLoading]="busySaving" busyCaption="Saving"></fit-icon-button>
        </div>
      </div>
      <label class="card-label text-dark-50 m-0">
        Click tabs on the right to navigate the form
      </label>
    </div>
    <div class="card-body py-3">
      <div *ngIf="viewmodel?.rejectComment" class="alert alert-custom alert-light-danger mb-5 py-3 px-9" style="flex-direction:column" role="alert">
        <div class="alert-heading"><b>Reject comment: </b></div>
        <p>{{viewmodel?.rejectComment}}</p>
      </div>
      <fit-fund-range-fund-survey-editor [survey]="form"
                                         [isReadOnly]="viewmodel.isLocked"
                                         [navigation]="navigation">
      </fit-fund-range-fund-survey-editor>
    </div>
  </div>

</ng-template>

<ng-template #loading>
  <fit-grid-skeleton [columnCount]="4"></fit-grid-skeleton>
</ng-template>

<ng-template #submittedSurvey>
  <div class="card card-custom">
    <fit-clean-slate [hasBorder]="false" message="Your survey has been submitted">
      <div class="d-flex justify-content-center">
        <img src="assets/img/clean-slate/handshake-slate.svg"
             width="120"
             class="mb-5" />
      </div>
    </fit-clean-slate>
  </div>
</ng-template>
