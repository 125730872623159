
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Text-/-Text-width" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M13.5,4 L13.5,14 C13.5,14.5522847 13.0522847,15 12.5,15 L11.5,15 C10.9477153,15 10.5,14.5522847 10.5,14 L10.5,4 L7,4 C6.44771525,4 6,3.55228475 6,3 L6,2 C6,1.44771525 6.44771525,1 7,1 L17,1 C17.5522847,1 18,1.44771525 18,2 L18,3 C18,3.55228475 17.5522847,4 17,4 L13.5,4 Z" id="T" fill="#000000" opacity="0.3"></path>
        <path d="M6.41421356,20 L7.70710678,21.2928932 C8.09763107,21.6834175 8.09763107,22.3165825 7.70710678,22.7071068 C7.31658249,23.0976311 6.68341751,23.0976311 6.29289322,22.7071068 L3.29289322,19.7071068 C2.90236893,19.3165825 2.90236893,18.6834175 3.29289322,18.2928932 L6.29289322,15.2928932 C6.68341751,14.9023689 7.31658249,14.9023689 7.70710678,15.2928932 C8.09763107,15.6834175 8.09763107,16.3165825 7.70710678,16.7071068 L6.41421356,18 L17.5857864,18 L16.2928932,16.7071068 C15.9023689,16.3165825 15.9023689,15.6834175 16.2928932,15.2928932 C16.6834175,14.9023689 17.3165825,14.9023689 17.7071068,15.2928932 L20.7071068,18.2928932 C21.0976311,18.6834175 21.0976311,19.3165825 20.7071068,19.7071068 L17.7071068,22.7071068 C17.3165825,23.0976311 16.6834175,23.0976311 16.2928932,22.7071068 C15.9023689,22.3165825 15.9023689,21.6834175 16.2928932,21.2928932 L17.5857864,20 L6.41421356,20 Z" id="Combined-Shape" fill="#000000"></path>
    </g>
</svg>
