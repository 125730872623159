
<ng-container *ngIf="survey">
  <form [formGroup]="survey">
    <ng-container *ngIf="navigation == 1">
      <div class="card card-custom border mb-2">
        <div class="card-header min-h-50px">
          <div class="card-title m-0">
            Strategy
          </div>
        </div>
        <div class="card-body py-3">
          <div [formGroup]="survey.strategy">
            <fit-select-editor [control]="survey?.strategy.fundOrMandate"
                               [options]="survey?.strategy.viewModel?.fundOrMandateTypeList"
                               [isReadOnly]="isReadOnly"
                               width="350px"
                               label="Fund Type"
                               tooltip="">
            </fit-select-editor>
            <fit-textarea-editor [control]="survey?.strategy.investmentObjective"
                                 [isReadOnly]="isReadOnly"
                                 width="75%"
                                 label="Investment Objective"
                                 helperText="In Depth Description of Investment Objective">
            </fit-textarea-editor>
            <fit-textarea-editor [control]="survey?.strategy.chargeDetail"
                                 [isReadOnly]="isReadOnly"
                                 width="75%"
                                 label="Charge Details"
                                 helperText="Details of the Charge">
            </fit-textarea-editor>
            <fit-select-editor [control]="survey?.strategy.currency"
                               [options]="survey?.strategy.viewModel?.currencyList"
                               [isReadOnly]="isReadOnly"
                               width="350px"
                               label="Base Currency"
                               helperText="Currency of Fund"
                               tooltip="">
            </fit-select-editor>
            <fit-number-editor [control]="survey?.strategy.aum"
                               [isReadOnly]="isReadOnly"
                               width="350px"
                               label="Fund/Mandate AUM"
                               helperText="Assets Under Management">
            </fit-number-editor>
            <div class="d-flex flex-row">
              <fit-select-editor [control]="survey?.strategy.statusLock"
                                 [options]="survey?.strategy.viewModel?.statusTypeList"
                                 [isReadOnly]="(isReadOnly || isVATeam || survey?.strategy.viewModel?.isOIKFund)"
                                 width="350px"
                                 label="Status Override"
                                 helperText="Status Override (OIEC Funds cannot be be overriden)"
                                 [tooltip]="isVATeam ? 'VA Team Members cannot override the status' : ''">
              </fit-select-editor>
              <div class="col-form-label text-left font-weight-bold col-8">
                <div class="d-flex flex-row">
                  <div class="d-flex position-relative">
                    Fund Status:
                  </div>
                </div>
                <span class="label label-xl label-inline label-light-info font-weight-normal mt-3">{{survey?.viewModel.fundStatus}}</span>
              </div>
            </div>
            <fit-textarea-editor [control]="survey?.strategy.comments"
                                 [isReadOnly]="isReadOnly"
                                 width="75%"
                                 label="Comments">
            </fit-textarea-editor>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="navigation == 2">
      <div class="card card-custom border">
        <div class="card-header min-h-50px">
          <div class="card-title m-0">
            Classifications
          </div>
        </div>
        <div class="card-body py-3">
          <div [formGroup]="survey.classifications">
            <fit-select-editor [control]="survey?.classifications.scenario"
                               [options]="survey?.classifications.viewModel?.scenarioTypeList"
                               [isReadOnly]="isReadOnly"
                               width="350px"
                               label="Scenario"
                               helperText=""
                               tooltip="">
            </fit-select-editor>
            <fit-select-editor [control]="survey?.classifications.assetClass"
                               [options]="survey?.classifications.viewModel?.assetClasses"
                               [isReadOnly]="isReadOnly"
                               width="350px"
                               label="Asset Class"
                               helperText=""
                               tooltip="">
            </fit-select-editor>
            <fit-select-editor [control]="survey?.classifications.passiveType"
                               [options]="survey?.classifications.viewModel?.passiveTypes"
                               [isReadOnly]="isReadOnly"
                               width="350px"
                               label="Active/Passive"
                               helperText=""
                               tooltip="">
            </fit-select-editor>
            <fit-select-editor [control]="survey?.classifications.publicPrivate"
                               [options]="survey?.classifications.viewModel?.publicPrivates"
                               [isReadOnly]="isReadOnly"
                               width="350px"
                               label="Public Private"
                               helperText=""
                               tooltip="">
            </fit-select-editor>
            <fit-select-editor [control]="survey?.classifications.investmentGeography"
                               [options]="survey?.classifications.viewModel?.investmentGeographies"
                               [isReadOnly]="isReadOnly"
                               width="350px"
                               label="Investment Geography"
                               helperText=""
                               tooltip="">
            </fit-select-editor>
            <fit-select-editor [control]="survey?.classifications.investmentUniverse"
                               [options]="survey?.classifications.viewModel?.investmentUniverses"
                               [isReadOnly]="isReadOnly"
                               width="350px"
                               label="Investment Universe"
                               helperText=""
                               tooltip="">
            </fit-select-editor>
            <fit-select-editor [control]="survey?.classifications.investmentTechnique"
                               [options]="survey?.classifications.viewModel?.investmentTechniques"
                               [isReadOnly]="isReadOnly"
                               width="350px"
                               label="Investment Technique"
                               helperText=""
                               tooltip="">
            </fit-select-editor>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="navigation == 3">
      <div class="card card-custom border">
        <div class="card-header min-h-50px">
          <div class="card-title m-0">
            Indicators
          </div>
        </div>
        <div class="card-body py-3 d-flex flex-row" [formGroup]="survey.indicators">
          <div class="col-md-6">
            <fit-checkbox-editor [control]="survey?.indicators.isTotalReturn"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Total Return (Cap Growth + Inc)?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isCapitalGrowth"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Capital Growth / Preservation?"
                                 helperText=""
                                 [labelClickable]="true"
                                 default="0"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isYieldIncome"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Yield / Income - X%?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isYieldIncomeGrowingHigh"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Yield / Income Growing High?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isYieldIncomeRelative"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Yield ? Income Relative?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isTarget"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Target (X%)?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isTargetReturn"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Target Return: Cash + X%?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isCharities"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Charities?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
          </div>
          <div class="col-md-6">
            <fit-checkbox-editor [control]="survey?.indicators.isInflationProtection"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Inflation Protection?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isVolatilityOutcome"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Volatility Outcome?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isTracker"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Tracker?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isLiquidityMatching"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Liquidity Matching?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isPositiveImpact"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Positive Impact?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isLeverage"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="Leverage > 110%"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>
            <fit-checkbox-editor [control]="survey?.indicators.isESG"
                                 [defaultMargins]="false"
                                 [isReadOnly]="isReadOnly"
                                 label="ESG?"
                                 helperText=""
                                 [labelClickable]="true"
                                 tooltip="">
            </fit-checkbox-editor>

          </div>
        </div>
      </div>
    </ng-container>
  </form>
</ng-container>


