import { Validators } from "@angular/forms";
import { TypedForm } from "src/app/shared/forms/typed.form";
import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { PricingSurveyEditorViewModel } from "src/app/surveys/pricing-survey/viewmodels/pricing.survey.editor.viewmodel";

export class PricingSurveyForm extends TypedForm {
  conclusion = this.addTypedControl<NameValueViewModel<number>>('conclusion', [Validators.required]);
  comment = this.addTypedControl<string>('comment', [Validators.required, Validators.maxLength(2000)]);

  viewModel: PricingSurveyEditorViewModel;
    
  constructor(viewModel: PricingSurveyEditorViewModel) {
    super();
    this.viewModel = viewModel;
    this.populate(viewModel);
  };


  populate(viewModel: PricingSurveyEditorViewModel) {
    this.conclusion.setTypedValue(this.viewModel.conclusionOptions.find(f => f.value == viewModel.conclusion.value));
    this.comment.setTypedValue(viewModel.comment);
    this.markAsPristine();
  }

  getViewModel(): PricingSurveyEditorViewModel {
    const viewModel = new PricingSurveyEditorViewModel();
    viewModel.conclusion = this.conclusion.typedValue;
    viewModel.comment = this.comment.typedValue;
    viewModel.instanceId = this.viewModel.instanceId;
    viewModel.rowVersion = this.viewModel.rowVersion;
    return viewModel;
  }
}
