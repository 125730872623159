<svg width="24px"
     height="24px"
     viewBox="0 0 24 24"
     version="1.1"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Stockholm-icons-/-Code-/-Warning-2"
       stroke="none"
       stroke-width="1"
       fill="none"
       fill-rule="evenodd">
        <rect id="bound"
              x="0"
              y="0"
              width="24"
              height="24"></rect>
        <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z"
              id="Path-117"
              fill="#000000"
              opacity="0.3"></path>
        <rect id="Rectangle-9"
              fill="#000000"
              x="11"
              y="9"
              width="2"
              height="7"
              rx="1"></rect>
        <rect id="Rectangle-9-Copy"
              fill="#000000"
              x="11"
              y="17"
              width="2"
              height="2"
              rx="1"></rect>
    </g>
</svg>