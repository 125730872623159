import { Component, Injector, Input, OnInit } from '@angular/core';

import { iconMap } from 'src/app/icons/constants';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-icon',
  template: '<ng-container *ngComponentOutlet="iconType; injector: instanceInjector"></ng-container>'
})
export class IconComponent implements OnInit {
  @Input()
  icon: string;

  instanceInjector: Injector;

  get iconType(): typeof IconBase {
    const type = iconMap[this.icon];

    if (!type) {
      throw new Error(`Unknown icon type: ${this.icon}`);
    }

    return type;
  }

  constructor(private injector: Injector) { }

  ngOnInit(): void {
    this.instanceInjector = Injector.create({
      providers: [
        {
          provide: IconConfig,
          useValue: new IconConfig()
        }
      ],
      parent: this.injector
    });
  }
}
