import { NameViewModel } from "src/app/shared/viewmodels/name.viewmodel";

export class NameValueViewModel<TValue> extends NameViewModel {

  static create<TValue>(name: string = '', value: TValue = null, selected: boolean = false) {
    const vm = new NameValueViewModel<TValue>();
    vm.name = name;
    vm.value = value;
    vm.selected = selected;

    return vm;
  }

  public value: TValue;
  
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.value = obj.value;
    }
  }

  getShortValue(len: number) {
    if (this.value && this.value.toString().length > len) {
      return this.value.toString().substring(0, len) + '...';
    }
    return this.value?.toString();
  }

  
}
