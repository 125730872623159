import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-map-compass-icon',
  templateUrl: './map-compass.component.html'
})
export class MapCompassComponent extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
