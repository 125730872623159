
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Text-/-Dots" stroke="none" stroke-width="1" >
       	<rect x="14" y="9" width="6" height="6" rx="3" fill="black"/>
		<rect x="3" y="9" width="6" height="6" rx="3" fill="black" fill-opacity="0.7"/>
	</g>
</svg>
