import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';

export type DropdownDirection = 'top' | 'bottom-start' | 'bottom auto' | 'bottom-left' | 'bottom-right' | 'bottom-end';

@Component({
  selector: 'fit-ngdropdown',
  templateUrl: './ngdropdown.component.html',
  styleUrls: ['./ngdropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgDropdownComponent {

  @ViewChild('button')
  button: ElementRef<HTMLButtonElement>;

  @Input()
  direction: DropdownDirection = 'bottom auto';

  @Input()
  defaultText: string;

  @Input()
  label: string;

  @Input()
  btnClass = "btn-outline-primary";

  @Input()
  isDisabled = false;

  @Input()
  showToggleIcon = true;

  constructor() { }


}
