import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-layout-layout-3d-icon',
  templateUrl: './layout-layout-3d.component.html'
})
export class LayoutLayout3dComponent extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
