<div (click)="onSort()" [class]="class">
  <span class="d-flex flex-row" [ngClass] ="{'justify-content-center' : isCentered}">
    <span *ngIf="icon">
      <span class="svg-icon svg-icon-sm m-0 d-flex flex-row"
            [ngClass]="{ 'svg-icon-primary' : sortOrder != 0 }">
        <fit-icon [icon]="icon"></fit-icon>
      </span>
    </span>
    <span [ngClass]="{ 'text-primary' : sortOrder != 0}">{{header}}</span>
    <span *ngIf="sortOrder == 0" class="svg-icon svg-icon-sm ml-0 align-self-center">
      <fit-icon icon="navigation-up-down"></fit-icon>
    </span>
    <span *ngIf="sortOrder == 1" class="svg-icon svg-icon-sm svg-icon-primary ml-0 align-self-center">
      <fit-icon icon="navigation-arrow-up"></fit-icon>
    </span>
    <span *ngIf="sortOrder == 2" class="svg-icon svg-icon-sm svg-icon-primary ml-0 align-self-center">
      <fit-icon icon="navigation-arrow-down"></fit-icon>
    </span>
    <span *ngIf="sortOrder == 3" class="svg-icon svg-icon-sm svg-icon-primary ml-0 align-self-center">
      <fit-icon icon="design-position"></fit-icon>
    </span>
  </span>
</div>
