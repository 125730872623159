import { NameValueViewModel } from "src/app/shared/viewmodels/name.value.viewmodel";
import { ViewModel } from "src/app/shared/viewmodels/viewmodel";

export class UserGroupViewModel extends NameValueViewModel<string> {

  hasPermissions = false;

  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.hasPermissions = obj?.hasPermissions;
    }
  }

}
