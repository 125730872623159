export class PagerViewModel {
  recordCount: number;
  pageRecordCount: number;
  start: number;
  end: number;
  page: number;
  pageCount: number;
  pageSize: number;

  constructor(obj?: any) {
    if (obj) {
      this.recordCount = obj?.recordCount;
      this.pageRecordCount = obj?.pageRecordCount;
      this.start = obj?.start;
      this.end = obj?.end;
      this.page = obj?.page;
      this.pageCount = obj?.pageCount;
      this.pageSize = obj?.pageSize;
    }
  }
}
