
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Design-/-Vertical" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Bound" points="0 0 24 0 24 24 0 24"></polygon>
        <path d="M12,3 C12.5522847,3 13,3.44771525 13,4 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,4 C11,3.44771525 11.4477153,3 12,3 Z M12,8 C12.5522847,8 13,8.44771525 13,9 L13,10 C13,10.5522847 12.5522847,11 12,11 C11.4477153,11 11,10.5522847 11,10 L11,9 C11,8.44771525 11.4477153,8 12,8 Z M12,13 C12.5522847,13 13,13.4477153 13,14 L13,15 C13,15.5522847 12.5522847,16 12,16 C11.4477153,16 11,15.5522847 11,15 L11,14 C11,13.4477153 11.4477153,13 12,13 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,20 C13,20.5522847 12.5522847,21 12,21 C11.4477153,21 11,20.5522847 11,20 L11,19 C11,18.4477153 11.4477153,18 12,18 Z" id="Combined-Shape" fill="#000000"></path>
        <path d="M21,9.04031242 L21,14.9596876 C21,15.23583 20.7761424,15.4596876 20.5,15.4596876 C20.3864643,15.4596876 20.276309,15.4210472 20.1876525,15.350122 L16.488043,12.3904344 C16.272412,12.2179296 16.2374514,11.9032834 16.4099561,11.6876525 C16.433022,11.6588201 16.4592107,11.6326315 16.488043,11.6095656 L20.1876525,8.64987802 C20.4032834,8.47737324 20.7179296,8.51233393 20.8904344,8.7279649 C20.9613596,8.81662142 21,8.92677668 21,9.04031242 Z M3,14.9596876 L3,9.04031242 C3,8.76417005 3.22385763,8.54031242 3.5,8.54031242 C3.61353575,8.54031242 3.723691,8.5789528 3.81234752,8.64987802 L7.51195699,11.6095656 C7.72758796,11.7820704 7.76254865,12.0967166 7.59004388,12.3123475 C7.56697799,12.3411799 7.54078935,12.3673685 7.51195699,12.3904344 L3.81234752,15.350122 C3.59671656,15.5226268 3.28207037,15.4876661 3.1095656,15.2720351 C3.03864038,15.1833786 3,15.0732233 3,14.9596876 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
    </g>
</svg>
