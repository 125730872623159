import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserViewModel } from 'src/app/shared/viewmodels/user.viewmodel';
import { PermissionItem } from 'src/app/shared/types/permission.item';

@Component({
  selector: 'fit-aside-menu',
  templateUrl: './aside.menu.component.html',
  styleUrls: ['./aside.menu.component.scss']
})
export class AsideMenuComponent implements  OnInit {

  PermissionType = PermissionItem;

  @ViewChild('kt_aside')
  ktAside: ElementRef<HTMLElement>;

  @HostBinding('class.hidden')
  hidden = true;

  userPermissions: number[];

  canViewSurveys = false;
  canViewAssessments = false;
  canViewDataMaintenance = false;
  canViewReference = false;
  isAdministrator = false;

  surveyPermissions = [
    PermissionItem.viewQualitySurveyDashboard,
    PermissionItem.viewFundRangeSurveyDashboard,
    PermissionItem.viewPricingSurveyDashboard
  ];

  assessmentPermissions = [
    PermissionItem.viewValueAssessments
  ];

  referencePermissions = [
    PermissionItem.viewClassifications,
    PermissionItem.viewServices,
    PermissionItem.viewFundRanges,
    PermissionItem.viewPricingTeam
  ];

  teamPermissions = [
    PermissionItem.viewUsers,
    PermissionItem.viewGroups,
  ];

  get menuExpanded(): boolean {
    return !this.document.body.classList.contains('aside-minimize');
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthenticationService,
    public router: Router) {
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.hidden = false;
      this.setPermissions();
    } else {
      this.hidden = true;
    }

    merge(this.authService.notifyLogin, this.authService.notifyLogout)
      .subscribe(_ => {
        if (this.authService.isLoggedIn) {
          this.hidden = false;
          this.setPermissions();
        } else {
          this.hidden = true;
        }
      });

    this.router.events.pipe(filter(evt => evt instanceof NavigationEnd)).subscribe(_ => {
      this.ktAside.nativeElement.classList.remove('aside-on');
    });
  }

  toggleMenuExpanded(): void {
    if (this.document.body.classList.contains('aside-minimize')) {
      this.document.body.classList.remove('aside-minimize');
    } else {
      this.document.body.classList.add('aside-minimize');
    }
  }

  toggleMenuVisibleMobile(): void {
    if (this.ktAside.nativeElement.classList.contains('aside-on')) {
      this.ktAside.nativeElement.classList.remove('aside-on');
    } else {
      this.ktAside.nativeElement.classList.add('aside-on');
    }
  }

  setPermissions() {
    this.isAdministrator = this.authService.user.isAdministrator;
    this.userPermissions = this.authService.user.permissions.map(p => p.value);
    this.canViewSurveys = this.userPermissions.some(x => this.surveyPermissions.includes(x));
    this.canViewAssessments = this.userPermissions.some(x => this.assessmentPermissions.includes(x));
    this.canViewReference = this.userPermissions.some(x => this.referencePermissions.includes(x));
  }
}
