<div class="login login-4 login-signin-on d-flex flex-row-fluid">
  <div class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
       style="background-image: url('assets/media/bg/bg-3.jpg');">
    <div class="position-relative h-100 w-100 d-flex flex-column justify-content-center">
      <div class="d-flex flex-center mb-15">
        <!--TODO: Big M&G Logo-->
      </div>
      <!--begin::Login Sign in form-->
      <div class="login-signin">
        <div class="mb-20 text-center">
          <h1>Welcome To M&G Value Assessments</h1>
          <!--<div class="text-muted font-weight-bold">Enter your details to login to your account</div>-->
        </div>
        <div *ngIf="response && !response?.passed"
             class="alert alert-danger"
             role="alert">
          {{ response?.message }}
        </div>
        <form class="form d-flex flex-column"
              [formGroup]="loginForm"
              (submit)="login()">
          <div class="form-group mb-5">
            <input class="form-control h-auto form-control-solid py-4 px-8"
                   type="email"
                   [formControl]="loginForm.email"
                   placeholder="Email"
                   name="email"
                   autocomplete="email"
                   [ngClass]="{'is-invalid': loginForm.email.touched && loginForm.email.invalid }" />
            <div class="mt-1"
                 *ngIf="loginForm.email.touched && loginForm.email.invalid">
              <span class="text-danger">Email is required</span>
            </div>
          </div>
          <div class="form-group mb-5">
            <input class="form-control h-auto form-control-solid py-4 px-8"
                   type="password"
                   [formControl]="loginForm.password"
                   placeholder="Password"
                   name="password"
                   autocomplete="password"
                   [ngClass]="{'is-invalid': loginForm.password.touched && loginForm.password.invalid }" />
            <div class="mt-1"
                 *ngIf="loginForm.password.touched && loginForm.password.invalid">
              <span class="text-danger">Password is required</span>
            </div>
          </div>
          <button class="btn btn-primary font-weight-bold py-4"
                  (click)="login()"
                  [disabled]="!canLogin()">
            <span *ngIf="busyLoading"
                  class="spinner spinner-white pr-9"></span>
            {{ busyLoading ? "Signing In..." : "Sign In" }}
          </button>
        </form>
      </div>
      <!--end::Login Sign in form-->
    </div>
  </div>
</div>
