import { Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { LoginCommand } from 'src/app/login/commands/login.command';
import { LoginService } from 'src/app/login/services/login.service';
import { TypedForm } from 'src/app/shared/forms/typed.form';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { LoginViewModel } from 'src/app/shared/viewmodels/login.viewmodel';
import { UserViewModel } from 'src/app/shared/viewmodels/user.viewmodel';
import { UsersViewModel } from 'src/app/shared/viewmodels/users.viewmodel';
import { GetUsersCommand } from '../../teams/users/commands/get.users.command';
import { UserService } from '../../teams/users/user.service';


@Component({
  selector: 'fit-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {
  loginForm = new LoginForm();
  busyLoading = false;
  response: LoginViewModel;

  users: Array<UserViewModel>;

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private loginService: LoginService,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.authService.logout();
    console.log("login comp called")
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

  }

  setLoginDisplay() {

    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;


    if (this.loginDisplay) {
      const userInfo = this.msalService.instance.getAllAccounts();
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS), takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          console.log(result);
          const payload = result.payload as AuthenticationResult;
          this.msalService.instance.setActiveAccount(payload.account);
        });

      this.loginDbExtend();
    }
    else {
      this.loginRequestPopup();
    }
  }

  loginRequestPopup() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            console.log(response.account)
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }
  }

  logout() {
    this.msalService.logout();
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  loginDbExtend(): void {

    this.busyLoading = true;
    const command = new LoginCommand();
    if (this.msalService.instance.getAllAccounts().length > 0) {

      var accData = this.msalService.instance.getAllAccounts();
      console.log(accData)
      command.email = accData[0].username;
      command.password = accData[0].name;

      if (accData[0].idTokenClaims?.roles != null && accData[0].idTokenClaims?.roles != undefined) {
        command.roles = accData[0].idTokenClaims?.roles?.join() ?? "";
      }
      else {
        command.roles = "";
      }

    }

    this.loginService.login(command)
      .pipe(
        finalize(() => this.busyLoading = false)
      )
      .subscribe(res => {

        this.response = res;
        if (res.passed) {
          this.authService.login(res);
          this.loadUsersData();
          this.router.navigate([this.authService.getAuthorizedPath()]);
        }
      });
  }

  loadUsersData() {
    this.busyLoading = true;
    this.users = new Array<UserViewModel>();

    const command = new GetUsersCommand();

    this.userService.getUsers(command)
      .pipe(finalize(() => this.busyLoading = false))
      .subscribe((data: UsersViewModel) => {

      });
  }

  canLogin(): boolean {
    return !this.busyLoading && this.loginForm.valid;
  }

  login(): void {

    this.busyLoading = true;

    const command = new LoginCommand();
    command.email = this.loginForm.email.typedValue;
    command.password = this.loginForm.password.typedValue;

    this.loginService.login(command)
      .pipe(
        finalize(() => this.busyLoading = false)
      )
      .subscribe(res => {
        this.response = res;
        if (res.passed) {
          this.authService.login(res);

          this.router.navigate([this.authService.getAuthorizedPath()]);
        }
      });
  }
}

class LoginForm extends TypedForm {
  email = this.addTypedControl<string>('email', [Validators.required, Validators.email]);
  password = this.addTypedControl<string>('password', [Validators.required]);
}
