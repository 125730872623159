import { Component } from '@angular/core';
import { InputEditor } from 'src/app/shared/editors/input.editor';
import { ToggleTooltipsService } from 'src/app/shared/services/toggle.tooltips.service';

@Component({
  selector: 'fit-text-editor',
  templateUrl: './input.editor.html',
  styleUrls: ['./editor.scss']
})
export class TextEditor extends InputEditor {

  constructor(toggleTooltipsService: ToggleTooltipsService) {
    super('text', toggleTooltipsService);
  }

}
