<div [ngClass]="{'card shadow-sm': hasBorder}">
  <div [ngClass]="{'card-body': hasBorder}">
    <ng-content></ng-content>
    <div class="text-center mb-4">
      <h4>
        {{message}}
      </h4>
    </div>
    <div class="d-flex justify-content-center">
      <ng-content select="[clean-slate-action]">
      </ng-content>
    </div>
  </div>
</div>