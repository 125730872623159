
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Tools-/-Angle-Grinder" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M14.8890873,14.1109127 C11.8515212,14.1109127 9.3890873,11.6484788 9.3890873,8.6109127 C9.3890873,5.57334658 11.8515212,3.1109127 14.8890873,3.1109127 C17.9266534,3.1109127 20.3890873,5.57334658 20.3890873,8.6109127 C20.3890873,11.6484788 17.9266534,14.1109127 14.8890873,14.1109127 Z M14.8890873,10.6109127 C15.9936568,10.6109127 16.8890873,9.7154822 16.8890873,8.6109127 C16.8890873,7.5063432 15.9936568,6.6109127 14.8890873,6.6109127 C13.7845178,6.6109127 12.8890873,7.5063432 12.8890873,8.6109127 C12.8890873,9.7154822 13.7845178,10.6109127 14.8890873,10.6109127 Z M14.8890873,9.6109127 C15.441372,9.6109127 15.8890873,9.16319745 15.8890873,8.6109127 C15.8890873,8.05862795 15.441372,7.6109127 14.8890873,7.6109127 C14.3368025,7.6109127 13.8890873,8.05862795 13.8890873,8.6109127 C13.8890873,9.16319745 14.3368025,9.6109127 14.8890873,9.6109127 Z" id="Combined-Shape" fill="#000000" opacity="0.3" transform="translate(14.889087, 8.610913) rotate(-405.000000) translate(-14.889087, -8.610913) "></path>
        <rect id="Combined-Shape" fill="#000000" opacity="0.3" transform="translate(9.232233, 14.974874) rotate(-45.000000) translate(-9.232233, -14.974874) " x="1.23223305" y="11.9748737" width="16" height="6" rx="3"></rect>
        <path d="M16.267767,18.0822059 C12.2738464,17.3873004 9.26776695,14.3606699 9.26776695,10.732233 C9.26776695,7.10379618 12.2738464,4.07716572 16.267767,3.38226022 L16.267767,18.0822059 Z" id="Combined-Shape" fill="#000000" transform="translate(12.767767, 10.732233) rotate(-405.000000) translate(-12.767767, -10.732233) "></path>
    </g>
</svg>
