import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GetValueAssessmentCommand } from "src/app/shared/commands/get.value.assessment.command";
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { ValueAssessmentViewModel } from "src/app/shared/viewmodels/value.assessment.viewmodel";
import { environment } from "src/environments/environment";

@Injectable()
export class CriteriaService extends RestServiceBase {

  private URL = environment.apiUrl + '/assessments';

  constructor(httpClient: HttpClient, authService: AuthenticationService) {
    super(httpClient, authService);
  }

  getValueAssessment(command: GetValueAssessmentCommand): Observable<ValueAssessmentViewModel> {
    return this.post(this.URL + `GetValueAssessment`, command);
  }
}
