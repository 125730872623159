import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-design-circle-icon',
  templateUrl: './design-circle.component.html'
})
export class DesignCircleComponent extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
