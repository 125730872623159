import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-documents-mp4-icon',
  templateUrl: './documents-mp4.component.html'
})
export class DocumentsMp4Component extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
