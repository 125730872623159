import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CancelValidationsCommand } from "src/app/shared/commands/cancel.validations.command";
import { GetRunningValidationsCommand } from "src/app/shared/commands/get.running.validations.command";
import { GetValidationResultsCommand } from "src/app/shared/commands/get.validation.results.command";
import { GetValidationStatusCommand } from "src/app/shared/commands/get.validation.status.command";
import { RunValidationsCommand } from "src/app/shared/commands/run.validations.command";
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { ValidationResultsViewModel } from "src/app/shared/viewmodels/validation.results.viewmodel";
import { ValidationsStatusViewModel } from "src/app/shared/viewmodels/validations.status.viewmodel";
import { environment } from "src/environments/environment";

@Injectable()
export class ValidationsService extends RestServiceBase {

  private URL = environment.apiUrl + '/validations';

  constructor(httpClient: HttpClient, authService: AuthenticationService) {
    super(httpClient, authService);
  }

  runValidations(command: RunValidationsCommand): Observable<ValidationsStatusViewModel> {
    return this.post<ValidationsStatusViewModel>(this.URL + `RunValidations`, command);
  }

  cancelValidations(command: CancelValidationsCommand) {
    return this.post(this.URL + `CancelValidations`, command);
  }

  getValidationResults(command: GetValidationResultsCommand): Observable<ValidationResultsViewModel> {
    return this.post(this.URL + `GetValidationResults`, command);
  }

  getRunningValidations(command: GetRunningValidationsCommand): Observable<ValidationsStatusViewModel> {
    return this.post<ValidationsStatusViewModel>(this.URL + `GetRunningValidations`, command);
  }

  getValidationsStatus(command: GetValidationStatusCommand): Observable<ValidationsStatusViewModel> {
    return this.post<ValidationsStatusViewModel>(this.URL + `GetValidationsStatus`, command);
  }
}
