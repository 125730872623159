import { FormGroup } from "@angular/forms";
import { TypedForm } from "src/app/shared/forms/typed.form";
import { ClassificationsForm } from "src/app/surveys/fundrange-survey/forms/classifications.form";
import { IndicatorsForm } from "src/app/surveys/fundrange-survey/forms/indicators.form";
import { StrategyForm } from "src/app/surveys/fundrange-survey/forms/strategy.form";
import { FundRangeFundSurveyEditorViewModel } from "src/app/surveys/fundrange-survey/fundrange-fund-survey/viewmodels/fund.range.fund.survey.editor.viewmodel";

export class FundRangeFundSurveyForm extends TypedForm{
  indicators: IndicatorsForm;
  strategy: StrategyForm;
  classifications: ClassificationsForm;

  viewModel: FundRangeFundSurveyEditorViewModel;

  constructor(isVATeam: boolean = false) {
    super();
    this.indicators = this.addTypedForm<IndicatorsForm>('indicators', new IndicatorsForm(isVATeam));
    this.strategy = this.addTypedForm<StrategyForm>('strategy', new StrategyForm(isVATeam));
    this.classifications = this.addTypedForm<ClassificationsForm>('classifications', new ClassificationsForm(isVATeam));
  }

  populate(viewmodel: FundRangeFundSurveyEditorViewModel) {
    this.viewModel = viewmodel;

    this.indicators.populate(viewmodel)
    this.strategy.populate(viewmodel)
    this.classifications.populate(viewmodel)
  }

  getViewModel(): FundRangeFundSurveyEditorViewModel {
    const viewModel = Object.assign(new FundRangeFundSurveyEditorViewModel(), this.viewModel);

    this.indicators.getViewModel(viewModel);
    this.strategy.getViewModel(viewModel);
    this.classifications.getViewModel(viewModel);
    
    return viewModel;
  }
}
