import { Component, Input } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-rectangle-icon',
  templateUrl: './rectangle.component.html'
})
export class RectangleComponent extends IconBase {
  
  constructor(config?: IconConfig) {
    super(config);

  }
}
