export class NavigationItem {

  constructor(name: string, route?: string, icon?: string, enabled?: boolean) {
    this.name = name;
    this.route = route;
    this.icon = icon;
    this.enabled = enabled;
  }

  name: string;
  route: string;
  icon: string;
  enabled = true;
  childItems = new Array<NavigationItem>();
}
