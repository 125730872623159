import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fit-notfound-component',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})

export class NotfoundComponent implements OnInit {

  public message: string;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.message = `Page "${this.router.url}" can't be found.`;
  }
}
