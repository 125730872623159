
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Tools-/-Shovel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M4.6109127,7.1109127 L14.6109127,7.1109127 L14.6109127,9.1109127 L4.6109127,9.1109127 L2.46446609,11.2573593 C2.3706979,11.3511275 2.24352095,11.4038059 2.1109127,11.4038059 C1.83477033,11.4038059 1.6109127,11.1799483 1.6109127,10.9038059 L1.6109127,5.31801948 C1.6109127,5.18541124 1.66359112,5.05823428 1.75735931,4.96446609 C1.95262146,4.76920395 2.26920395,4.76920395 2.46446609,4.96446609 L4.6109127,7.1109127 Z" id="Combined-Shape" fill="#000000" opacity="0.3" transform="translate(8.110913, 8.110913) rotate(-315.000000) translate(-8.110913, -8.110913) "></path>
        <path d="M16.9497475,9.87867966 L19.7749023,13.1384737 C20.8921007,14.4275487 21.3501517,16.1609297 21.0156115,17.8336309 L20.6160255,19.8315609 C20.5368532,20.2274222 20.2274222,20.5368532 19.8315609,20.6160255 L17.8336309,21.0156115 C16.1609297,21.3501517 14.4275487,20.8921007 13.1384737,19.7749023 L9.87867966,16.9497475 L16.9497475,9.87867966 Z M13.5451169,16.0236544 C12.7817306,15.3777121 11.8128172,16.5227916 12.5762035,17.1687338 L14.8743005,19.1132774 C15.6376868,19.7592197 16.6066002,18.6141403 15.8432139,17.968198 L13.5451169,16.0236544 Z" id="Combined-Shape" fill="#000000"></path>
    </g>
</svg>
