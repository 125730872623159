import { AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn } from "@angular/forms";

export class TypedFormControl<T> extends FormControl {

  constructor(...args: any[]) {
    super(...args);
  }

  get hasValue(): boolean {
    return !(this.value === null || this.value === undefined || this.value === '');
  }

  get typedValue(): T {
    return this.value as T;
  }

  setTypedValue(value: T, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }) {
    this.setValue(value, options);
  }
}
