import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientID,
    authority: 'https://login.microsoftonline.com/' + environment.tenantID,
    //authority: 'https://login.microsoftonline.com/prudentialplc.onmicrosoft.com/' + environment.tenantID,

    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.redirectLogoutUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        //console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
}
export const protectedResources = {
  todoListApi: {
    endpoint: "https://localhost:44371/api/TodoList",
    scopes: ["user.read", 'openid', 'profile'],
  },
  graphApi: {
    endpoint: "https://graph.microsoft.com/v1.0/me/memberOf",
    scopes: ["User.Read", "GroupMember.Read.All"]
  }
}
export const loginRequest = {
  scopes: []
};
