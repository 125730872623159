import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface ComponentCanDeactivate {
  canDeactivate: () => Observable<boolean | null>;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private readonly location: Location) { }

  canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot) {
    return component.canDeactivate().pipe(
      switchMap((confirmed: boolean | null) => {
        // TODO: Remove location.go call when https://github.com/angular/angular/issues/13586 is fixed.
        if (confirmed === null) {
          this.location.go(currentRoute.url.toString());
        }
        return of(!!confirmed);
      })
    );
  }

}
