import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { GetQualitySurveyCommand } from 'src/app/surveys/quality-survey/commands/get.quality.survey.command';
import { DebugDelay } from 'src/app/shared/debug/debug.delay';
import { ConfirmModalComponent, ConfirmModalConfig } from 'src/app/shared/modals/confirm-modal/confirm.modal.component';
import { ModalButton } from 'src/app/shared/modals/modal.button';
import { ModalResult } from 'src/app/shared/modals/modal.result';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { EditState } from 'src/app/shared/types/edit.state';
import { SaveQualitySurveyCommand } from 'src/app/surveys/quality-survey/commands/save.quality.survey.command';
import { SubmitQualitySurveyCommand } from 'src/app/surveys/quality-survey/commands/submit.quality.survey.command';
import { QualitySurveyForm } from 'src/app/surveys/quality-survey/forms/quality.survey.form';
import { QualitySurveysService } from 'src/app/surveys/quality-survey/quality.surveys.service';
import { QualitySurveyEditorViewModel } from 'src/app/surveys/quality-survey/viewmodels/quality.survey.editor.viewmodel';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fit-quality-survey',
  templateUrl: './quality.survey.component.html',
  styleUrls: ['./quality.survey.component.scss']
})
export class QualitySurveyComponent implements OnDestroy {

  busyLoading = false;
  busySaving = false;
  busySubmitting = false;
  busyUploading = false;

  viewModel: QualitySurveyEditorViewModel;

  destroyed = new Subject();
  surveyId: string;

  form: QualitySurveyForm;

  constructor(private surveyService: QualitySurveysService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private title: Title,
    private location: Location,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: Document) {
  }

  get canRefresh() {
    return !this.busyLoading;
  }

  get canSave() {
    return !this.busyLoading && !this.busySaving && !this.busySubmitting && (this.form?.dirty || this.form.files.some(v => v.editState != EditState.unchanged) || this.form.files.some(v => v.editState != EditState.unchanged));
  }

  get canSubmit() {
    return !this.busyLoading && !this.busySaving && !this.busySubmitting && this.form?.valid && this.isExceededValidate();
  }

  get canReset() {
    return !this.busyLoading && !this.busySaving && !this.busySubmitting && (this.form?.dirty || this.form.files.some(v => v.editState != EditState.unchanged) || this.form.evidenceFiles.some(v => v.editState != EditState.unchanged));
  }

  ngOnInit() {
    this.title.setTitle("Capture Survey");
    this.surveyId = this.route.snapshot.params.surveyId;
    this.load();
  }

  load() {
    this.busyLoading = true;

    const command = new GetQualitySurveyCommand(this.surveyId, null);

    this.surveyService.getQualitySurvey(command)
      .pipe(delay(DebugDelay.value),
        finalize(() => this.busyLoading = false))
      .subscribe((data) => {
        this.viewModel = new QualitySurveyEditorViewModel(data);
        this.form = new QualitySurveyForm(this.viewModel);
      });
  }

  isExceededValidate() : boolean {
    return !this.form.kpiOrSlaEvidence.typedValue
      || (this.form.kpiOrSlaEvidence.typedValue && this.form.evidenceFiles.length > 0 && this.form.evidenceFiles.every(v => v.editState != EditState.deleted));
  }

  saveSurvey() {
    this.busySaving = true;

    const command = new SaveQualitySurveyCommand(this.form.getViewModel());

    this.surveyService.saveSurvey(command)
      .pipe(delay(DebugDelay.value),
        finalize(() => this.busySaving = false))
      .subscribe(() => {
        this.load();
      });
  }

  submitSurvey() {
    this.busySubmitting = true;

    event.stopPropagation();

    const confirmModal = this.modalService.createInstance(ConfirmModalComponent, {
      title: 'Confirm Survey Submission',
      size: 'sm',
      message: `Are you sure you want to submit '${this.viewModel?.subService.name}'?`,
      primaryButton: new ModalButton('Submit', 'btn-light-primary', ModalResult.primary, 'Submitting')
    } as ConfirmModalConfig);

    confirmModal.result.pipe(
      tap(r => r !== ModalResult.primary ? confirmModal.hide() : {}),
      filter(r => r === ModalResult.primary),
      switchMap(() => {
        const command = new SubmitQualitySurveyCommand(this.form.getViewModel());
        return this.surveyService.submitSurvey(command)
      })
    )
      .pipe(
        delay(DebugDelay.value),
        finalize(() => {
          confirmModal.hide(),
            this.busySubmitting = false;
        }))
      .subscribe(() => {
        this.toastr.success('Your survey has been submitted');
        this.goBack()
      });
  }

  resetSurvey() {
    this.form.reset();
    this.form.populate(this.viewModel);
    this.load();
  }


  goBack(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.destroyed.next();
  }
}
