import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GetImportStatusCommand } from "src/app/shared/commands/get.import.status.command";
import { GetRunningImportCommand } from "src/app/shared/commands/get.running.import.command";
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { ImportStatusViewModel } from "src/app/shared/viewmodels/import.status.viewmodel";
import { environment } from "src/environments/environment";

@Injectable()
export class ImportsService extends RestServiceBase {

  private URL = environment.apiUrl + '/import';

  constructor(httpClient: HttpClient, authService: AuthenticationService) {
    super(httpClient, authService);
  }

  getRunningImport(command: GetRunningImportCommand): Observable<ImportStatusViewModel> {
    return this.post<ImportStatusViewModel>(this.URL + `GetRunningImport`, command);
  }

  getImportStatus(command: GetImportStatusCommand): Observable<ImportStatusViewModel> {
    return this.post<ImportStatusViewModel>(this.URL + `GetImportStatus`, command);
  }
}
