import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map, finalize, tap, filter, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { ModalResult } from 'src/app/shared/modals/modal.result';
import { ConfirmModalComponent, ConfirmModalConfig } from 'src/app/shared/modals/confirm-modal/confirm.modal.component';
import { ModalButton } from 'src/app/shared/modals/modal.button';
import { ModalConfig } from 'src/app/shared/modals/modal.config.interface';

@Injectable()
export class ConflictHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private location: Location,
    private toastr: ToastrService,
    private modalService: ModalService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((res) => {
        if (res.status === 409) {
          const getErrorMessage = (): Promise<string> => {
            return new Promise((resolve, _) => {
              // We get an object back if the expected response type was 'blob'
              // Now we need to parse the blob as a string to get the correct error message from the server
              if (res.error) {
                if (typeof (res.error) === 'object') {
                  const reader = new FileReader();
                  reader.addEventListener('loadend', (e: ProgressEvent<FileReader>) => {
                    const text = (e.target as any).result;
                    resolve(text);
                  });
                  reader.readAsText(res.error);
                } else {
                  resolve(res.error);
                }
              } else {
                resolve('Internal server error occurred');
              }
            });
          };

          getErrorMessage().then(errMsg => {
            const confirmModal = this.modalService.createInstance(ConfirmModalComponent, {
              title: 'Action Error',
              size: 'sm',
              message: errMsg,
              secondaryButton: null,
              primaryButton: new ModalButton('Refresh', 'btn-danger', ModalResult.primary, 'Refreshing')
            } as ConfirmModalConfig);

            confirmModal.result.pipe(
              tap(r => r !== ModalResult.primary ? confirmModal.hide() : {}),
              filter(r => r === ModalResult.primary)
            )
              .pipe(finalize(() => confirmModal.hide()))
              .subscribe(() => window.location.reload());
          });

        }
        return throwError(res);

      })
    );
  }

}
