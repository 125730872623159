import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'surveyAgeColourClass'
})
export class SurveyAgeColourClassPipe implements PipeTransform {

  transform(value: number): string {
    if (value < 7)
      return "label-success";
    else if (value < 14)
      return "label-warning";
    else
      return "label-danger";
  }
}
