<!--begin::Aside-->
<div class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
     #kt_aside>

  <!--begin::Brand-->
  <div class="brand flex-column-auto">
    <!--begin::Logo-->
    <a *ngIf="menuExpanded"
       routerLink="/"
       class="brand-logo h4 mb-0 mt-10">
      <img src="assets/img/logo.png" width="163" height="71" />
      <!--<span class="mt-3 ml-3">fit</span>-->
    </a>

    <!--end::Logo-->
    <!--begin::Toggle-->
    <button class="brand-toggle btn btn-sm px-0"
            [ngClass]="{'active': !menuExpanded}"
            (click)="toggleMenuExpanded()">
      <span class="svg-icon svg-icon-light svg-icon-xl">
        <fit-icon icon="navigation-angle-double-left"></fit-icon>
      </span>
    </button>
    <!--end::Toolbar-->
  </div>
  <!--end::Brand-->
  <!--begin::Aside Menu-->
  <div class="aside-menu-wrapper flex-column-fluid"
       id="kt_aside_menu_wrapper">
    <!--begin::Menu Container-->
    <div class="aside-menu my-4"
         data-menu-vertical="1"
         data-menu-scroll="1"
         data-menu-dropdown-timeout="500">
      <!--begin::Menu Nav-->
      <ul class="menu-nav">

        <li *ngIf="canViewSurveys" class="menu-section">
          <h4 class="menu-text">Surveys</h4>
          <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>

        <li *ngIf="userPermissions?.includes(PermissionType.viewQualitySurveyDashboard)"
            class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('surveys/quality',false)">
          <a routerLink="surveys/quality"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="text-bullet-list"></fit-icon>
            </span>
            <span class="menu-text">Quality Of Service</span>
          </a>
        </li>

        <li *ngIf="userPermissions?.includes(PermissionType.viewFundRangeSurveyDashboard)"
            class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('surveys/fundrange',false)">
          <a routerLink="surveys/fundrange"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="text-bullet-list"></fit-icon>
            </span>
            <span class="menu-text">Fund Ranges</span>
          </a>
        </li>

        <li *ngIf="userPermissions?.includes(PermissionType.viewPricingSurveyDashboard)"
            class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('surveys/pricing',false)">
          <a routerLink="surveys/pricing"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="text-bullet-list"></fit-icon>
            </span>
            <span class="menu-text">Pricing</span>
          </a>
        </li>

        <li *ngIf="canViewAssessments" class="menu-section">
          <h4 class="menu-text">Assessments</h4>
          <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>

        <li *ngIf="userPermissions?.includes(PermissionType.viewValueAssessments)"
            class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('/assessments', false)">
          <a routerLink="/assessments"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="shopping-calculator"></fit-icon>
            </span>
            <span class="menu-text">Value Assessments</span>
          </a>
        </li>

        <li *ngIf="canViewReference" class="menu-section">
          <h4 class="menu-text">Reference</h4>
          <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>

        <li *ngIf="userPermissions?.includes(PermissionType.viewClassifications)"
            class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('/reference/classifications', false)">
          <a routerLink="/reference/classifications"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="design-flatten"></fit-icon>
            </span>
            <span class="menu-text">Classifications</span>
          </a>
        </li>

        <li *ngIf="userPermissions?.includes(PermissionType.viewServices)"
            class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('/reference/services', false)">
          <a routerLink="/reference/services"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="design-difference"></fit-icon>
            </span>
            <span class="menu-text">Services</span>
          </a>
        </li>

        <li *ngIf="userPermissions?.includes(PermissionType.viewFundRanges)"
            class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('/reference/fund-ranges', false)">
          <a routerLink="/reference/fund-ranges"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="navigation-arrows-h"></fit-icon>
            </span>
            <span class="menu-text">Fund Ranges</span>
          </a>
        </li>

        <li *ngIf="userPermissions?.includes(PermissionType.viewPricingTeam)"
            class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('/reference/pricing-team-users', false)">
          <a routerLink="/reference/pricing-team-users"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="shopping-wallet"></fit-icon>
            </span>
            <span class="menu-text">Pricing Team</span>
          </a>
        </li>

        <li class="menu-section">
          <h4 class="menu-text">Teams</h4>
          <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>

        <li *ngIf="userPermissions?.includes(PermissionType.viewUsers)"
            class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('/teams/users',false)">
          <a routerLink="/teams/users"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="communication-group"></fit-icon>
            </span>
            <span class="menu-text">Users</span>
          </a>
        </li>

        <li *ngIf="isAdministrator || userPermissions?.includes(PermissionType.viewGroups)"
            class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('/teams/groups',false)">
          <a routerLink="/teams/groups"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="communication-shield-user"></fit-icon>
            </span>
            <span class="menu-text">Groups</span>
          </a>
        </li>

        <li class="menu-item"
            aria-haspopup="true"
            [class.menu-item-active]="router.isActive('/teams/profile',false)">
          <a routerLink="/teams/profile/personalInfo"
             class="menu-link">
            <span class="svg-icon menu-icon">
              <fit-icon icon="general-settings-1"></fit-icon>
            </span>
            <span class="menu-text">Preferences</span>
          </a>
        </li>

      </ul>
      <!--end::Menu Nav-->
    </div>
   
    <!--end::Menu Container-->
  </div>
  <!--end::Aside Menu-->
</div>
<!--end::Aside-->
