import { Validators } from "@angular/forms";
import { TypedForm } from "src/app/shared/forms/typed.form";
import { FundRangeFundSurveyEditorViewModel } from "src/app/surveys/fundrange-survey/fundrange-fund-survey/viewmodels/fund.range.fund.survey.editor.viewmodel";

export class IndicatorsForm extends TypedForm  {
  isTotalReturn = this.addTypedControl<boolean>('isTotalReturn', [], false);
  isCapitalGrowth = this.addTypedControl<boolean>('isCapitalGrowth', [], false);
  isYieldIncome = this.addTypedControl<boolean>('isYieldIncome', [], false);
  isYieldIncomeGrowingHigh = this.addTypedControl<boolean>('isYieldIncomeGrowingHigh', [], false);
  isYieldIncomeRelative = this.addTypedControl<boolean>('isYieldIncomeRelative', [], false);
  isTarget = this.addTypedControl<boolean>('isTarget', [], false);
  isTargetReturn = this.addTypedControl<boolean>('isTargetReturn', [], false);
  isInflationProtection = this.addTypedControl<boolean>('isInflationProtection', [], false);
  isVolatilityOutcome = this.addTypedControl<boolean>('isVolatilityOutcome', [], false);
  isTracker = this.addTypedControl<boolean>('isTracker', [], false);
  isLiquidityMatching = this.addTypedControl<boolean>('isLiquidityMatching', [], false);
  isPositiveImpact = this.addTypedControl<boolean>('isPositiveImpact', [], false);
  isLeverage = this.addTypedControl<boolean>('isLeverage', [], false);
  isESG = this.addTypedControl<boolean>('isESG', [], false);
  isCharities = this.addTypedControl<boolean>('isCharities', [], false);

  constructor(isVATeam: boolean = false) {
    super();
    if (isVATeam) {
      Object.keys(this.controls).forEach(key => {
        this.controls[key].setValidators([Validators.required]);
        this.controls[key].updateValueAndValidity();
      });
    }
  }

  populate(viewmodel: FundRangeFundSurveyEditorViewModel) {
    this.isTotalReturn.setTypedValue(viewmodel.isTotalReturn);
    this.isCapitalGrowth.setTypedValue(viewmodel.isCapitalGrowth);
    this.isYieldIncome.setTypedValue(viewmodel.isYieldIncome);
    this.isYieldIncomeGrowingHigh.setTypedValue(viewmodel.isYieldIncomeGrowingHigh);
    this.isYieldIncomeRelative.setTypedValue(viewmodel.isYieldIncomeRelative);
    this.isTarget.setTypedValue(viewmodel.isTarget);
    this.isTargetReturn.setTypedValue(viewmodel.isTargetReturn);
    this.isInflationProtection.setTypedValue(viewmodel.isInflationProtection);
    this.isVolatilityOutcome.setTypedValue(viewmodel.isVolatilityOutcome);
    this.isTracker.setTypedValue(viewmodel.isTracker);
    this.isLiquidityMatching.setTypedValue(viewmodel.isLiquidityMatching);
    this.isPositiveImpact.setTypedValue(viewmodel.isPositiveImpact);
    this.isLeverage.setTypedValue(viewmodel.isLeverage);
    this.isESG.setTypedValue(viewmodel.isESG);
    this.isCharities.setTypedValue(viewmodel.isCharities);
  }

  getViewModel(viewModel: FundRangeFundSurveyEditorViewModel): FundRangeFundSurveyEditorViewModel {
    viewModel.isTotalReturn = this.isTotalReturn.typedValue;
    viewModel.isCapitalGrowth = this.isCapitalGrowth.typedValue;
    viewModel.isYieldIncome = this.isYieldIncome.typedValue;
    viewModel.isYieldIncomeGrowingHigh = this.isYieldIncomeGrowingHigh.typedValue;
    viewModel.isYieldIncomeRelative = this.isYieldIncomeRelative.typedValue;
    viewModel.isTarget = this.isTarget.typedValue;
    viewModel.isTargetReturn = this.isTargetReturn.typedValue;
    viewModel.isInflationProtection = this.isInflationProtection.typedValue;
    viewModel.isVolatilityOutcome = this.isVolatilityOutcome.typedValue;
    viewModel.isTracker = this.isTracker.typedValue;
    viewModel.isLiquidityMatching = this.isLiquidityMatching.typedValue;
    viewModel.isPositiveImpact = this.isPositiveImpact.typedValue;
    viewModel.isLeverage = this.isLeverage.typedValue;
    viewModel.isESG = this.isESG.typedValue;
    viewModel.isCharities = this.isCharities.typedValue;

    return viewModel;
  }
}
