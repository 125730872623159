
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Communication-/-Dial-numbers" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <rect id="Rectangle-7" fill="#000000" opacity="0.3" x="4" y="4" width="4" height="4" rx="2"></rect>
        <rect id="Rectangle-7-Copy-3" fill="#000000" x="4" y="10" width="4" height="4" rx="2"></rect>
        <rect id="Rectangle-7-Copy" fill="#000000" x="10" y="4" width="4" height="4" rx="2"></rect>
        <rect id="Rectangle-7-Copy-4" fill="#000000" x="10" y="10" width="4" height="4" rx="2"></rect>
        <rect id="Rectangle-7-Copy-2" fill="#000000" x="16" y="4" width="4" height="4" rx="2"></rect>
        <rect id="Rectangle-7-Copy-5" fill="#000000" x="16" y="10" width="4" height="4" rx="2"></rect>
        <rect id="Rectangle-7-Copy-8" fill="#000000" x="4" y="16" width="4" height="4" rx="2"></rect>
        <rect id="Rectangle-7-Copy-7" fill="#000000" x="10" y="16" width="4" height="4" rx="2"></rect>
        <rect id="Rectangle-7-Copy-6" fill="#000000" x="16" y="16" width="4" height="4" rx="2"></rect>
    </g>
</svg>
