
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Design-/-Picker" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M10.232233,10.232233 L13.767767,13.767767 L8.46446609,19.0710678 C7.48815536,20.0473785 5.90524292,20.0473785 4.92893219,19.0710678 C3.95262146,18.0947571 3.95262146,16.5118446 4.92893219,15.5355339 L10.232233,10.232233 Z" id="Rectangle-42" fill="#000000"></path>
        <path d="M13.767767,6.69669914 L15.5355339,4.92893219 C16.5118446,3.95262146 18.0947571,3.95262146 19.0710678,4.92893219 C20.0473785,5.90524292 20.0473785,7.48815536 19.0710678,8.46446609 L17.3033009,10.232233 L18.363961,11.2928932 C18.9497475,11.8786797 18.9497475,12.8284271 18.363961,13.4142136 C17.7781746,14 16.8284271,14 16.2426407,13.4142136 L10.5857864,7.75735931 C10,7.17157288 10,6.22182541 10.5857864,5.63603897 C11.1715729,5.05025253 12.1213203,5.05025253 12.7071068,5.63603897 L13.767767,6.69669914 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
    </g>
</svg>
