import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-layout-layout-top-panel-2-icon',
  templateUrl: './layout-layout-top-panel-2.component.html'
})
export class LayoutLayoutTopPanel2Component extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
