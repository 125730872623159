<!--<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"/>
      <path d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z" fill="#000000" opacity="0.3"/>
      <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000"/>
  </g>
</svg>-->
<!--<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
  <path d="m192 32v-32c-44.832031 0-83.167969 32.007812-91.183594 76.113281l-18.167968 99.886719h-42.648438v32h36.832031l-17.128906 94.167969c-5.246094 28.878906-30.351563 49.832031-59.703125 49.832031v32c44.832031 0 83.167969-32.007812 91.183594-76.113281l18.167968-99.886719h34.648438v-32h-28.832031l17.128906-94.167969c5.246094-28.878906 30.351563-49.832031 59.703125-49.832031zm0 0" />
  <path d="m208 96-12.800781-9.601562c-1.109375 1.480468-27.199219 37.058593-27.199219 105.601562s26.089844 104.121094 27.199219 105.601562l25.59375-19.203124c-.199219-.269532-20.792969-29.328126-20.792969-86.398438 0-57.054688 20.664062-86.207031 20.871094-86.503906zm0 0" />
  <path d="m331.199219 105.601562c.207031.269532 20.800781 29.328126 20.800781 86.398438 0 57.054688-20.664062 86.207031-20.871094 86.503906l12.871094 9.496094 12.800781 9.601562c1.121094-1.488281 27.199219-37.058593 27.199219-105.601562s-26.089844-104.121094-27.199219-105.601562zm0 0" />
  <path d="m291.0625 142.585938-19.0625 26.214843-19.0625-26.214843-25.875 18.828124 25.152344 34.585938-25.152344 34.585938 25.875 18.828124 19.0625-26.214843 19.0625 26.214843 25.875-18.828124-25.152344-34.585938 25.152344-34.585938zm0 0" />
</svg>-->
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="56" height="56">

  <g transform="matrix(0.109375 0 0 0.109375 0 0)">
    <g transform="matrix(1.333333 0 0 1.333333 0 0)">
    </g>
    <path transform="matrix(1.333333 0 0 1.333333 0 0)" d="M192 32L192 0C 147.168 0 108.832 32.00781 100.8164 76.11328L100.8164 76.11328L82.64844 176L40 176L40 208L76.83203 208L59.70313 302.168C 54.45703 331.0469 29.35156 352 0 352L0 352L0 384C 44.83203 384 83.16797 351.9922 91.18359 307.8867L91.18359 307.8867L109.3516 208L144 208L144 176L115.168 176L132.2969 81.83203C 137.543 52.95313 162.6484 32 192 32z" stroke="none" fill="#000000" fill-rule="nonzero" />
    <g transform="matrix(1.333333 0 0 1.333333 0 0)">
    </g>
    <path transform="matrix(1.333333 0 0 1.333333 0 0)" d="M208 96L195.1992 86.39844C 194.0898 87.87891 168 123.457 168 192C 168 260.543 194.0898 296.1211 195.1992 297.6016L195.1992 297.6016L220.793 278.3984C 220.5938 278.1289 200 249.0703 200 192C 200 134.9453 220.6641 105.793 220.8711 105.4961L220.8711 105.4961L208 96z" stroke="none" fill="#000000" fill-rule="nonzero" />
    <g transform="matrix(1.333333 0 0 1.333333 0 0)">
    </g>
    <path transform="matrix(1.333333 0 0 1.333333 0 0)" d="M331.1992 105.6016C 331.4063 105.8711 352 134.9297 352 192C 352 249.0547 331.3359 278.207 331.1289 278.5039L331.1289 278.5039L344 288L356.8008 297.6016C 357.9219 296.1133 384 260.543 384 192C 384 123.457 357.9102 87.87891 356.8008 86.39844L356.8008 86.39844L331.1992 105.6016z" stroke="none" fill="#000000" fill-rule="nonzero" />
    <g transform="matrix(1.333333 0 0 1.333333 0 0)">
    </g>
    <path transform="matrix(1.333333 0 0 1.333333 0 0)" d="M291.0625 142.5859L272 168.8008L252.9375 142.5859L227.0625 161.4141L252.2148 196L227.0625 230.5859L252.9375 249.4141L272 223.1992L291.0625 249.4141L316.9375 230.5859L291.7852 196L316.9375 161.4141L291.0625 142.5859z" stroke="none" fill="#000000" fill-rule="nonzero" />
  </g>
</svg>
