import { NameValueViewModel } from 'src/app/shared/viewmodels/name.value.viewmodel';
import { UserGroupViewModel } from 'src/app/shared/viewmodels/user.group.viewmodel';
import { ViewModel } from 'src/app/shared/viewmodels/viewmodel';

export class UserViewModel extends ViewModel {

  isAdministrator = false;
  firstName: string;
  lastName: string;
  email?: string;
  password: string;
  mobileNumberCode: string;
  mobileNumber: string;
  landlineNumberCode: string;
  landlineNumber: string;

  groups: UserGroupViewModel[];
  permissions: NameValueViewModel<number>[];

  constructor(user?: UserViewModel) {
    super(user);

    this.instanceId = user?.instanceId;
    this.firstName = user?.firstName;
    this.lastName = user?.lastName;
    this.email = user?.email;
    this.password = user?.password;
    this.mobileNumberCode = user?.mobileNumberCode;
    this.mobileNumber = user?.mobileNumber;
    this.landlineNumberCode = user?.landlineNumberCode;
    this.landlineNumber = user?.landlineNumber;
    this.rowVersion = user?.rowVersion;

    this.groups = user?.groups?.map(u => new UserGroupViewModel(u));
    this.permissions = user?.permissions.map(p => new NameValueViewModel(p));
  }

  toString() {
    return `${this.firstName} ${this.lastName}`;
  }

  get groupNames(): string[] {
    return this.groups.map(g => g.name);
  }
}
