import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetQualitySurveyCommand } from 'src/app/surveys/quality-survey/commands/get.quality.survey.command';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RestServiceBase } from 'src/app/shared/services/rest.service.base';
import { EditState } from 'src/app/shared/types/edit.state';
import { GetQualitySurveysCommand } from 'src/app/surveys/quality-survey/commands/get.quality.surveys.command';
import { SaveQualitySurveyCommand } from 'src/app/surveys/quality-survey/commands/save.quality.survey.command';
import { SubmitQualitySurveyCommand } from 'src/app/surveys/quality-survey/commands/submit.quality.survey.command';
import { QualitySurveyEditorViewModel } from 'src/app/surveys/quality-survey/viewmodels/quality.survey.editor.viewmodel';
import { QualitySurveysViewModel } from 'src/app/surveys/quality-survey/viewmodels/quality.surveys.viewmodel';
import { environment } from 'src/environments/environment';

@Injectable()
export class QualitySurveysService extends RestServiceBase {
  private URL = environment.apiUrl +'/qos';

  constructor(httpClient: HttpClient,
    authService: AuthenticationService,
    private router: Router) {
    super(httpClient, authService);
  }

  getQualitySurveysForUser(command: GetQualitySurveysCommand): Observable<QualitySurveysViewModel> {
    return this.post<QualitySurveysViewModel>(this.URL + 'GetQualitySurveysForUser', command)
  }

  getQualitySurvey(command: GetQualitySurveyCommand): Observable<QualitySurveyEditorViewModel> {
    return this.post<QualitySurveyEditorViewModel>(this.URL + 'GetQualitySurvey', command)
  }

  saveSurvey(command: SaveQualitySurveyCommand) {
    const form = new FormData();
    form.append('command', JSON.stringify(command));
    command.survey.attachments.forEach(a => {
      if (a?.editState == EditState.added) {
        form.append(a.name, a.file);
      }
    });
    command.survey.evidenceAttachments.forEach(e => {
      if (e?.editState == EditState.added) {
        form.append(e.name, e.file);
      }
    });

    return this.post(this.URL + `SaveQualitySurvey`, form);
  }

  submitSurvey(command: SubmitQualitySurveyCommand) {
    const form = new FormData();
    form.append('command', JSON.stringify(command));
    command.survey.attachments.forEach(a => {
      if (a?.editState == EditState.added) {
        form.append(a.name, a.file);
      }
    });
    command.survey.evidenceAttachments.forEach(e => {
      if (e?.editState == EditState.added) {
        form.append(e.name, e.file);
      }
    });

    return this.post(this.URL + 'SubmitQualitySurvey', form);
  }

}
