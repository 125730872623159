<fit-subheader title="Pricing Surveys" [isFullWidth]="false">
  <div class="my-2 ml-auto">
    <fit-icon-button caption="Refresh"
                     (onClick)="load()"
                     icon="general-update"
                     [isDisabled]="!canRefresh"
                     [busyLoading]="busyLoading">
    </fit-icon-button>
  </div>
</fit-subheader>

<div class="container d-flex flex-column h-100">
  <ng-container *ngIf="!busyLoading else loading">
    <ng-container *ngTemplateOutlet="manageView"></ng-container>
  </ng-container>
</div>

<ng-template #manageView>

  <div class="card card-custom mb-5">
    <div class="card-body py-3">
      <form [formGroup]="filterForm" (keydown.enter)="submitSearch($event)">
        <div class="row justify-content-start">
          <div class="col-3">
            <div class="d-flex my-2">
              <p class="font-weight-bold mb-0">Fund</p>
            </div>
            <fit-text-editor [control]="filterForm.fund"
                             placeholder="Fund">
            </fit-text-editor>
          </div>
          <div class="col-3">
            <div class="d-flex my-2">
              <p class="font-weight-bold mb-0">Matched Fund</p>
            </div>
            <fit-text-editor [control]="filterForm.matchFund"
                             placeholder="Comparable Fund">
            </fit-text-editor>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="btn btn-light-primary btn-sm mr-3"
                    (click)="runSearch()">
              <span class="svg-icon">
                <fit-icon icon="general-search">
                </fit-icon>
              </span>
              Search
            </button>
            <button class="btn btn-sm"
                    (click)="clearSearch()">
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="table-container">
    <div class="card col-12 px-0 table-fixed-header">
      <table class="table table-rounded table-hover p-4 mb-0">
        <thead>
          <tr>
            <th scope="col">
              <fit-sortheader header="Value Assessment" tag="ValueAssessment" [sortOrder]="sortOrders.get('ValueAssessment')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
            </th>
            <th scope="col" class="py-3">
              <fit-sortheader header="Fund Code" tag="FundCode" [sortOrder]="sortOrders.get('FundCode')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
            </th>
            <th scope="col" class="py-3">
              <fit-sortheader header="Fund" tag="FundName" [sortOrder]="sortOrders.get('FundName')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
            </th>
            <th scope="col" class="py-3">
              <fit-sortheader header="Match Fund Code" tag="MatchFundCode" [sortOrder]="sortOrders.get('MatchFundCode')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
            </th>
            <th scope="col" class="py-3">
              <fit-sortheader header="Match Fund" tag="MatchFundName" [sortOrder]="sortOrders.get('MatchFundName')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
            </th>
            <th scope="col" class="py-3">
              <fit-sortheader header="Status" tag="Status" [sortOrder]="sortOrders.get('Status')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
            </th>
            <th scope="col" class="py-3">
              <fit-sortheader header="Date Created" tag="CreatedDate" [sortOrder]="sortOrders.get('CreatedDate')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
            </th>
            <th scope="col" class="py-3">
              <fit-sortheader header="Age" tag="Age" [sortOrder]="sortOrders.get('Age')" (sortOrderToggle)="setSortOrder($event)"></fit-sortheader>
            </th>
            <th scope="col" class="is-narrow text-right">Actions</th>
          </tr>
        </thead>
        <ng-container *ngIf="!viewmodel?.surveys || viewmodel?.surveys.length === 0">
          <tr>
            <td colspan="9">
              <div class="container my-5">
                <fit-clean-slate [hasBorder]="false" message="There are no surveys assigned to you or your filter returned no results.">
                  <div class="d-flex justify-content-center">
                    <img src="assets/img/clean-slate/package-slate.svg"
                         width="120"
                         class="mb-5" />
                  </div>
                </fit-clean-slate>
              </div>
            </td>
          </tr>
        </ng-container>
        <tbody>
          <ng-container>
            <tr *ngFor="let item of viewmodel?.surveys; let i = index">
              <td scope="col">
                {{item.valueAssessment}}
              </td>
              <td>
                {{item.fund?.value}}
              </td>
              <td>
                {{item.fund?.name}}
              </td>
              <td>
                {{item.matchFund?.value}}
              </td>
              <td>
                {{item.matchFund?.name}}
              </td>
              <td>
                <div class="label label-inline {{item.status.value | surveyStatusColourClass}}" style="min-width:100px;">
                  <b>{{item.status.name}}</b>
                </div>
              </td>
              <td>
                {{item.createdDate | amFromUtc | amLocal | amDateFormat: 'DD MMM YYYY'}}
              </td>
              <td>
                <div class="label label-inline {{item.age | surveyAgeColourClass}}">{{item.age}} Day(s)</div>
              </td>
              <td>
                <div class="d-flex flex-row">
                  <fit-icon-button title="View Survey"
                                   icon="general-edit"
                                   class="btn-light-primary"
                                   (onClick)="viewSurvey(item.instanceId)">
                  </fit-icon-button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div class="d-flex justify-content-between flex-wrap mt-3">
    <div class="d-flex mb-2">
      <fit-paginator [totalItems]="viewmodel?.pager.recordCount"
                     [currentPage]="viewmodel?.pager.page"
                     [itemsPerPage]="viewmodel?.pager.pageSize"
                     (pageChange)="pageChange($event)">
      </fit-paginator>
    </div>
    <div class="d-flex">
      <fit-ngdropdown [showToggleIcon]="false"
                      [defaultText]="pageSizeButtonText">
        <a ngbDropDownItem
           class="dropdown-item"
           (click)="setPageSize(10)">10</a>
        <a ngbDropDownItem
           class="dropdown-item"
           (click)="setPageSize(25)">25</a>
        <a ngbDropDownItem
           class="dropdown-item"
           (click)="setPageSize(50)">50</a>
      </fit-ngdropdown>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <fit-grid-skeleton [columnCount]="4"></fit-grid-skeleton>
</ng-template>
