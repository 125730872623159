import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-communication-clipboard-check-icon',
  templateUrl: './communication-clipboard-check.component.html'
})
export class CommunicationClipboardCheckComponent extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
