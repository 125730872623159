
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Text-/-Bold" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M7.34,19 L7.34,4.8 L12.68,4.8 C15,4.8 16.9,6.7 16.9,9.02 C16.9,10.02 16.52,10.84 15.9,11.46 C17.1,12.1 17.9,13.26 17.9,14.78 C17.9,17.12 16,19 13.6,19 L7.34,19 Z M10.54,16.06 L13.3,16.06 C14.16,16.06 14.78,15.44 14.78,14.66 C14.78,13.88 14.16,13.24 13.3,13.24 L10.54,13.24 L10.54,16.06 Z M10.54,10.54 L12.32,10.54 C13.18,10.54 13.8,9.92 13.8,9.14 C13.8,8.36 13.18,7.72 12.32,7.72 L10.54,7.72 L10.54,10.54 Z" id="B" fill="#000000"></path>
    </g>
</svg>
