import { SortOrder } from "src/app/shared/types/sort.order";

export class GetPricingSurveysCommand {
  constructor(
    public page: number = 1,
    public pageSize: number = 25,
    public fund: string = '',
    public matchFund: string = '',
    public sortBy: string = '',
    public sortOrder: SortOrder) { }
}
