
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Design-/-Horizontal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Bound" points="0 0 24 0 24 24 0 24"></polygon>
        <path d="M21,12 C21,12.5522847 20.5522847,13 20,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 L20,11 C20.5522847,11 21,11.4477153 21,12 Z M16,12 C16,12.5522847 15.5522847,13 15,13 L14,13 C13.4477153,13 13,12.5522847 13,12 C13,11.4477153 13.4477153,11 14,11 L15,11 C15.5522847,11 16,11.4477153 16,12 Z M11,12 C11,12.5522847 10.5522847,13 10,13 L9,13 C8.44771525,13 8,12.5522847 8,12 C8,11.4477153 8.44771525,11 9,11 L10,11 C10.5522847,11 11,11.4477153 11,12 Z M6,12 C6,12.5522847 5.55228475,13 5,13 L4,13 C3.44771525,13 3,12.5522847 3,12 C3,11.4477153 3.44771525,11 4,11 L5,11 C5.55228475,11 6,11.4477153 6,12 Z" id="Combined-Shape" fill="#000000"></path>
        <path d="M14.9596876,21 L9.04031242,21 C8.76417005,21 8.54031242,20.7761424 8.54031242,20.5 C8.54031242,20.3864643 8.5789528,20.276309 8.64987802,20.1876525 L11.6095656,16.488043 C11.7820704,16.272412 12.0967166,16.2374514 12.3123475,16.4099561 C12.3411799,16.433022 12.3673685,16.4592107 12.3904344,16.488043 L15.350122,20.1876525 C15.5226268,20.4032834 15.4876661,20.7179296 15.2720351,20.8904344 C15.1833786,20.9613596 15.0732233,21 14.9596876,21 Z M9.04031242,3 L14.9596876,3 C15.23583,3 15.4596876,3.22385763 15.4596876,3.5 C15.4596876,3.61353575 15.4210472,3.723691 15.350122,3.81234752 L12.3904344,7.51195699 C12.2179296,7.72758796 11.9032834,7.76254865 11.6876525,7.59004388 C11.6588201,7.56697799 11.6326315,7.54078935 11.6095656,7.51195699 L8.64987802,3.81234752 C8.47737324,3.59671656 8.51233393,3.28207037 8.7279649,3.1095656 C8.81662142,3.03864038 8.92677668,3 9.04031242,3 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
    </g>
</svg>
