import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-navigation-right-3-icon',
  templateUrl: './navigation-right-3.component.html'
})
export class NavigationRight3Component extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
