
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Shopping-/-Rouble" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
        <path d="M5.3618034,16.2763932 L5.8618034,15.2763932 C5.94649941,15.1070012 6.11963097,15 6.30901699,15 L16.190983,15 C16.4671254,15 16.690983,15.2238576 16.690983,15.5 C16.690983,15.5776225 16.6729105,15.6541791 16.6381966,15.7236068 L16.1381966,16.7236068 C16.0535006,16.8929988 15.880369,17 15.690983,17 L5.80901699,17 C5.53287462,17 5.30901699,16.7761424 5.30901699,16.5 C5.30901699,16.4223775 5.32708954,16.3458209 5.3618034,16.2763932 Z" id="Path" fill="#000000" opacity="0.3"></path>
        <path d="M8,3.716 L13.107,3.716 C14.042338,3.716 14.8856629,3.80033249 15.637,3.969 C16.3883371,4.13766751 17.0323306,4.41366475 17.569,4.797 C18.1056693,5.18033525 18.5196652,5.67099701 18.811,6.269 C19.1023348,6.86700299 19.248,7.58766245 19.248,8.431 C19.248,9.33567119 19.079335,10.0946636 18.742,10.708 C18.404665,11.3213364 17.9485029,11.8158315 17.3735,12.1915 C16.7984971,12.5671685 16.1276705,12.8393325 15.361,13.008 C14.5943295,13.1766675 13.781671,13.261 12.923,13.261 L10.692,13.261 L10.692,20 L8,20 L8,3.716 Z M12.716,10.823 C13.1913357,10.823 13.6436645,10.7885003 14.073,10.7195 C14.5023355,10.6504997 14.885665,10.5278342 15.223,10.3515 C15.560335,10.1751658 15.8286657,9.9336682 16.028,9.627 C16.2273343,9.3203318 16.327,8.92166912 16.327,8.431 C16.327,7.95566429 16.2273343,7.5685015 16.028,7.2695 C15.8286657,6.97049851 15.5641683,6.73666751 15.2345,6.568 C14.9048317,6.39933249 14.5291688,6.28816694 14.1075,6.2345 C13.6858312,6.18083307 13.2526689,6.154 12.808,6.154 L10.692,6.154 L10.692,10.823 L12.716,10.823 Z" id="Combined-Shape" fill="#000000"></path>
    </g>
</svg>
