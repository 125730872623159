<svg width="24px"
     height="24px"
     viewBox="0 0 24 24"
     version="1.1"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Stockholm-icons-/-Navigation-/-Close"
       stroke="none"
       stroke-width="1"
       fill="none"
       fill-rule="evenodd">
        <g id="Group"
           transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
           fill="#000000">
            <rect id="Rectangle-185"
                  x="0"
                  y="7"
                  width="16"
                  height="2"
                  rx="1"></rect>
            <rect id="Rectangle-185-Copy"
                  transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                  x="0"
                  y="7"
                  width="16"
                  height="2"
                  rx="1"></rect>
        </g>
    </g>
</svg>
