import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'fit-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {

  @Input()
  currentPage: number;

  @Input()
  totalItems: number;

  @Input()
  itemsPerPage: number;

  @Input()
  class = "md";

  @Input()
  showPageOf = true;

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter();

  private totalItemsSubject = new BehaviorSubject<number>(0);

  constructor() {
    this.currentPage = this.currentPage || 1;
  }

  ngOnInit() {
    this.totalItemsSubject.pipe(delay(0)).subscribe((_) => {
      if (this.currentPage <= 0) {
        this.currentPage = 1;
        this.pageChange.emit(this.currentPage);
      }

      if (this.lastPageNumber < this.currentPage) {
        this.currentPage = this.lastPageNumber;
        this.pageChange.emit(this.currentPage);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.totalItems) {
      // If we filter from the last page, the total amount of items might change
      // and we end up with less items than the current page number supports
      this.totalItemsSubject.next(this.totalItems);
    }
  }

  goToPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.pageChange.emit(this.currentPage);
  }

  canGoToPage(pageNumber: number): boolean {
    if (this.totalItems < this.itemsPerPage) {
      return false;
    }
    return pageNumber > 0;
  }

  get lastPageNumber(): number {
    if (this.totalItems > 0) {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    }
    return 1;
  }

  get isOnFirstPage(): boolean {
    return this.currentPage === 1;
  }

  get isOnLastPage(): boolean {
    return this.currentPage === this.lastPageNumber;
  }

  get buttonOneNumber(): number {
    if (this.isOnFirstPage || this.lastPageNumber === 2) {
      return 1;
    } else if (this.isOnLastPage) {
      return this.lastPageNumber - 2;
    } else {
      return this.currentPage - 1;
    }
  }

  get buttonTwoNumber(): number {
    if (this.isOnFirstPage || this.lastPageNumber === 2) {
      return 2;
    } else if (this.isOnLastPage) {
      return this.lastPageNumber - 1;
    } else {
      return this.currentPage;
    }
  }

  get buttonThreeNumber(): number {
    if (this.isOnFirstPage) {
      return 3;
    } else if (this.isOnLastPage) {
      return this.lastPageNumber;
    } else {
      return this.currentPage + 1;
    }
  }

  get canGoPrevious(): boolean {
    return this.currentPage > 1 && this.totalItems > this.itemsPerPage;
  }

  get canGoNext(): boolean {
    return this.currentPage < this.lastPageNumber && this.totalItems > this.itemsPerPage;
  }

}
