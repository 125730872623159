import { EditState } from 'src/app/shared/types/edit.state';
import { UUID } from "angular2-uuid";

export class ViewModel {
  instanceId: string = UUID.UUID();
  rowVersion: string;
  editState: EditState;
  eTag: string;

  constructor(obj?: ViewModel) {
    if (obj) {
      this.instanceId = obj.instanceId;
      this.rowVersion = obj.rowVersion;
      this.editState = obj.editState;
      this.eTag = obj.eTag;
    }
  }
}
