import { Component, Input } from '@angular/core';
import { PricingSurveyForm } from 'src/app/surveys/pricing-survey/forms/pricing.survey.form';

@Component({
  selector: 'fit-pricing-survey-editor',
  templateUrl: './pricing.survey.editor.component.html',
  styleUrls: ['./pricing.survey.editor.component.scss']
})
export class PricingSurveyEditorComponent {

  @Input()
  survey: PricingSurveyForm;

  @Input()
  isReadOnly = false;
}


