<div class="row" [ngClass]="{'form-group': defaultMargins}">
  <div *ngIf="label"
       class="col-form-label text-left font-weight-bold"
       [ngClass]="fullWidth ? 'col-12 text-left' : 'text-md-right col-12 col-md-3'">
    <div class="d-flex flex-row">
      <div class="d-flex position-relative">
        {{label}}
        <span *ngIf="isRequired && !isReadOnly" class="text-danger">*</span>

        <span *ngIf="tooltip" class="svg-icon" style="cursor: pointer"
              [ngClass]="{'svg-icon-primary': !hovering, 'svg-icon-warning': hovering}"
              (mouseenter)="hovering=true" (mouseleave)="hovering=false"
              container="body" tooltipClass="tooltip-text" [placement]="tooltipPlacement" [ngbTooltip]="tipContent">
          <fit-icon icon="code-question-circle"></fit-icon>
        </span>

        
      </div>
    </div>
  </div>

  <div [ngClass]="fullWidth ? 'col-12' : 'col-12 col-md-9'">
    <div class="input-group"
         [style.width]="width">
      <input class="form-control"
             [ngClass]="{'is-invalid': control.invalid && control.touched}"
             type="number"
             [formControl]="control"
             [readOnly]="isReadOnly"
             [attr.placeholder]="placeHolder"
             [attr.min]="min"
             [attr.max]="max"
             [attr.step]="step"
             (keyup)="enforceBoundries($event)" />
      <div *ngIf="append" class="input-group-append">
        <span class="input-group-text">{{append}}</span>
      </div>
    </div>
    <span *ngIf="helperText"
          class="form-text text-muted">{{helperText}}</span>
    <div *ngIf="control.invalid && control.touched && !isReadOnly"
         class="fv-plugins-message-container">
      <div class="fv-help-block"> {{getErrorMessage()}} </div>
    </div>
  </div>
</div>


<ng-template #tipContent>
  <div class="d-flex">
      <span class="svg-icon svg-icon-primary">
        <fit-icon icon="code-info-circle"></fit-icon>
      </span>
      <b class="pl-1 tooltip-title">{{tooltipTitle}}</b>
    </div>
    <div class="d-flex tooltip-text">
      {{tooltip}}
    </div>
</ng-template>
