import { Component } from '@angular/core';
import { IconBase } from 'src/app/icons/icon.base';
import { IconConfig } from 'src/app/icons/icon.config';

@Component({
  selector: 'fit-code-commit-icon',
  templateUrl: './code-commit.component.html'
})
export class CodeCommitComponent extends IconBase {

  constructor(config?: IconConfig) {
    super(config);
  }
}
